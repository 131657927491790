// Import
   import './normal.css';
   import './App.css';
   import {useState, useEffect} from 'react'; 
   import {config} from './config.js'; // Maik
   import * as auth from './auth/auth.js';

// Authentication init
/**
 * @param url {string}
 * @param options {RequestInit<RequestInitCfProperties> | undefined}
 * @returns Promise<Response>
 */
   const fetchWithLogin = (url, options) => {
      return new Promise((resolve) => {
         auth.onLogin((err) => {
            let screen = document.querySelector('.signIn');
            if (err == null) {
               try {
                  resolve(fetch(auth.addTokenToUrl(url), options));
                  if (screen !== null) { screen.classList.remove('ready'); }
                  if (screen !== null) { screen.classList.add('notready'); }
               }
               catch(err) {
                  console.log(`- Sign in missing`);
                  if (screen !== null) { screen.classList.remove('notready'); }
                  if (screen !== null) { screen.classList.add('ready'); }
               }
            }
            else {
               console.log(`- Sign in missed`); // TBD: Display Screen just now
               if (screen !== null) { screen.classList.remove('notready'); }
               if (screen !== null) { screen.classList.add('ready'); }
            }
         });
      });
   }
   window.user = {};

// App
   function App() {

      useEffect(() => {
         feature.initParams();
         feature.getModels();
         feature.getPrompts();
         feature.saveGroupsToStorage();
         feature.loadPromptByHash();
      // eslint-disable-next-line
      // react-hooks/exhaustive-deps
      }, []);

      const debug = function(key, message, data) {
         window.debugs = window.debugs || {};
         let go = false;
         if (key === null) {
            key = message;
            key = key.replace(/ /g, '');
            key = key.replace(/\./g, '');
            key = key.replace(/\,/g, '');
            key = key.replace(/\:/g, '');
            key = key.replace(/\;/g, '');
            key = key.replace(/\//g, '');
            key = key.replace(/\?/g, '');
            key = key.replace(/\!/g, '');
            key = key.replace(/\-/g, '');
            key = key.replace(/\–/g, '');
         }
         if (message === false) {
            window.debugs[key] = false;
            return;
         }
         if (key === 'any') {
            go = true;
         }
         else if (window.debugs[key] !== true) {
            go = true;
            window.debugs[key] = true;
         }
         if (go) {
            if (typeof data !== 'undefined') {
               console.log(message, data);
            }
            else {
               console.log(message);
            }
         }
      };

      const helper = {
         ui: {
            scroll: function(method, direction, delay, smooth) {
               method =    typeof method    !== 'undefined' ? method    : 'chat';
               direction = typeof direction !== 'undefined' ? direction : 'bottom';
               delay =     typeof delay     === 'number'    ? delay     : 100;
               smooth =    typeof smooth    !== 'undefined' ? smooth    : false;
               const scroll = function(selector) {
                  let element = document.querySelector(selector);
                  let height =  element.scrollHeight;
                  if (direction === 'top')    { height = `0`; }
                  if (direction === 'bottom') { height = `${height}`; }
                  if (smooth === true) { 
                     element.scroll({ top: height, behavior: 'smooth'}); 
                  }
                  else { 
                     if (direction === 'top')    { element.scrollTo(0, 0); }
                     if (direction === 'bottom') { element.scrollTo(0, height); }
                  }
                  return `${method}, ${direction}, ${delay}, ${smooth}, ${height}`;
               };
               setTimeout(() => {
                  if (method === 'chat')   { scroll('.chatbox'); }
                  if (method === 'editor') { scroll('textarea.editor'); }
               }, delay);
            },
            uiReady: function() {
               let element = document.querySelector('.chat-input-textarea');
               element.focus();
            },
         /* chosenPrompt: function(promptKey, attribute) {
               let result = null;
               let element = document.querySelector(`.choosePrompt option[value="${promptKey}"]`);
               if (element) {
                  if (typeof attribute === 'string') { 
                     result = element[attribute]; 
                  }
                  else {
                     result = element;
                  }
               }
               return result;
            }, */
            prompteneer: function(method) {
            // console.log(`Prompteneer`);
               method = 
               method === true  ? true  : 
               method === false ? false : 
               typeof method === 'string' ? method : config.client.prompteneer;
            // console.log(`- method: '${method}')`);
               let element = document.querySelector('.prompteneer');
            // console.log(`- element: '${element}')`);
               if (element && method === true)  { 
                  element.style.display = 'block'; 
                  document.querySelector('.editor').focus();
                  helper.ui.scroll('editor', 'top', 1, false);
                  document.querySelector('body').dataset.prompteneer = "true";
               }
               if (element && method === false) { 
                  element.style.display = 'none'; 
                  document.querySelector('.chat-input-textarea').focus();
               // helper.ui.scroll('chat', 'bottom');
                  document.querySelector('body').dataset.prompteneer = "false";
               }
            },
            promptSaveDialogue: function(method, e) {
               let element = document.querySelector('.promptSaving');
               if (element && method === true)  { 
                  element.style.display = 'block'; 
               }
               if (element && method === false) { 
                  element.style.display = 'none'; 
               }
               e.preventDefault();
            },
            promptSave: function(e) {

               setTimeout(function() {

                  let authorRole = function() {
                     let result = config.client.roleDefault;
                     let role = helper.storage.get('userRole');
                     if (typeof role === 'string' && role !== '') {
                        result = role;
                     }
                     return result;
                  }();

                  const sanitize = function(value, type) {
                     let result = null;
                     if (type === 'string'  && typeof value === 'string')  { result = value; }
                     if (type === 'number'  && typeof value === 'string')  { result = parseFloat(value); }
                     if (type === 'number'  && typeof value === 'number')  { result = value; }
                     if (type === 'boolean' && typeof value === 'boolean') { result = value; }
                     if (value  === '' || value  === NaN) { result = null; }
                     if (result === '' || result === NaN) { result = null; }
                     if (value  === 'null')               { result = null; }
                     if (result === 'null')               { result = null; }
                     return result;
                  };

                  let promptId =             document.querySelector(`.prompteneer #promptId input`).value;
                  let promptName =           document.querySelector(`.prompteneer #promptName input`).value;
                  let promptDescription =    document.querySelector(`.prompteneer #promptDescription textarea`).value;
                  let promptRaw =            document.querySelector(`.prompteneer .editor`).value;
                  let promptModel =          document.querySelector(`.prompteneer #promptModel select`).value;
                  let promptTemperature =    document.querySelector(`.prompteneer #promptTemperature select`).value;
                  let promptPrecision =      document.querySelector(`.prompteneer #promptPrecision select`).value;
                  let promptTokens =         document.querySelector(`.prompteneer #promptTokens input`).value;
                  let promptStatisticId =    document.querySelector(`.prompteneer #promptStatisticId input`).value;
                  let promptStatisticQuery = document.querySelector(`.prompteneer #promptStatisticQuery input`).value;
                  let promptGroup =          document.querySelector(`.prompteneer #promptGroup select`).value;
                  let promptLabel =          function() { 
                     let el = document.querySelector(`select.choosePrompt [value="${promptGroup}"]`); 
                     if (el) { return el.innerText; } 
                     else { 
                        el = document.querySelector(`.choosePrompt [value="___${promptGroup}___"]`); 
                        if (el) { return el.innerText; } 
                        return promptGroup;
                     }
                  }();
                  let promptRole =           document.querySelector(`.prompteneer #promptRole select`).value;
                  let promptHidden =         document.querySelector(`.prompteneer #promptHidden input[type="checkbox"]`).checked;
                  let promptAuthors =        document.querySelector(`.prompteneer #promptAuthors input`).value;
                  let body = {
                     name:             promptName,
                     hidden:           promptHidden,
                     group:            promptGroup,
                     label:            promptLabel,
                     locked:           false,
                     role:             promptRole,
                     model:            promptModel,
                     precision:        sanitize(currentPrecision,       'string'), // Should be "promptPrecision" instead of "currentPrecision"?
                     temperature:      sanitize(promptTemperature,      'number'),
                     statisticId:      sanitize(promptStatisticId,      'number'),
                     statisticQuery:   sanitize(promptStatisticQuery,   'string'),
                     tokens:           sanitize(currentTokens,          'number'), // Should be "promptTokens" instead of "currentTokens"?
                     authorRole:       authorRole,
                     authorEmail:      window.user.id,
                     authorFirstname:  window.user.firstName,
                     authorLastname:   window.user.lastName,
                     authorDepartment: window.user.department,
                     authors:          promptAuthors,
                     description:      promptDescription,
                     prompt:           promptRaw,
                  };

                  let idExists = function() {
                     let result = false;
                     let prompteneer = promptId;
                     let promptIds = [];
                     let promptOptions = document.querySelectorAll('.choosePrompt option');
                     promptOptions.forEach((option) => {
                        promptIds.push(option.value);
                     });
                  // console.log(`prompt options: `, promptIds);
                     if (promptIds.includes(promptId)) { result = true; }
                     if (promptIds.includes(`___${promptId}___`)) { result = true; }
                     return result;
                  }(); 

                  let roleSufficient = function() { // Folgt
                     let result = false;
                     if (promptRole === 'user') { result = true; }
                     if (promptRole === 'manager' && (authorRole === 'manager' || authorRole === 'angel' || authorRole === 'admin')) { result = true; }
                     if (promptRole === 'angel'   && (                            authorRole === 'angel' || authorRole === 'admin')) { result = true; }
                     if (promptRole === 'admin'   && (                                                      authorRole === 'admin')) { result = true; }
                  // console.log(`isSufficient: ${result}`); // Tbd
                     return result;
                  }();

                  let isVersioned = function() {
                     let result = false;
                     let test = promptId.match(/[a-zA-Z0-9-_]*?_[\d]*-[\d]*/);
                     if (test === promptId) {
                        result = true;
                     }
                  // console.log(`isVersioned: ${result}`); // Tbd
                     return result;
                  }();

                  let allowSave = function() {
                     let result = false;
                     if (!idExists) { 
                        result = true; 
                        console.log(`- Prompt ID does not exist`);
                     }
                     else if (idExists && roleSufficient) { 
                        result = true; 
                     // debug('any', `- Allow save:  true`);
                        let dialogue = document.querySelector('.promptSavingDialogue');
                        let saveButton = document.querySelector('.promptSavingFooter button:first-child');
                        if (saveButton.dataset.overwrite !== 'true') {
                           saveButton.dataset.overwrite = 'true';
                           saveButton.innerText = 'Overwrite?';
                           dialogue.dataset.overwrite = 'true';
                           return false;
                        }
                        else {
                           dialogue.dataset.overwrite = 'false';
                        }
                     }
                     else {
                        console.log(`Will prevent saving because id does exist and role is not sufficient to overwrite.`);
                        let textVersioning = ``;
                        if (isVersioned) { textVersioning = ` (consider counting up the version at the end of the id)`; }
                        else {             textVersioning = ` (consider versioning by adding "_0-1")`; } // Tbd
                        let text = ``+
                           `You can not overwrite this prompt. `+
                           `Please choose an other id${textVersioning}. `+
                           `You might want to ask a Manager to merge the prompts later. `+
                        ``;
                        alert(text);
                     }
                     return result;
                  }(); 

                  if (allowSave) {

                  // console.log(`Will attempt to save prompt "${promptName}" (${promptId}) to "${promptLabel}" (${promptGroup}):`, body);
                  // console.log(`Will attempt to save prompt "${promptId}" to group "${promptLabel}":`, body);
                     debug('any', `- Allow save:  `, true);
                  // debug('any', `- Body:       `, [body]);
                     window.location.hash = promptId;
                     
                     fetchWithLogin(`${config.client.urlServer}/prompt/set?id=${promptId}`, {
                        method: 'POST',
                        headers: {
                           "Accept": "application/json",
                           "Content-Type": "application/json",
                        },
                        body: JSON.stringify(body),
                     })
                     .then(res => res.json())
                     .then((data) => {
                        if (data.status.code === 200) {
                        // console.log(`Saved prompt (${data.status.description}).`);
                           debug('any', `- Saved:       ${data.status.description}`);
                           let saveButton = document.querySelector('.promptSavingFooter button:first-child');
                           saveButton.dataset.overwrite = 'false';
                           saveButton.innerText = 'Confirm';

                           if (!idExists) {
                              let selectPrompt = document.querySelectorAll(`.choosePrompt optgroup#${promptGroup}`)[0];
                              selectPrompt.insertAdjacentHTML('afterbegin', `
                                 <option value="___${promptId}___" selected>${promptName}</option>
                              `);
                              selectPrompt.value = `___${promptId}___`;
                           }

                           let prompteneer = document.querySelector('.prompteneer');
                           prompteneer.dataset.authorEmail =      window.user.id;
                           prompteneer.dataset.authorFirstname =  window.user.firstName;
                           prompteneer.dataset.authorLastname =   window.user.lastName;
                           prompteneer.dataset.authorDepartment = window.user.department;

                           helper.ui.promptSaveDialogue(false, e);
                           saveButton.classList.remove('saving');
                        }
                        else {
                        // console.log(`Did not save prompt, ${data.status.description} (statuscode ${data.status.code}):`, data);
                           let saveButton = document.querySelector('.promptSavingFooter button:first-child');
                           saveButton.classList.remove('saving');
                        // helper.ui.promptSaveDialogue(false, e);
                           debug('any', `- Not Saved:   ${data.status.description}`, data);
                        }
                     });

                  }

               }, 100);
               e.preventDefault();
            },
            promptReload: function(promptKey, e) {
               debug('any', `Will reload "${promptKey}".`);
               setTimeout(function() {
                  fetchWithLogin(`${config.client.urlServer}/prompt/get?id=${promptKey}`, {
                     method: 'GET',
                     headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                     }
                  })
                  .then(res => res.json())
                  .then((data) => {
                     let element = document.querySelector('.prompteneer .editor');
                     element.value = data.result.prompt;
                  });
               }, 100);
               e.preventDefault();
            },
            promptShare: function(promptKey, e) {
               debug('any', `Will share "${promptKey}".`);
               helper.share.toggle();
               e.preventDefault();
            },
            defaultModel: async function() {
               let selectElement = document.querySelector(`.chooseModel`);
               let justOnce = selectElement.classList.contains('justOnce');
               let promptKey = document.querySelector('.choosePrompt').value;
            // console.log(`Just once: ${justOnce}`);
               if (!justOnce) {

                  const promptData = await async function() { 
                     let result = await fetchWithLogin(`${config.client.urlServer}/prompt/get?id=${promptKey}`, {
                        method: 'GET',
                        headers: {
                           'Allow': 'application/json',
                           'Content-Type': 'application/json',
                        }
                     });
                     result = await result.json();
                     result = result.result;
                  // console.log(`Default model "${promptKey}": `, result.model);
                     return result;
                  }();
         
                  selectElement.value = promptData.model;
                  selectElement.classList.add('justOnce');
               }
               let modelElement = selectElement.querySelector(`option[value="loading"]`);
               if (modelElement) { modelElement.remove(); }
            },
            roleAttr: function() {
               let result = '';
               let role = helper.storage.get('userRole');
               if (typeof role === 'string' && role !== '') { 
                  if (role === 'null') { role = 'user'; }
                  result = `${role}`; 
               }
               return result;
            },
            highestAttr: function() {
               let result = '';
               let role = helper.storage.get('highestRole');
               if (typeof role === 'string' && role !== '') { 
                  if (role === 'null') { role = 'user'; }
                  result = `${role}`; 
               }
               return result;
            },
         },
         storage: {
            get: function(name, type) {
            // console.log(`--> storage.get('${name}', '${type}')`);
               var result = null;
               let prefix = config.client.storagePrefix;
               let store =  config.client.storageDefault;
               name = `${prefix}${name}`;
               if (store === 'session') { if (sessionStorage.getItem(name)) { result = sessionStorage.getItem(name); }}
               if (store === 'local')   { if (localStorage  .getItem(name)) { result = localStorage  .getItem(name); }}
               if (type  === 'array')   { result = helper.storage.toArray(result);  }
               if (type  === 'string')  { result = helper.storage.toString(result); }
               return result;
            },
            set: function(name, value) {
            // console.log(`--> storage.set('${name}', '${value}')`);
               var result = false;
               let prefix = config.client.storagePrefix;
               let store =  config.client.storageDefault;
               name = `${prefix}${name}`;
               value = ( typeof value === 'string' && value !== '' ) || typeof value === 'number' || typeof value === 'boolean' ? value : null;
               if (store === 'session') { sessionStorage.removeItem(name); }
               if (store === 'local')   { localStorage  .removeItem(name); }
               if (store === 'session') { sessionStorage.setItem(name, value); result = true; }
               if (store === 'local')   { localStorage  .setItem(name, value); result = true; }
               return result;
            },
            delete: function(name) {
               var result = false;
               let prefix = config.client.storagePrefix;
               let store =  config.client.storageDefault;
               name = `${prefix}${name}`;
               if (store === 'session') { sessionStorage.removeItem(name); result = true; }
               if (store === 'local')   { localStorage  .removeItem(name); result = true; }
               return result;
            },
            toString: function(input) {
               let output = '';
               let array = [];
               if (Array.isArray(input)) {
                  array = input;
               }
               else if (typeof input === 'string' && input.indexOf(',') > -1) {
                  input = input.replace(/^,/, '');
                  input = input.replace(/,$/, '');
                  array = input.split(',');
               }
               else if (typeof input === 'string' && input !== '') {
                  array = helper.array.insert(input);
               }
               if (Array.isArray(array)) {
                  if (array.length > 0) {
                     array = helper.array.clean(array);
                  }
                  if (array.length > 1) {
                     array = helper.array.unique(array);
                     array = helper.array.sort(array);
                  }
                  if (array.length > 0) {
                     output = array.join(',');
                  }
               }
               return output;
            },
            toArray: function(input) {
               let output = [];
               if (Array.isArray(input)) {
                  output = input;
               }
               else if (typeof input === 'string' && input.indexOf(',') > -1) {
                  input = input.replace(/^,/, '');
                  input = input.replace(/,$/, '');
                  let array = input.split(',');
                  array.forEach(function(item) {
                     item = item.trim();
                     output = helper.array.insert(output, item);
                  });
               }
               else if (typeof input === 'string' && input !== '') {
                  output = helper.array.insert(output, input);
               }
               if (output.length > 0) {
                  output = helper.array.clean(output);
               }
               return output;
            },
            toPersonal: function(string) {
               let result = string;
               if (typeof string === 'string' && string !== '') {
                  if (string.indexOf('@') > -1 && string.indexOf('.') > -1) {
                     let array = string.split(/[\.|\@]/);
                     const condense = function(string) {
                        string = string.replace(/-/g, '');
                        string = helper.string.capitalize(string);
                        return string;
                     };
                     let firstname = condense(array[0]);
                     let lastname =  condense(array[1]);
                     if (firstname && lastname) {
                     // result = `personal${helper.string.capitalize(window.user.firstName)}${helper.string.capitalize(window.user.lastName)}`;
                     // result = `${window.user.firstName.toLowerCase()}${helper.string.capitalize(window.user.lastName)}`;
                        result = `${firstname.toLowerCase()}${lastname}`;
                     }
                  }
               }
               return result;
            },
         },
         url: {
            open: function(method, url) {
               method = method === 'blank' ? '_blank' : '_self';
               window.open(url, method).focus();
            },
            parameter: function(method, key, value) {
               let result = null;
               if (method === 'get') {
                  if (key === 'hash') {
                     result = window.location.hash;
                     result = result.replace(/^#/g, '');
                  }
                  else {
                     let search = window.location.search;
                     let params = new URLSearchParams(search);
                     result = params.get(key);
                     result = decodeURIComponent(result);
                  }
               }
               else if (method === 'set') {
                  if (key === 'hash') {
                     window.location.hash = value;
                  }
                  else {
                     console.log('Setting URL params is not implemented yet.');
                  }
                  result = method;
               }
               return result;
            },
            state: function(method, key, value) {
            // console.log(`helper.url.state("${method}", "${key}", "${value}")`);
               value = value === 'null' ? null : value;
               let url = new URL(window.location);
               if (value !== null) {
                  url.searchParams.set(key, value);
               }
               else {
                  url.searchParams.delete(key);
               }
               url = decodeURIComponent(url);
               if (method === 'push') {
                  window.history.pushState({}, "", url);
               }
               else if (method === 'replace') {
                  window.history.replaceState({}, "", url);
               }
            },
         },
         string: {
            capitalize: function(string) {
               if (typeof string === 'string') { 
                  string = string.charAt(0).toUpperCase() + string.slice(1); 
               }
               return string;
            },
            shorten: function(string, length, ellipsis){
               if (typeof string !== 'string') { return ''; }
               if (!length) { return string; }
               if (length === 'default' || length === null) { length = 50; }
               string = string.trim();
               string = string.replace(/\n/g, ' ');
               string = string.replace(/\n/g, ' ');
               string = string.replace(/\n/g, ' ');
               string = string.replace(/  /g, ' ');
               string = string.replace(/  /g, ' ');
               string = string.replace(/  /g, ' ');
               let postfix = '';
               if (ellipsis) {
                  if ((length - 3) < string.length) {
                     postfix = '...';
                     length = length - 3;
                  }
               }
               string = string.substring(0, length);
               string += postfix;
               return string;
            },
            timestamp: function() {
               const dt = new Date();
               const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
               return `${
               dt.getFullYear()}-${
               padL(dt.getMonth()+1)}-${
               padL(dt.getDate())}_${
               padL(dt.getHours())}-${
               padL(dt.getMinutes())}-${
               padL(dt.getSeconds())}`;
            },
         },
         array: {
            remove: function(array, item) {
               if (typeof item === 'string' && item !== '') {
                  const index = array.indexOf(item);
                  if (index > -1) {
                     array.splice(index, 1);
                  }
                  array = helper.array.clean(array);
               }
               return array;
            },
            insert: function(array, item, order) {
               if (!array) { array = []; }
               if (typeof item === 'string' && item !== '') {
                  item = item.trim();
                  const index = array.indexOf(item);
                  if (index > -1) {
                     array.splice(index, 1);
                  }
                  if (order === 'prepend') {
                     array = [item, ...array];
                  }
                  else if (order === 'append') {
                     array = [...array, item];
                  }
                  else {
                     array.push(item);
                  }
               }
            // array = helper.array.clean(array);
               return array;
            },
            unique: function(array) {
               if (Array.isArray(array)) {
                  array = array.filter((value, index, arr) => arr.indexOf(value) === index);
               }
               return array;
            },
            sort: function(array) {
               if (Array.isArray(array)) {
                  array.sort(function(a, b){ return a.localeCompare(b); });
               }
               return array;
            },
            sortArrOfObjByKey: function(array) {
               function compare(a, b) {
                  if (a.name < b.name){ return -1; }
                  if (a.name > b.name){ return  1; }
                  return 0;
               }
               array = array.sort(compare);
            // console.log(`sortArrOfObjByKey: `, array);
               return array;
            },
            clean: function(array) {
               if (Array.isArray(array)) {
                  delete array['undefined'];
                  delete array[undefined];
                  delete array['null'];
                  delete array[null];
                  delete array[''];
                  let arrayNew = [];
                  array.forEach(function(item) {
                     if (typeof item === 'string' && item !== '') {
                        item = item.trim();
                        arrayNew.push(item);
                     }
                  });
                  if (arrayNew.length > 1) {
                     arrayNew = helper.array.unique(arrayNew);
                     arrayNew = helper.array.sort(arrayNew);
                  }
                  array = arrayNew;
               }
               return array;
            },
         },
         number: {
            thousand: function(number) {
               let result = number;
               if (typeof result === 'number') {
                  result = result.toString();
               }
               if (result === null || result === 'null') {
                  result = '0';
               }
               result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
               return result;
            }
         },
         chat: {
         // sendMessage: function(user, text) {
         // // setTimeout(() => {
         //    let chatLogNew = [ ...chatLog, { user: user, message: `${text}` } ];
         //    setChatLog(chatLogNew);
         // // }, 1)}
         // },
         },
         prompt: {
            compress: function(str, style) {
               if (style === 'raw') {
                  str = str.replace(/^[ ]*?\/\/[a-zA-Z0-9-\.,_\?\! ]*$/gm, '');
               }
               str = str.replace(/^ {9}/gm, '   ');
               str = str.replace(/^ {6}\/\/ /gm, '// ');
               str = str.replace(/\n[ ]*?\n[ ]*?\n/g, '\n   \n');
               str = str.replace(/\n[ ]*?\n/g, '\n   \n');
               str = str.trim();
               str = `   ${str}`;
               str = str.replace(/^   \/\/ /g, '// ');
               return str;
            },
            loadPrompt: async function(promptKey, reason) {

               const promptData = await async function() { 

                  let response = await fetchWithLogin(`${config.client.urlServer}/prompt/get?id=${promptKey}`, {
                     method: 'GET',
                     headers: {
                        'Allow': 'application/json',
                        'Content-Type': 'application/json',
                     }
                  });
                  response = await response.json();
                  response = response.result;
                  return response;

               }();

               const debugToConsole = function() {
                  
               // console.log(`### promptKey:  "${promptKey}" (${typeof promptKey})`);
               // console.log(`### reason:     "${reason}" (${typeof reason})`);
               // console.log(`### promptData: `, promptData);
               // console.log(`### hash:       `, helper.url.parameter('hash'));

                  debug('InitLoadingPrompts',  ` \nLoading Prompts`);
                  debug('InitLoadingBackend',  `- Requesting Backend...`);
                  debug('InitLoadingDropdown', `- Requesting Prompt-Dropdown...`);

                  if (promptKey === 'null' && typeof reason === 'undefined' && helper.url.parameter('hash') !== null) {
                     debug(null, `- No Prompt pre-selected in URL`);
                  }

                  else if (promptKey === 'null' && reason === 'hash') {
                     debug(null, `- Default Prompt pre-selected in URL`);
                  }

                  else if (promptKey !== 'null' && reason === 'hash') {
                     debug(null,  ` \nLoaded Prompt`);
                     debug(null,  `- From:        URL`);
                  // debug(null,  `- Data:       `, promptData);
                     debug(null,  `- Id:          ${promptKey}`);
                     debug(null,  `- Name:        ${promptData.name}`);
                     debug(null,  `- Group:       ${promptData.label}`);
                     debug(null,  `- Authors:     ${promptData.authors     || 'not set'}`);
                     debug(null,  `- Last:        ${promptData.authorEmail || 'not set'}`);
                     debug(null,  `- Created:     ${promptData.created     || 'not set'}`);
                     debug(null,  `- Updated:     ${promptData.updated     || 'not set'}`);
                     debug(null,  `- Role:        ${promptData.role}`);
                     debug(null,  `- Locked:      ${promptData.locked}`);
                  }

                  else if (promptKey !== 'null' && reason !== 'hash') {
                     debug('any', ` \nLoaded Prompt`);
                     debug('any', `- From:        Dropdown`);
                  // debug('any', `- Data:       `, promptData);
                     debug('any', `- Id:          ${promptKey}`);
                     debug('any', `- Name:        ${promptData.name}`);
                     debug('any', `- Group:       ${promptData.label}`);
                     debug('any', `- Authors:     ${promptData.authors     || 'not set'}`);
                     debug('any', `- Last:        ${promptData.authorEmail || 'not set'}`);
                     debug('any', `- Created:     ${promptData.created     || 'not set'}`);
                     debug('any', `- Updated:     ${promptData.updated     || 'not set'}`);
                     debug('any', `- Role:        ${promptData.role}`);
                     debug('any', `- Locked:      ${promptData.locked}`);
                  }

               }();

               const memorizeChange = function() {
                  if (promptKey !== 'null' && reason === 'hash') {
                     setCurrentPrompt(promptKey);
                     feature.promptFields(promptKey);
                  }
               }();

               const doConversation = function() {

                  let chatLogNew = null;
                  let messageClient = null;
                  let messageBot = null;
                  let delay = 0;
                  let dataset = document.querySelector(`.App`).dataset;

                  let isPromptLoaded = function() {
                  // console.log('! Loaded: ', dataset.promptLoaded);
                     return document.querySelector(`.App`).dataset.promptLoaded === 'true';
                  }();
                  let isPromptsLoaded = function() {
                  // console.log('! Loadeds: ', dataset.promptsLoaded);
                     return document.querySelector(`.App`).dataset.promptsLoaded === 'true';
                  // let res = false;
                  // let isGroups = document.querySelectorAll('.choosePrompt optgroup');
                  // if (isGroups.length > 0) { res = true; }
                  // return res;
                  }();
                  let isHashGiven = function() {
                     let res = false;
                     let theHash = window.location.hash
                     if (theHash) { if (theHash.replace('#', '') !== '') { res = true; } }
                  // console.log('! Hash: ', res);
                     return res;
                  }();

                  if (isHashGiven && isPromptLoaded && !isPromptsLoaded) {
                  // return;
                  }

                  if      (promptKey === 'null' && reason === 'hash') {
                     messageBot = `Talk to GPT through me!`;
                  }
                  else if (promptKey === 'null' && reason !== 'hash') {
                     if (!isPromptsLoaded && isHashGiven) { 
                        messageBot = `Please wait while I am loading the prompts...`; 
                        delay = 500;
                     }
                     else { 
                        messageBot = `Choose a Prompt in the Sidebar!`;
                        delay = 500;
                     }
                  }
                  else if (promptKey !== 'null' && reason === 'hash') {
                     messageClient = `Loaded Prompt "${promptData.name}" according to URL.`;
                     if (promptKey === 'noPromptDesign') { messageClient = `Switched off Prompt Design according to URL.`; }
                     messageBot = promptData.description;
                  }
                  else if (promptKey !== 'null' && reason !== 'hash') {
                     messageClient = `Changed prompt to "${promptData.name}".`;
                     if (promptKey === 'noPromptDesign') { messageClient = `Switched off Prompt Design.`; }
                     if (promptKey.indexOf('_assistant') === 0) { messageClient = `You are talking to this Client's Assistant.`; }
                     messageBot = promptData.description;
                  }

                  if (messageClient && messageBot) {
                     chatLogNew = [ 
                        ...chatLog, 
                        { user: 'client', message: messageClient },
                        { user: 'gpt', message: messageBot, type: 'init', feedback: true, prompt: promptKey, author: promptData.authorEmail, group: promptData.group, request: '--', response: messageBot } 
                     ];
                  }
                  else if (messageClient) {
                     chatLogNew = [ 
                        ...chatLog, 
                        { user: 'client', message: messageClient }
                     ];
                  }
                  else if (messageBot) {
                     chatLogNew = [ 
                        ...chatLog, 
                        { user: 'gpt', message: messageBot, type: 'init', feedback: true, prompt: promptKey, author: promptData.authorEmail, group: promptData.group, request: '--', response: messageBot } 
                     // setChatLog([...chatLogNew, { user: 'gpt', message: `${data.message}`, type: 'response', feedback: true, prompt: promptKey, author: promptAuthor, group: promptGroup, request: messages, response: data.message }]);
                     ];
                  }

                  setTimeout(function() {
                     setChatLog(chatLogNew); 
                  }, delay);

               }();

               const loadEditor = function() {
                  let editor = document.querySelector('.prompteneer .editor');
                  let value = promptData.prompt;
                  value = helper.prompt.compress(value, 'pretty');
                  editor.value = value;
                  editor.dataset.promptKey = promptKey;
               // console.log(`- Prompt: ${value}`);
                  helper.ui.scroll('editor', 'top', null, false);
               }();

               const loadGroup = function() {
                  let field = document.querySelector('#promptGroup select');
                  let updatePrompts = setInterval(function() {
                     let personal = `${window.user.firstName.toLowerCase()}${helper.string.capitalize(window.user.lastName)}`;
                     let options = `<option id="${personal}" value="${personal}">${window.user.firstName} ${window.user.lastName}</option>`;
                  // options += `<option id="default" value="default" class="roleAngel roleAdmin">Default</option>`;
                     let fields = function() {
                        let authorRole = helper.storage.get('userRole');
                        let elements = document.querySelectorAll('.choosePrompt optgroup');
                        elements.forEach(element => {
                           let classs = '';
                           if (element.id === 'default')       { classs = 'roleAdmin'; }
                        // if (element.label === 'Statistic')  { classs = 'roleAngel roleAdmin'; }
                        // if (element.label === 'Statistics') { classs = 'roleAngel roleAdmin'; }
                           if (element.id !== personal) {
                              options += `<option id="${element.id}" value="${element.id}" class="${classs}">${element.label}</option>`;
                           }
                        });
                     }();
                     if (options !== '') {
                        options = `<option id="default" value="default" class="roleAdmin">Default</option>${options}`;
                        field.innerHTML = options;
                        field.value = promptData.group;
                        clearInterval(updatePrompts);
                     // 1.1
                     // let loaded = document.querySelector(`.choosePrompt option[value="null"]`);
                     // if (loaded) { loaded.innerText = 'Choose a prompt...'; }
                     }
                  // document.querySelector('#promptGroup select').value = promptData.group;
                  }, 100);
               }();

               const loadId = function() {
                  let field = document.querySelector('#promptId input');
                  let value = promptKey;
               // console.log(`- Id: ${value}`);
                  field.value = value;
               }();

               const loadName = function() {
                  let field = document.querySelector('#promptName input');
                  let value = promptData.name;
               // console.log(`- Name: ${value}`);
                  field.value = value;
               }();

               const loadDescription = function() {
                  let field = document.querySelector('#promptDescription textarea');
                  let value = promptData.description;
               // console.log(`- Description: ${value}`);
                  field.value = value;
                  return true;
               }();

               const loadModel = function() {
                  let wait = setInterval(function() {
                     let field = document.querySelector('.chooseModel.justOnce');
                     if (field) {
                        field.value = promptData.model;
                        setCurrentModel(promptData.model);
                        clearInterval(wait);
                     }
                  }, 100);
               }();

               const loadTokens = function() {
                  let wait = setInterval(function() {
                     let field = document.querySelector('.chooseTokens');
                     if (field) {
                        field.value = promptData.tokens;
                        setCurrentTokens(promptData.tokens);
                     // console.log(`Loaded prompt tokens: ${promptData.tokens}`);
                        clearInterval(wait);
                     }
                  }, 100);
               }();

               const loadTemperature = function() {
                  let wait = setInterval(function() {
                     let field = document.querySelector('.chooseTemperature');
                     if (field) {
                        field.value = promptData.temperature;
                        setCurrentTemperature(promptData.temperature);
                     // console.log(`Loaded prompt temperature: ${promptData.temperature}`);
                        clearInterval(wait);
                     }
                  }, 100);
               }();

               const loadRole = function() {
                  let role = document.querySelector('.chooseRole');
                  role.value = promptData.role;
               }();

               const loadHidden = function() {
                  let hide = document.querySelector('#promptHidden #hide');
                  if (hide) { hide.checked = false; }
               }();

               const loadAuthor = function() {
                  let prompteneer = document.querySelector('.prompteneer');
                  prompteneer.dataset.authorEmail =      promptData.authorEmail;
                  prompteneer.dataset.authorFirstname =  promptData.authorFirstname;
                  prompteneer.dataset.authorLastname =   promptData.authorLastname;
                  prompteneer.dataset.authorDepartment = promptData.authorDepartment;
               }();

               const loadAuthors = function() {

                  let value = promptData.authors || '';

                  promptData.authorEmail = typeof promptData.authorEmail === 'string' ? promptData.authorEmail : '';

                  value = value.replace(`, ${promptData.authorEmail}`,   '');
                  value = value.replace(  `${promptData.authorEmail}, `, '');
                  value = value.replace(  `${promptData.authorEmail}`,   '');
                  value = `${value}, ${promptData.authorEmail}`;

                  value = value.replace(/undefined/g, '');
                  value = value.replace(/\, \, /g, ', ');
                  value = value.replace(/\, \, /g, ', ');
                  value = value.replace(/\, \, /g, ', ');
                  value = value.replace(/^\, /g, '');
                  value = value.replace(/\, $/g, '');

                  let authors = document.querySelector('#promptAuthors input');
                  let authorsString = value;
                  if (authorsString === ', ') { authorsString = window.user.id; }
               // console.log(`email 0: ${authorsString} (${typeof authorsString})`);
                  authors.value = authorsString;
                  
                  let names = [];
                  let isCoauthor = false;
                  let authorsArray = authorsString.split(', ');
                  authorsArray = authorsArray.filter((value, index, array) => array.indexOf(value) === index);
                  authorsString = authorsArray.join(', ');
                  authorsArray.forEach(function(email) {

                  // console.log(`email 1: ${email} (${typeof email})`);

                     let firstname = '';
                     let lastname = '';
                     let name = '';

                     if (typeof email !== 'string' || email === '' || email === ', ' || email === 'undefined' || email === ', undefined') {
                        email = window.user.id;
                     }
                  // console.log(`email 2: ${email} (${typeof email})`);
                     
                     if (email === window.user.id) { isCoauthor = true; }

                     firstname = email.split('.')[0];
                     lastname = email.split(/[\.|\@]/)[1];
                     name = `${helper.string.capitalize(firstname)} ${helper.string.capitalize(lastname)}`;

                     if (!names.includes(name)) { names.push(name); }

                  });
               // console.log(`names: `, names);

               // console.log(`authorsString: ${authorsString} (${typeof authorsString})`);
               // console.log(`user.id:       ${window.user.id} (${typeof window.user.id})`);
                  let isOnlyAuthor = authorsString === window.user.id ? true : false;
                  isOnlyAuthor = authorsString === '' ? false : isOnlyAuthor;
               // console.log(`- Author:      ${isOnlyAuthor}`);
                  isCoauthor = isCoauthor === true && isOnlyAuthor !== true ? true : false;
               // console.log(`- Coauthor:    ${isCoauthor}`);
                  
                  let isAnAuthor = isOnlyAuthor || isCoauthor ? true : false;
               // if (promptKey !== 'null' && promptKey !== 'noPromptDesign') { debug('any', `- Is author:   ${isAnAuthor}`); }

                  let text = names.join(', ');
                  text = text.replace(/,([^,]*)$/, ' and$1');
               // console.log(`text: `, text);

                  let by = document.querySelector('#promptBy span');
                  by.innerText = text;

                  let authors2 = document.querySelector('.promptSavingOverlay .promptSavingAuthors');
                  let authors2Text = '';
                  if (isCoauthor) {
                     authors2Text = `Current authors are ${text}. <br />`;
                  }
                  authors2.innerText = authors2Text;

                  let you = document.querySelector('.promptSavingOverlay .promptSavingYou');
                  if (isOnlyAuthor) { 
                     you.innerHTML = `
                        You are the author, so most likely: yes. 
                     `; 
                  }
                  else if (isCoauthor) { 
                     you.innerHTML = `
                        You are a co-author, so quite likely: yes. 
                     `; 
                  }

                  let off = document.querySelector('.promptSavingOverlay .promptSavingOff');
                  if (isCoauthor) { 
                     off.innerHTML = `
                        <br /><br />
                        If you have been off screen for a while, 
                        you may want to backup your prompt and to "Reload" first 
                        to see whether there are changes from others. 
                     `; 
                  }
                  else { 
                     off.innerHTML = ``; 
                  }

                  let created = document.querySelector('.promptSavingOverlay .promptSavingCreated');
                  created.innerText = typeof promptData.created === 'string' && promptData.created !== '' ? promptData.created : helper.string.timestamp();

                  let updated = document.querySelector('.promptSavingOverlay .promptSavingUpdated');
                  updated.innerText = promptData.updated;

               }();

               if (promptKey !== 'null') { 
                  helper.url.state('replace', 'group', promptData.group);
                  helper.url.parameter('set', 'hash', `${promptKey}`); 
                  feature.openView();
               }

               let textarea = document.querySelector('.chat-input-textarea');
               textarea.focus();

               helper.ui.scroll('chat',   'bottom', null, true);
            // helper.ui.scroll('editor', 'top');
               feature.loggedOut('renewCounting');

               const assistants = function() {
                  let elements = document.querySelectorAll(`.sidebar .assistants [value]`);
                  if (elements) { elements.forEach(function(element) { element.classList.remove('active'); }); }
                  let element = document.querySelector(`.sidebar .assistants [value="${promptKey}"]`);
                  if (element) { element.classList.add('active'); }
               }();

            },
            loadSaver: function(promptKey) {
               debug('any', ` \nSave Dialogue`);
               debug('any', `- Prompt Key:  ${promptKey}`);
               const loadId = function() {

                  let authorRole = helper.storage.get('userRole');
                  let promptGroup = document.querySelector('#promptGroup select');
                  let input = document.querySelector('#promptId input');

                  if (input && promptGroup.value === 'templates' && (authorRole === 'user' || authorRole === 'manager')) {
                     let newValue = `personal${helper.string.capitalize(window.user.firstName)}${helper.string.capitalize(window.user.lastName)}Title_0-1`;
                  // let newValue = `${window.user.firstName.toLowerCase()}${helper.string.capitalize(window.user.lastName)}Title_0-1`;
                     input.value = newValue;
                     debug('any', `- Prompt Id:   ${newValue}`);
                  }

                  return true;

               }();
               const loadName = function() {

                  let authorRole = helper.storage.get('userRole');
                  let promptGroup = document.querySelector('#promptGroup select');
                  let input = document.querySelector('#promptName input');

                  if (input && promptGroup.value === 'templates' && (authorRole === 'user' || authorRole === 'manager')) {
                     let newValue = `The Title of your Prompt`;
                     input.value = newValue;
                     debug('any', `- Prompt Name: ${newValue}`);
                  }

                  return true;

               }();
               const loadModel = function() {
                  let source = document.querySelector('.chooseModel');
                  let target = document.querySelector('#promptModel select');
                  target.innerHTML = source.innerHTML;
                  target.value = source.value;
               // console.log(`- Copied Model: ${target.value}`);
                  return true;
               }();
               const loadTemperature = function() {
                  let source = document.querySelector('.chooseTemperature');
                  let target = document.querySelector('#promptTemperature select');
                  target.innerHTML = source.innerHTML;
                  target.value = source.value;
               // console.log(`- Copied Temperature: ${target.value}`);
                  return true;
               }();
               const loadPrecision = function() {
                  let source = document.querySelector('.choosePrecision');
                  let target = document.querySelector('#promptPrecision select');
                  target.innerHTML = source.innerHTML;
                  let style =  window.getComputedStyle(source.parentNode);
                  let visible = ((style.display !== 'none') && (style.visibility !== 'hidden'));
               // console.log(`Parent Precision: `, source.parentNode);
                  if (!visible) { target.value = 'null'; }
               // console.log(`- Copied Precision: ${target.value}`);
                  return true;
               }();
               const loadStatisticId = function() {
                  let source = document.querySelector('.statisticId input');
                  let target = document.querySelector('#promptStatisticId input');
                  let style =  window.getComputedStyle(source.parentNode);
                  let visible = ((style.display !== 'none') && (style.visibility !== 'hidden'));
               // console.log(`Parent StatisticId: `, source.parentNode);
                  if (visible) { target.value = source.value; }
               // console.log(`- Copied Id: ${target.value}`);
                  return true;
               }();
               const loadStatisticQuery = function() {
                  let source = document.querySelector('.statisticQuery input');
                  let target = document.querySelector('#promptStatisticQuery input');
                  let style =  window.getComputedStyle(source.parentNode);
                  let visible = ((style.display !== 'none') && (style.visibility !== 'hidden'));
               // console.log(`Parent StatisticQuery: `, source.parentNode);
                  if (visible) { target.value = source.value; }
               // console.log(`- Copied Query: ${target.value}`);
                  return true;
               }();
               const loadTokens = function() {
                  let source = document.querySelector('.tokens input');
                  let target = document.querySelector('#promptTokens input');
                  let style =  window.getComputedStyle(source.parentNode);
                  let visible = ((style.display !== 'none') && (style.visibility !== 'hidden'));
               // console.log(`Parent Tokens: `, source.parentNode);
                  if (visible) { target.value = source.value; }
               // console.log(`- Copied Tokens: ${target.value}`);
                  return true;
               }();
               const loadGroup = function() {

                  let authorRole = helper.storage.get('userRole');
                  let select = document.querySelector('#promptGroup select');
                  let options = select.querySelectorAll('option');
               // console.log(`Available Prompt-Groups: `, options);

                  let selectValue = null;
                  options.forEach((option) => {
                  /* Control */
                     
                  // console.log(`- Set up Group`);
                  // console.log(`  - option.id:        ${option.id}`);
                  // console.log(`  - option.innerText: ${option.innerText}`);

                     let optionId = option.id;
                     let optionName = option.innerText;

                     let optionDisable = false;
                     let optionRequired = `User`;

                     if      (optionId === 'templates'               && (authorRole === 'user' || authorRole === 'manager') && (!config.admins.templates.includes(window.user.id))) {
                        optionRequired = `Admin`;
                        optionId = helper.storage.toPersonal(window.user.id);
                        selectValue = optionId;
                     // window.setTimeout(function() { select.value = optionId; }, 10);
                     }
                     else if (optionId.indexOf(  `default`)    === 0 && (authorRole === 'user' || authorRole === 'manager') && (!config.admins.default.includes(window.user.id))) {
                        optionDisable = true;
                        optionRequired = `Admin`;
                     }
                     else if (optionName.indexOf(` (live)`)    > -1  && authorRole  === 'user'                              && (!config.admins.live.includes(window.user.id))) {
                        optionDisable = true;
                        optionRequired = `Manager`;
                     }
                     else if (optionName.indexOf(`Assistants`) === 0 && (authorRole === 'user' || authorRole === 'manager') && (!config.admins.assistants.includes(window.user.id))) {
                        optionDisable = true;
                        optionRequired = `Admin`;
                     }
                     else if (optionName.indexOf(`Templates`)  === 0 && (authorRole === 'user' || authorRole === 'manager') && (!config.admins.templates.includes(window.user.id))) {
                        optionDisable = true;
                        optionRequired = `Admin`;
                     }
                     else if (optionName.indexOf(`Tools`)      === 0 && authorRole  === 'user'                              && (!config.admins.tools.includes(window.user.id))) {
                        optionDisable = true;
                        optionRequired = `Manager`;
                     }

                     if (optionDisable) { debug('any', `- Disabled Group "${optionName}" for role "${helper.string.capitalize(authorRole)}", "${optionRequired}" or higher required.`); }

                     option.disabled =  optionDisable;
                     option.innerText = optionName;

                  // console.log(`  - optionRequired:   ${optionRequired}`);
                  // console.log(`  - optionDisable:    ${optionDisable}`);
                  // console.log(`  - optionName:       ${optionName}`);
                  // console.log(`  - optionId:         ${optionId}`);
                  // console.log(`  - selectValue:      ${selectValue}`);
                     
                  });

                  debug('any', `- Group Id:   `, selectValue);
                  if (typeof selectValue === 'string' && selectValue !== '') { 
                     select.value = selectValue; 
                     window.setTimeout(function() {
                        select.value = selectValue;
                     }, 100);
                  }

                  return true;

               }();
               const loadRole = function() {
                  let userRole = helper.storage.get('userRole');
                  let promptGroup = document.querySelector('#promptGroup select');
                  let select = document.querySelector('#promptRole select');
                  let groupPersonal = helper.storage.toPersonal(window.user.id);

               // console.log(`- Load Role`);
               // console.log(`  - userRole:  ${userRole}`);
               // console.log(`  - promptGroup: ${promptGroup.value} (${typeof promptGroup.value})`);
               // console.log(`  - select:   `, select);
               // console.log(`  - Personal: `, groupPersonal);

                  let source = document.querySelector('.chooseRole');
                  let target = document.querySelector('#promptRole select');
                  target.innerHTML = source.innerHTML;
                  target.value = source.value;
               // console.log(`- Copied Role: ${target.value}`);
                  let promptRole = source.value;

                  let roleSufficient = function() {
                     let result = true;
                     if (!promptRole || promptRole === '') { result = true; }
                     if (promptRole === 'user') { result = true; }
                     if (promptRole === 'manager'   && (userRole === 'manager' || userRole === 'angel' || userRole === 'admin')) { result = true; }
                     if (promptRole === 'angel' && (                          userRole === 'angel' || userRole === 'admin')) { result = true; }
                     if (promptRole === 'admin'     && (                                                      userRole === 'admin')) { result = true; }
                     return result;
                  }();
                  debug('any', `- Allow edit:  `, roleSufficient);
                  if (!roleSufficient) {
                     target.disabled = true;
                  }

                  if (select && promptGroup.value === groupPersonal && (userRole === 'user' || userRole === 'manager')) {
                     select.value = 'user';
                     window.setTimeout(function() {
                        select.value = 'user';
                     // console.log(`select.value: `, select.value);
                     }, 200);
                  // console.log(`  - Selected:  ${select.value}`);
                  }

                  return true;
               }();
               const loadHidden = function() {
                  let hide = document.querySelector('#promptHidden #hide');
                  if (hide) { hide.checked = false; }
                  return true;
               }();
            },
         },
         share: {
            toggle: function(method) {
               let element = document.querySelector('.share');
               if (element) { // SSOF
                  if (method === true) {
                     setTimeout(function() { 
                        setCurrentShare('Close Share'); 
                        setCurrentSettings('Open Settings'); 
                        setCurrentTrouble('Open Trouble'); 
                        element.style.display = 'block'; 
                     }, 200);
                  }
                  else if (method === false) {
                     setTimeout(function() { 
                        setCurrentShare('Open Share'); 
                     }, 100);
                     element.style.display = 'none';
                  }
                  else if (currentShare === 'Open Share') {
                     setCurrentShare('Close Share');
                     setCurrentSettings('Open Settings'); 
                     setCurrentTrouble('Open Trouble'); 
                     element.style.display = 'block';
                  }
                  else {
                     setCurrentShare('Open Share');
                     setCurrentSettings('Open Settings');
                     setCurrentTrouble('Open Trouble'); 
                     element.style.display = 'none';
                  }
               }
               element.querySelector(`.shareCode textarea`).value = `https://statista-ai.statista.workers.dev/#${currentPrompt}`;
            },
            update: function(e) {
            // console.log(`Click (group: ${e.target.name}, key: ${e.target.id}, value: ${e.target.value})`);
               
               let values = {};

               let groups = document.querySelectorAll(`.share [data-radios]`);
               groups.forEach(function(group) {
               // console.log('group: ', group);
                  let key = group.dataset.radios;
               // console.log('- key: ', key);
                  let value = null;
                  let radios = group.querySelectorAll(`input[type="radio"]`);
                  radios.forEach(function(radio) {
                  // console.log('- radio: ', radio.value);
                     if (radio.checked && radio.id !== 'client' && radio.id.replace(/\d/g, '') !== 'none') {
                        value = radio.id;
                     // console.log('  - value: ', value);
                     }
                  });
                  values[key] = value;
               });

               let textinputs = document.querySelectorAll(`.share input[type="text"]`);
               textinputs.forEach(function(textinput) {
                  let key = textinput.id;
                  let value = textinput.value;
                  values[key] = value;
               });

               helper.share.code(values);
            },
            code: function(settings) {
            // console.log(`code: `, settings);
               let url = new URL("https://statista-ai.statista.workers.dev/");
               Object.entries(settings).forEach(function([key, value]) {
                  if (value !== '' && value != null && key !== 'embed' && key !== 'prompt') {
                     url.searchParams.append(key, value);
                  }
                  else if (key === 'prompt') {
                     url.hash = value;
                  }
               });
            // console.log(`- url: `, url.href);
               let indicator = document.querySelector(`.shareCode h3 span`);
               let code = url.href;
               if (settings.embed !== null && settings.embed.replace(/\d/g, '') !== 'none') {
                  code = config.embed[settings.embed];
                  code = code.replace(/\{url\}/g, url.href);
                  code = code.replace(/\{prompt\}/g, settings.prompt);
                  indicator.innerText = 'Code';
               }
               else {
                  indicator.innerText = 'URL';
               }
               let textarea = document.querySelector('.shareCode textarea');
               textarea.value = code;
            }
         },
         trouble: {
            toggle: function(method) {
               let element = document.querySelector('.trouble');
               if (element) { // SSOF
                  if (method === true) {
                     setTimeout(function() { 
                        setCurrentShare('Open Share'); 
                        setCurrentTrouble('Close Trouble'); 
                        setCurrentSettings('Open Settings'); 
                        element.style.display = 'block'; 
                     }, 200);
                  }
                  else if (method === false) {
                     setTimeout(function() { 
                        setCurrentShare('Open Share'); 
                        setCurrentTrouble('Open Trouble'); 
                     }, 100);
                     element.style.display = 'none';
                  }
                  else if (currentTrouble === 'Open Trouble') {
                     setCurrentTrouble('Close Trouble');
                     setCurrentSettings('Open Settings'); 
                     setCurrentShare('Open Share'); 
                     element.style.display = 'block';
                  }
                  else {
                     setCurrentShare('Open Share');
                     setCurrentTrouble('Open Trouble');
                     setCurrentSettings('Open Settings');
                     element.style.display = 'none';
                  }
               }
            },
         },
         settings: {
            toggle: function(method) {
               let element = document.querySelector('.settings');
               if (element) { // SSOF
                  if (method === true) {
                     setTimeout(function() { 
                        setCurrentSettings('Close Settings'); 
                        setCurrentTrouble('Open Trouble'); 
                        setCurrentShare('Open Share'); 
                        element.style.display = 'block'; 
                     }, 200);
                  }
                  else if (method === false) {
                     setTimeout(function() { 
                        setCurrentSettings('Open Settings'); 
                     }, 100);
                     element.style.display = 'none';
                  }
                  else if (currentSettings === 'Open Settings') {
                     setCurrentSettings('Close Settings');
                     setCurrentTrouble('Open Trouble'); 
                     setCurrentShare('Open Share'); 
                     element.style.display = 'block';
                  }
                  else {
                     setCurrentSettings('Open Settings');
                     setCurrentTrouble('Open Trouble'); 
                     setCurrentShare('Open Share'); 
                     element.style.display = 'none';
                  }
               }
            },
            policy: function(method) {

               let element =  document.querySelector('.App');
               let entrance = document.querySelector('.entrance');
               let checkbox = document.querySelector('#policyConfirmed');
               let button =   document.querySelector('#policyStart');

               let versionNumber =  config.policy.version;
               let versionString =  versionNumber.replace(/\./g, '-');
               let versionStorage = `policyVersion_${versionString}`;

               const pos = function(side) {
                  if (side === 'right') {
                  // entrance.style.left = '300px';
                  }
                  else if (side === 'center') {
                  // entrance.style.left = '0px';
                  }
               };
               const get = function(elem, key) {
                  let result = null;
                  if (elem === 'storage') {
                     result = helper.storage.get(key);
                  }
                  if (elem === 'checkbox') {
                     if (key === 'checked') {
                        result = checkbox.dataset.confirmed;
                     }
                  }
                  const sanitize = function() {
                     if      (result === 'true')      { result = true;  }
                     else if (result === 'false')     { result = false; }
                     else if (result === undefined)   { result = false; }
                     else if (result === 'undefined') { result = false; }
                     else if (result === null)        { result = false; }
                     else if (result === 'null')      { result = false; }
                  }();
               // console.log(`get('${elem}', '${key}') === ${result} (${typeof result})`);
                  return result;
               };
               const set = function(elem, key, value) {
               // console.log(`set('${elem}', '${key}', '${value}')`);
                  if (elem === 'app') { 
                     elem = element; 
                     elem.dataset[key] = value;
                  }
                  if (elem === 'storage') {
                     helper.storage.set(key, `${value}`);
                  }
                  if (elem === 'button') { 
                     if (key === 'disabled') { 
                        if (value === true) {
                           button.classList.add('disabled');
                        }
                        if (value === false) {
                           button.classList.remove('disabled');
                        }
                     }
                  }
                  if (elem === 'checkbox') { 
                     if (key === 'checked') { 
                        checkbox.dataset.confirmed = value;
                     }
                  }
               };
               
               if (method === 'init') {
                  return get('storage', versionStorage);
               }

               else if (method === 'print') {
                  return versionNumber;
               }

               else if (method === 'open') {
                  pos('right');
                  set('app', 'policyConfirmed', false);
               // console.log(`Is confirmed: ${policyConfirmed} (${typeof policyConfirmed})`);
                  if (policyConfirmed) { 
                     set('button', 'disabled', false);
                     set('checkbox', 'checked', true);
                  }
                  else { 
                     set('button', 'disabled', true);
                     set('checkbox', 'checked', false);
                  }
               }

               else if (method === true) {
                  set('checkbox', 'checked', true);
                  set('button', 'disabled', false);
               }

               else if (method === false) {
                  set('checkbox', 'checked', false);
                  set('button', 'disabled', true);
               }

               else if (method === 'close') {
                  let state = get('checkbox', 'checked');
                  if (state) {
                     set('app', 'policyConfirmed', state);
                     setPolicyConfirmed(state);
                  }
                  else {
                     pos('center'); 
                  }
                  set('storage', versionStorage, state);
               }

            },
            groups: function(method, event) {

            // console.log(`Settings`);
            // console.log(`- Method:  ${method}`);

               let elements = document.querySelectorAll(`.settingsGroups input[type='checkbox']`);
               let highest = helper.storage.get('highestRole');
               let available = [];
               let current = [];

               if (window.user.initialized) { debug(null, ` \nYour Prompt-Groups`); }

               let groupsAvailableArray = function() {
                  let result = [];
                  const params = function(param) {
                     let pusher = helper.url.parameter('get', param);
                     if (typeof pusher === 'string' && pusher !== '' && pusher !== 'null') {
                        let pusherArray = pusher.split(',');
                        let pusherNew = [];
                        pusherArray.forEach(function(item) { 
                           item = item.replace(/Live$|Stage$|Dev$|0$|1$|2$|3$|4$|5$|6$|7$|8$|9$/g, '');
                           pusherNew = helper.array.insert(pusherNew, item);
                        });
                        pusherNew.forEach(function(item) { 
                           if (item !== null && item !== 'null') {
                              item = item.replace(/([a-z]*).*/, '$1'); // Tbd
                              result = helper.array.insert(result, item);
                           }
                        });
                     }
                  };
                  params('group');
                  params('groups');
                  const storage = function() {
                     let store = helper.storage.get('groupsAvailable', 'array');
                     store.forEach(function(item) { 
                        item = item.replace(/Live$|Stage$|Dev$|0$|1$|2$|3$|4$|5$|6$|7$|8$|9$/g, '');
                        result = helper.array.insert(result, item);
                     });
                  }();
                  const personal = function() {
                     result = helper.array.insert(result, helper.storage.toPersonal(window.user.id));
                  }();
                  const all = function() {
                     if (highest === 'angel' || highest === 'admin') {
                        result = helper.array.insert(result, 'all');
                     }
                     else {
                        result = helper.array.remove(result, 'all');
                     }
                  }();
                  result = helper.array.clean(result);
               // console.log(`- groupsAvailableArray: `, result);
                  return result;
               }();

               let groupsAvailableString = function() {
                  let result = helper.storage.toString(groupsAvailableArray) || '';
                  available = result;
               // console.log(`- groupsAvailableString: '${result}'`);
                  if (window.user.initialized) { debug(null, `- Available:  `, [result.split(',')]); }
                  return result;
               }();

               let groupsCurrentArray = function() {

                  let result = helper.storage.get('groupsCurrent', 'array');

                  const params = function(param) {

                     let url = helper.url.parameter('get', param);
                  // console.log(`- groupsCurrentUrl: ${url} (${typeof url})`);
                     if (typeof url === 'string' && url !== '') {
                        let urlArray = helper.storage.toArray(url);
                     // console.log(`- groupsCurrentArr (${typeof urlArray}, ${urlArray.length}): `, urlArray);
                        if (Array.isArray(urlArray) && urlArray.length > 0) {
                           urlArray.forEach(function(item) {
                              if (item !== null && item !== 'null' && item !== '') {
                                 item = item.replace(/Live$|Stage$|Dev$|0$|1$|2$|3$|4$|5$|6$|7$|8$|9$/g, '');
                                 result = helper.array.insert(result, item);
                              }
                           });
                        }
                     }
                  // debug(null, `- groupsCurrentArray: `, result);
   
                  };
                  params('group');
                  params('groups');

               // let results = []
               // result.forEach(function(item) {
               //    item = item.replace(/Live$|Stage$|Dev$|0$|1$|2$|3$|4$|5$|6$|7$|8$|9$/g, '');
               //    results = helper.array.insert(results, item);
               // });
               // return results;
                  return result;

               }();

               let groupsCurrentString = function() {
                  let result = helper.storage.toString(groupsCurrentArray);
                  if (typeof result !== 'string') { result = ''; }
                  current = result;
                  if (window.user.initialized) { debug(null, `- Active:     `, [result.split(',')]); }
                  return result;
               }();

               if (method === 'get') {
                  let result = [];
                  elements.forEach(function(element) {
                     let key = element.id;
                     if (element.checked === true) {
                        result.push(key);
                     }
                  });
                  current = result;
               }

               if (method === 'set') {

                  debug('any', ` \nChanged Group`);
               // debug('any', `- Method:     ${method}`);
               // debug('any', `- Available:  `, groupsAvailableArray);
               // debug('any', `- Available:  ${groupsAvailableString}`);
               // debug('any', `- Current:    `, groupsCurrentArray);
               // debug('any', `- Current:    ${groupsCurrentString}`);

                  let key = event.target.id;
                  debug('any', `- Key:         ${key}`);

                  let name = event.target.parentNode.querySelector('label').innerText;
                  debug('any', `- Name:        ${name}`);

                  let checked = event.target.checked;
                  debug('any', `- Activated:   ${checked}`);

                  if (checked) {
                     groupsCurrentArray = helper.array.insert(groupsCurrentArray, key);
                  }
                  else {
                     groupsCurrentArray = helper.array.remove(groupsCurrentArray, key);
                  }

                  groupsCurrentString = helper.storage.toString(groupsCurrentArray) || '';
               // debug('any', `- String:     ${groupsCurrentString}`);

                  helper.storage.set('groupsCurrent', groupsCurrentString);
               // debug('any', `- New Groups: ${groupsCurrentString.replace(/,/g, ', ')}`);

                  setCurrentGroups(groupsCurrentString);

                  elements.forEach(function(element) {
                     let key = element.id;
                     if (groupsCurrentArray.includes(key)) {
                        element.checked = true;
                        element.dataset.active = true;
                     }
                     else {
                        element.checked = false;
                        element.dataset.active = false;
                     }
                  });

                  setTimeout(function() { 
                  // setCurrentPrompt('');
                     feature.getPrompts(); 
                  // debug('any', `- Refreshed the settings`);
                  }, 250);

               }

               if (method === 'init') {

                  elements.forEach(function(element) {
                     let key = element.id;
                     if (groupsAvailableArray.includes(key)) {
                        element.parentNode.style.display = 'block';
                     }
                     else {
                        element.parentNode.style.display = 'none';
                     }
                  });

                  elements.forEach(function(element) {
                     let key = element.id;
                     if (groupsCurrentArray.includes(key)) {
                        element.checked = true;
                        element.dataset.active = true;
                     }
                     else {
                        element.checked = false;
                        element.dataset.active = false;
                     }
                  });

                  helper.storage.set('groupsAvailable', groupsAvailableString);

                  let store = helper.storage.get('groupsCurrent', 'string');
                  if (typeof store !== 'string' || store === null || store === 'null' || store === '') {
                     helper.storage.set('groupsCurrent', groupsAvailableString);
                  }

               }

               let hash = helper.url.parameter('get', 'hash');
               if (typeof hash !== 'string' || hash === '#' || hash === '' || hash === null) {
                  helper.url.state('replace', 'group', null);
                  helper.url.state('replace', 'groups', null);
               }

               return groupsCurrentString;

            },
            roles: function(method, value) {
               let result = null;
               if (method === 'init') {
                  let location = helper.url.parameter('get', 'role');
                  let storage = helper.storage.get('userRole');
                  if (typeof location === 'string' && location !== '' && location !== 'null') { result = location; }
                  else if (typeof storage === 'string' && storage !== '' && storage !== 'null') { result = storage; }
                  else { result = config.client.roleDefault; }
                  if ((typeof location === 'string' && location !== '' && location !== 'null')
                  &&  (typeof storage === 'string'  && storage  !== '' && storage  !== 'null')
                  &&  (location !== storage)) {
                     setTimeout(function() { 
                        helper.settings.toggle(true);
                        setCurrentSettings('Close Settings');
                     }, 10);
                  }
                  debug('any', `Using role '${result}'`);
                  helper.storage.set('userRole', result);
                  helper.url.state('replace', 'role', null);
               }
               if (method === 'get') {
                  result = currentRole;
               }
               if (method === 'set') {
                  setCurrentRole(value);
               }
               if (method === 'init' || method === 'set') {
                  let highest = helper.storage.get('highestRole');
                  if (highest !== null && highest !== 'null' && highest !== '') {
                     if (result === 'admin'                                                                      ) { helper.storage.set('highestRole', 'admin') }
                     if (result === 'angel'                                                && highest !== 'admin') { helper.storage.set('highestRole', 'angel') }
                     if (result === 'manager'                       && highest !== 'angel' && highest !== 'admin') { helper.storage.set('highestRole', 'manager') }
                     if (result === 'user' && highest !== 'manager' && highest !== 'angel' && highest !== 'admin') { helper.storage.set('highestRole', 'user') }
                  }
                  else {
                     helper.storage.set('highestRole', result);
                  } 
               }
               if (method === 'init' || method === 'set') {
                  helper.storage.set('userRole', result);
                  window.setTimeout(function() {
                     debug('any', `Set or init: ${result}`);
                     let setting = document.querySelector(`.settingsRole select`);
                     setting.value = result;
                     setCurrentRole(result);
                  }, 1000);
               }
            // helper.url.state('replace', 'role', result !== 'user' ? result : null);
            // helper.storage.set('userRole', result === 'null' ? 'user' : result);
               return result;
            },
            user: function(method, value) {
               if (method === 'init') {
                  let email = null;
                  let emailStorage = helper.storage.get('userId');
                  let emailLocation = helper.url.parameter('get', 'email');
                  let emailConfig = config.client.userDefault;
                  if (typeof emailStorage === 'string' && emailStorage !== '' && emailStorage !== 'null') {
                     email = emailStorage;
                     helper.url.state('replace', 'email', null);
                  }
                  else if (typeof emailLocation === 'string' && emailLocation !== '' && emailLocation !== 'null') {
                     email = emailLocation;
                     helper.storage.set('userId', email);
                     helper.url.state('replace', 'email', null);
                  }
                  else {
                     email = emailConfig;
                  }
                  emailStorage = helper.storage.get('userId');
                  if (typeof emailStorage !== 'string' || emailStorage === '' || emailStorage === 'null') {
                     if (config.client.initSettings === true) {
                        helper.settings.toggle(true); // 1.6
                     }
                  }
                  return email;
               }
               else if (method === 'get') {
                  return currentUser;
               }
               else if (method === 'set') {
                  setCurrentUser(value);
                  helper.storage.set('userId', value);
                  helper.url.state('replace', 'email', null);
               }
            },
            token: function(method) { // TBD TOKENDISPLAY
               let setting = document.querySelector('.settings .settingsToken input');
               let sidebar = document.querySelector('.sidebar .tokenDisplay');
               let helpers = document.querySelector('.sidebar .showTokens');
               if (sidebar === null) { return null; }
            // console.log(` \nDisplay Tokens`);
            // console.log(`- Method: `, method);
               let status = false;
            // console.log(`- Current: `, status);
               let result = null;
               if (method === 'init') {
                  status = helper.storage.get('tokenDisplay');
                  status = status === 'true'  ? true  : status;
                  status = status === 'false' ? false : status;
                  status = typeof status === 'boolean' ? status : false;
                  method = status;
               // console.log(`- Initialized: `, method);
               }
               else if (method === 'toggle') {
                  if (currentToken === false) { method = true;  }
                  if (currentToken === true)  { method = false; }
               // console.log(`- Toggled: `, method);
               }
               if (method === true) {
                  result = true;
               }
               else if (method === false) {
                  result = false;
               }
               else {
                  result = false;
               }
            // console.log(`- Result: `, result);
               if (sidebar !== null) { 
               // console.log(`- Sidebar (${typeof sidebar}): `, sidebar);
                  sidebar.dataset.display = `${result}`;
                  helpers.dataset.display = `${result === true ? false : true}`;
                  setting.checked = result;
               // setCurrentToken(result);
               // currentToken = result;
                  helper.storage.set('tokenDisplay', result);
               }
               return result;
            },
         },
         animate: {
            waiting: function() {
               /*
               let dotdotdot = window.setInterval(function() {
                  let elements = document.querySelectorAll(`.chat-log > .waiting`);
                  if (elements.length > 0) {
                     elements.forEach(function(element) {
                        element = element.querySelector(`.message p`);
                        let code = element.innerHTML;
                        let text = code;
                        text = text.replace(/[\. \&nbsp\;]*$/g, '');
                        let codeNew = text;
                        if      (code === text+'...&nbsp;')      { codeNew = '&nbsp;..&nbsp;'; }
                        else if (code === text+'&nbsp;..&nbsp;') { codeNew = '.&nbsp;.&nbsp;'; }
                        else if (code === text+'.&nbsp;.&nbsp;') { codeNew = '..&nbsp;&nbsp;'; }
                        else if (code === text+'..&nbsp;&nbsp;') { codeNew = '... ';           }
                        else if (code === text+'... ')           { codeNew = '...&nbsp;';      }
                        else                                     { codeNew = '...&nbsp;';      }
                        element.innerHTML = text+''+codeNew;
                     });
                  }
                  else {
                     clearInterval(dotdotdot);
                  // console.log(`Cleared waiting interval: `, dotdotdot);
                  }
               }, 250);
*/
               let wait = window.setInterval(function() {
                  let elements = document.querySelectorAll(`.chat-log > .waiting`);
                  let last = null;
                  if (elements.length > 0) {
                     elements.forEach(function(element) {
                        element = element.querySelector(`.message p`);
                        let text = function() {
                           let result = element.innerHTML;
                           result = result.replace(/\&nbsp\;/g, '');
                           result = result.replace(/[\. ]*?$/g, '');
                        // console.log(`text: `, result);
                           return result;
                        }();
                        let dots = function() {
                           let result = element.innerHTML;
                           result = result.replace(text, '');
                        // console.log(`dots 1: `, result);
                           return result;
                        }();
                        if      (dots === '...&nbsp;')      { dots = '&nbsp;..&nbsp;'; }
                        else if (dots === '&nbsp;..&nbsp;') { dots = '.&nbsp;.&nbsp;'; }
                        else if (dots === '.&nbsp;.&nbsp;') { dots = '..&nbsp;&nbsp;'; }
                        else if (dots === '..&nbsp;&nbsp;') { dots = '... ';           }
                        else if (dots === '... ')           { dots = '...&nbsp;';      }
                        else                                { dots = '...&nbsp;';      }
                        if (last !== text) {
                        // console.log(`dots 2: `, dots);
                           element.innerHTML = text+''+dots;
                           last = text;
                        }
                     });
                  }
                  else {
                     clearInterval(wait);
                  // console.log(`Cleared waiting interval: `, wait);
                  }
               }, 250);
            },
         },
         params: {
            get: function(key) {
               let result = null;
               let app = document.querySelector('.App');
               if (app) {
                  let value = app.dataset[key];
                  if (typeof value === 'string' && value !== '') { result = value; }
               }
               if (result === 'true')  { result = true;  }
               if (result === 'false') { result = false; }
               return result;
            },
            set: function(key, value) {
            // debug('any', `Saving "${key}":`, value);
               let app = document.querySelector('.App');
               let view = helper.params.get('view') ? helper.params.get('view') : helper.url.parameter('get', 'view');
               value = decodeURIComponent(value); 
               value = value.replace(/"/g, "'");
               if (view !== 'widget' && view !== 'assistants' && view !== 'test') { helper.url.state('replace', key, null); } // 1.9
               if (app) {
                  app.dataset[key] = value;
               }
               else { // Wait for App to exist
                  let wait = window.setInterval(function() {
                     let app = document.querySelector('.App');
                     if (!app) {} else {
                        app.dataset[key] = value;
                        clearInterval(wait);
                     }
                  }, 1);
               }
               return value;
            },
         },
      };

      const [input,              setInput]                 = useState('');
      const [models,             setModels]                = useState([]);
   // eslint-disable-next-line
      const [prompts,            setPrompts]               = useState([]);
      const [statisticId,        setCurrentStatisticId]    = useState(config.statista.statisticId);
      const [statisticQuery,     setCurrentStatisticQuery] = useState(config.statista.statisticQuery);
      const [currentTokens,      setCurrentTokens]         = useState(config.openai.tokens);
      const [currentModel,       setCurrentModel]          = useState(config.openai.model);
      const [currentPrompt,      setCurrentPrompt]         = useState(config.client.prompts);
      const [currentTemperature, setCurrentTemperature]    = useState(config.openai.temperature);
      const [currentPrecision,   setCurrentPrecision]      = useState(config.openai.precision);
      const [currentRole,        setCurrentRole]           = useState('');
      const [chatLog,            setChatLog]               = useState([{ user: 'gpt', message: function() {
         let result = config.client.start;
         let welcome = helper.url.parameter('get', 'welcome');
      // console.log(`Message: ${welcome}`);
         if (typeof welcome === 'string' && welcome !== '' && welcome !== 'null') { result = welcome; }
         else {
            let dom = document.querySelector('.App');
            if (dom) { 
               welcome = helper.params.get('welcome');
               dom.dataset.welcome = 'null';
            }
            if (typeof welcome === 'string' && welcome !== '' && welcome !== 'null') { result = welcome; }
         }
      // let welcome = helper.url.parameter('get', 'welcome') || helper.params.get('welcome');
      // if (typeof welcome === 'string' && welcome !== '' && welcome !== 'null') { 
      //    result = helper.params.set('welcome', welcome);
      // }
         return result;
      }() }]); // 1.2
      const [currentTheme,       setCurrentTheme]          = useState(helper.storage.get('theme') || config.client.theme);
      const [policyUrl,          setPolicyUrl]             = useState(config.policy.url);
      const [policyVersion,      setPolicyVersion]         = useState(config.policy.version.replace(/\./g, '-'));
      const [policyConfirmed,    setPolicyConfirmed]       = useState(helper.settings.policy('init'));
      const [currentSettings,    setCurrentSettings]       = useState('Open Settings');
      const [currentTrouble,     setCurrentTrouble]        = useState('Open Trouble');
      const [currentShare,       setCurrentShare]          = useState('Open Share');
      const [currentUser,        setCurrentUser]           = useState(helper.settings.user('init'));
      const [currentGroups,      setCurrentGroups]         = useState(helper.settings.groups('init'));
      const [currentToken,       setCurrentToken]          = useState(helper.settings.token('init'));
      
      const feature = {
         getModels: function() {
            fetchWithLogin(config.client.urlServer + '/models')
            .then(res => res.json())
            .then((data) => {
            // console.log('Sorted Models from Backend: ', data.models);
               let modelsDefault = [
               // { id: 'text-davinci-002',       object: 'model',   created: 1669599635, owned_by: 'openai-internal', permission: [], root: "text-davinci-002" },
                  { id: 'text-davinci-003',       object: 'model',   created: 1669599635, owned_by: 'openai-internal', permission: [], root: "text-davinci-003" },
                  { id: 'gpt-3.5-turbo',          object: 'model',   created: 1677610602, owned_by: 'openai',          permission: [], root: "gpt-3.5-turbo" },
               // { id: 'gpt-3.5-turbo-16k-0613', object: 'model',   created: 1677610602, owned_by: 'openai',          permission: [], root: "gpt-3.5-turbo-16k-0613" },
                  { id: 'gpt-3.5-turbo-16k',      object: 'model',   created: 1677610602, owned_by: 'openai',          permission: [], root: "gpt-3.5-turbo-16k" },
                  { id: 'gpt-3.5-turbo-1106',     object: 'model',   created: 1677610602, owned_by: 'openai',          permission: [], root: "gpt-3.5-turbo-1106" },
                  { id: 'gpt-4',                  object: 'model',   created: 1678604602, owned_by: 'openai',          permission: [], root: "gpt-4" },
               // { id: 'gpt-4-0314',             object: 'model',   created: 1678604602, owned_by: 'openai',          permission: [], root: "gpt-4-0314" },
               // { id: 'gpt-4-0613',             object: 'model',   created: 1678604602, owned_by: 'openai',          permission: [], root: "gpt-4-0613" },
                  { id: 'gpt-4-1106-preview',     object: 'model',   created: 1678604602, owned_by: 'openai',          permission: [], root: "gpt-4-1106-preview" },
                  { id: '--',                     object: 'divider', created: 1669599635, owned_by: 'prompt-dropdown', permission: [], root: "--" },
               ];
               let modelsAll = data.models;
               let models = [...modelsDefault, ...modelsAll];
            // console.log('Enriched Models in Frontend: ', models);
               setModels(models);
               let saveSelect = function() {
                  let select = document.querySelector('.promptGroup');
               }();
            });
         },
         getPrompts: function() {
            let groups = function() {
               let result = typeof currentGroups === 'string' && currentGroups !== '' ? currentGroups : '';
            // console.log(`currentGroups (${typeof result}): `, result);
               return result;
            }();
            fetchWithLogin(`${config.client.urlServer}/prompt/list?groups=${groups}`)
            .then(res => res.json())
            .then((data) => {
            // console.log(`Prompt-Data: `, data);
            // const visibleGroupNames = [...config.client.groupsDefault];
               const visibleGroupNames = [];
               const url = new URL(window.location.href);
               let urlParam = function() {
                  let groups = config.client.groupsParams;
                  let result = null;
                  groups.forEach(function(group) {
                  // let value = helper.url.parameter('get', group);
                     let value = helper.storage.get('groupsCurrent');
                     if (typeof value !== 'string' || value !== null || value !== 'null' || value !== '') { 
                        result = helper.url.parameter('get', 'group'); 
                     }
                     if (typeof value !== 'string' || value !== null || value !== 'null' || value !== '') { 
                        result = helper.url.parameter('get', 'groups'); 
                     }
                     if (typeof value === 'string' && value !== '') { 
                        result = value; 
                     }
                  });
                  return result;
               }();
               if (urlParam != null && urlParam !== '') {
                  urlParam = decodeURIComponent(urlParam);
                  urlParam = urlParam.replace(/ /g, '_');
                  visibleGroupNames.push(...urlParam.split(','));
               }
            // console.log(`Available prompts: `, data.result);
               data.result = data.result.filter((resultItem) => {
                  let result = null;
                  let resultItemGroupName = resultItem.group;
                  let idx = visibleGroupNames.findIndex((visibleGroupName) => 
                  // (resultItemGroupName.toLowerCase() == visibleGroupName.toLowerCase())
                     (resultItemGroupName.toLowerCase().startsWith(visibleGroupName.toLowerCase()))
                  );
                  result = (idx >= 0);
                  if (urlParam === 'all') { result = true; }
                  return result;
               });
/*
               function compare(a, b) {
                  if (a.name < b.name){ return -1; }
                  if (a.name > b.name){ return  1; }
                  return 0;
               }
               data.result = data.result.sort(compare);
               console.log(`Sorted: `, data.result);
/*
               console.log(`Prompt-Data: `, data);
               let urlParam = null;
               const url = new URL(window.location.href);
               data.visibleGroupNames = function() {
               // const result = [...config.client.groupsDefault];
                  const result = [];
                  urlParam = function() {
                     let groups = config.client.groupsParams;
                     let group = null;
                     groups.forEach(function(group) {
                     // let value = helper.url.parameter('get', group);
                        let value = helper.storage.get('groupsCurrent');
                        if (typeof value !== 'string' || value !== null || value !== 'null' || value !== '') { 
                           group = helper.url.parameter('get', 'group'); 
                        }
                        if (typeof value !== 'string' || value !== null || value !== 'null' || value !== '') { 
                           group = helper.url.parameter('get', 'groups'); 
                        }
                        if (typeof value === 'string' && value !== '') { 
                           group = value; 
                        }
                     });
                     return group;
                  }();
                  if (urlParam != null && urlParam !== '') {
                     urlParam = decodeURIComponent(urlParam);
                     urlParam = urlParam.replace(/ /g, '_');
                     result.push(...urlParam.split(','));
                  }
                  console.log(`Visible group names: `, result);
                  return result;
               }();
               data.result = function() {
                  let result = data.result;
               // console.log(`Available prompts: `, result);
                  result = result.filter((filterItem) => {
                     let filter = null;
                     let filterItemGroupName = filterItem.group;
                     let idx = data.visibleGroupNames.findIndex((visibleGroupName) => 
                     // (filterItemGroupName.toLowerCase() == visibleGroupName.toLowerCase())
                        (filterItemGroupName.toLowerCase().startsWith(visibleGroupName.toLowerCase()))
                     );
                     filter = (idx >= 0);
                     if (urlParam === 'all') { filter = true; }
                     return filter;
                  });
                  console.log(`Filtered list of prompts: `, result);
                  return result;
               }();
               data.result = function() {
                  let result = data.result;
                  result.sort((a, b) => {
                     if (a.name < b.name){ return -1; }
                     if (a.name > b.name){ return  1; }
                     return 0;
                  });
                  console.log(`Sorted list of prompts: `, result);
                  return result;
               }();
*/
               debug(null, ` \nLoading finished`);
               debug(null, `- Backend available`);
               debug(null, `- Prompt-Dropdown available`);
            // debug(null, `- Loaded Prompt Dropdown`, data.result);
               setPrompts(data.result);
            // 1.1
               let hash = helper.url.parameter('get', 'hash');
               if (typeof hash === 'string' && hash !== '') {
                  setTimeout(function() {
                     let select = document.querySelector('.choosePrompt');
                     let options = select.querySelectorAll('option');
                     let applies = false;
                     options.forEach((option) => { if (option.value === hash) { applies = true; } });
                     if (applies) { 
                        select.value = hash; 
                        let optionPrompt = null;
                        let promptLoaded = document.querySelector('.App').dataset.promptLoaded;
                        if (promptLoaded !== true && promptLoaded !== 'true') {
                           let message = function() {
                              let result = `Please proceed.`;
                              data.result.forEach((option) => {
                                 if (option.id === hash) {
                                    result = option.message;
                                    optionPrompt = option.name;
                                 }
                              });
                              return result;
                           }();
                           let chatLogNew = [ ...chatLog, 
                              { user: 'client', message: `Changed prompt to "${optionPrompt}" according to URL.` }, 
                              { user: 'gpt',    message: message } 
                           ];
                           setChatLog(chatLogNew);
                           helper.prompt.loadPrompt(hash, 'hash');
                        }
                        let saveButton = document.querySelector(`.controls #save`);
                        saveButton.disabled = false;
                     }
                  }, 100);
               }
               let loaded = document.querySelector(`.choosePrompt option[value="null"]`);
               if (loaded) { loaded.innerText = 'Choose a prompt...'; }
               feature.openView();
               let input = document.querySelector(`.chat-input-textarea`);
               input.disabled = false;
               input.focus();

               document.querySelector('.App').dataset.promptsLoaded = true;
               let buttons = document.querySelectorAll(`.message code.button[disabled]`);
               buttons.forEach(function(button) { button.disabled = false; });

               const loadShare = function() {
                  let shareSelect = document.querySelector(`.sharePrompt select`);
                  let options = [];
                  data.result.forEach(function(result) {
                     if (result.id !== 'null') { options.push(result.name+'|'+result.id); }
                  });
                  options = helper.array.sortArrOfObjByKey(options);
               // console.log(`Sorted Options: `, options);
                  options.forEach(function(result) {
                     let option = document.createElement('option');
                     option.value = result.split('|')[1];
                     option.innerText = result.split('|')[0];
                     shareSelect.append(option);
                  });
                  shareSelect.value = currentPrompt;
                  shareSelect.addEventListener('change', function(e) {
                     let shareInput = document.querySelector(`.sharePrompt input[type="text"]`);
                     shareInput.value = e.target.value;
                     helper.share.update();
                  });
               }();

               const loadGroup = function() {

                  window.setTimeout(function() {

                     let current = document.querySelector('.App').dataset.group;
                     let target =  document.querySelector(`#promptGroup select`);
                     if (target) { target.innerHTML = ''; }
                     let loaded = ['assistants'];

                     let optgroups = document.querySelectorAll('select.choosePrompt optgroup');
                     optgroups.forEach(function(optgroup) {
                     // console.log(`* optgroup: `, optgroup);
                        let key = optgroup.id;
                        let label = optgroup.label;
                        let selected = current === key ? true : false;
                        let item = document.createElement('option');
                        item.value = key;
                        item.innerText = label;
                        item.selected = selected;
                        target.append(item);
                        loaded.push(key);
                     });

                     let line = document.createElement('option');
                     line.value = '';
                     line.innerText = `--`;
                     line.classList.add('roleAdmin');
                     target.append(line);

                     let groups = helper.storage.get('groupsAvailable').split(',');
                     groups.forEach(function(group) {

                        if (!loaded.includes(group)) {
                           let item = document.createElement('option');
                           item.value = group;
                           item.innerText = group;
                           item.classList.add('roleAdmin');
                           target.append(item);
                        }

                     });

                     target.disabled = false;

                  // let confirm = document.querySelector('.promptSavingConfirm');
                  // if (confirm) { confirm.disabled = false; }

                  }, 1);

               }();

            });
         },
         handleSubmit: async function(e, useInput) {

            debug('any', ` \nNew Request`);
         // debug('any', `- useInput: `, useInput);
            
            let promptKey = document.querySelector('.prompteneer .editor').dataset.promptKey;
            debug('any', `- Prompt id:  `, promptKey);
            
            let promptGroup = function() { // 1.2
               let result = null;
               let element = document.querySelector(`.choosePrompt option[value="${promptKey}"]`);
               if (element) { result = element.parentNode.id; }
               return result;
            }();
         // let promptGroup = document.querySelector(`.choosePrompt option[value="${promptKey}"]`).parentNode.id;
         // debug('any', `- Prompt Group: ${promptGroup}`);

            let userId = window.user.id;
            debug('any', `- User id:    `, userId);
            let userCity = window.user.city;
         // debug('any', `- City:       `, userCity);
            let userDepartment = window.user.department;
         // debug('any', `- Department: `, userDepartment);
            let userFirstname = window.user.firstName;
         // debug('any', `- Firstname:  `, userFirstname);
            let userLastname = window.user.lastName;
         // debug('any', `- Lastname:   `, userLastname);
            let userPosition = window.user.title;
         // debug('any', `- Position:   `, userPosition);
            let userRole = helper.string.capitalize(helper.storage.get('userRole'));
         // debug('any', `- Position:   `, userPosition);

            e.preventDefault(); 

            let textarea = document.querySelector('.chat-input-textarea');
            let buttons = document.querySelectorAll('.message code.button[onclick]');
            const disable = function() {
               if (textarea) { textarea.disabled = true; }
               if (buttons)  { buttons.forEach((button) => { button.disabled = true; button.classList.add('disabled'); }); }
            }();

            if (typeof useInput !== 'string' || useInput === '') { useInput = input; }
            let consult = '';
            if (document.querySelector('.prompteneer .editor').value.indexOf('{prompt:') >= 0) { consult = 'Consulting Assistants '; }
            let chatLogNew = [ ...chatLog, 
               { user: 'me', message: `${useInput}` },
               { user: 'gpt', message: `${consult}...`, waiting: true },
            ];
            setChatLog(chatLogNew);

            window.setTimeout(function() {
               let waiting = document.querySelectorAll(`.chat-log > .waiting`);
               waiting.forEach(function(wait) { wait.style.display = 'block'; });
               helper.animate.waiting();
               helper.ui.scroll('chat', 'bottom', null, true);
            }, 999);

            await setInput('');
            helper.ui.scroll('chat', 'bottom', null, true);

            let fullPrompt = document.querySelector('.prompteneer .editor').value;
            let messages = chatLogNew.map((message) => message.message )[chatLogNew.length-2];
            debug('any', `- User input:  ${helper.string.shorten(messages, 'default', true)}`);

            let promptContent = helper.prompt.compress(fullPrompt, 'raw');;
            promptContent = promptContent.replace(/\n/g, ' \\n ');
            promptContent = promptContent.replace(/  /g, ' ');
         // debug('any', `- Prompt Content: ${helper.string.shorten(promptContent, 'default', true)}`);
            
            let promptAuthor = function() {
               let result = null;
            // console.log('### ', document.querySelector(`.prompteneer`).dataset.authorEmail);
               if (result === null) { result = document.querySelector(`.prompteneer`).dataset.authorEmail; }
            // if (result === null) { result = window.user.id; }
            // if (result === null) { result = 'andreas.sefzig@statista.com'; }
            // debug('any', `- Author:      ${result}`);
               return result;
            }();
         // let promptGroup = 'tools'; // Tbd

            let timestamp = helper.string.timestamp();
         // debug('any', `- Timestamp:  `, timestamp);
            
            let clientView = helper.params.get('view');
         // debug('any', `- View:       `, clientView);
            
            let backendBody = {
               message: messages,
               statisticId,
               statisticQuery,
               currentModel,
               currentTemperature,
               currentTokens,
               currentPrecision,
               currentPrompt,
               fullPrompt,
               userId,
               userCity,
               userDepartment,
               userFirstname,
               userLastname,
               userPosition,
               userRole
            };
         // debug('any', `- Body: `, backendBody);

         // debug('any', `- Creating log...`);
            
            let logCreate = await feature.log('create', {
               userId: userId,
               userCity: userCity, 
               userDepartment: userDepartment, 
               userInput: messages,
               userRole: userRole,
               promptKey: promptKey,
               promptGroup: promptGroup || 'unavailable', // 1.2
            // promptContent: promptContent,
               promptTokens: null,
               timestamp: timestamp,
               clientView: clientView,
               clientTheme: currentTheme,
               response: null
            });
            debug('any', `- Created Log: ${logCreate}`);
            document.querySelector('.prompteneer').dataset.log = logCreate;

            debug('any', `- Waiting for AI...`);
            const response = await fetchWithLogin(config.client.urlServer, {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify(backendBody)
            });

            let data = await response;
            debug('any', ` \nNew Response`);
            debug('any', `- Request was received (${data.status})`);

            if (await data.ok !== false) { 
            // chatLogNew = [ ...chatLogNew, { user: 'gpt', message: `<span class="waiting">...</span>` } ];
            // setChatLog(chatLogNew);
               data = await response.json(); 
            // debug('any', `- Response (raw):  `, data);
               console.groupCollapsed('- Engineered Prompt');
               console.log(data.prompt);
               console.groupEnd();
            // debug('any', `- Prompt:      `, { prompt: data.prompt });
            }

            else { 
               debug('any', `- An error occurred`);
               debug('any', `- Model:      ${currentModel}`);
               let betterModel = function() {
                  let result = 'a similar one';
                  if (currentModel === 'gpt-4') { result = '"gpt-4-0314"'; }
                  else if (currentModel === 'gpt-4-0314') { result = '"gpt-3.5-turbo"'; }
                  else if (currentModel === 'gpt-3.5-turbo') { result = '"gpt-4"'; }
                  return result;
               }();
            // data.message = `Something went wrong :(\n\nA log has been created and are working on it. In the meantime, please change the model to ${betterModel} and retry.`;
               debug('any', `- Recommendation: ${betterModel}`);
            }

         // console.log(`Data: `, data);

            if (data.prompt) {

            // Replace Placeholders
               let replaced = data.prompt;
            // debug('any', `- Placeholders`);

            // Replace Text Placeholders
               replaced = await async function() {
                  let result = replaced;
                  Object.entries(config.placeholders.text).forEach(function([key, value]) {
                     let search = `\{text\:${key}\}`;
                  // console.log(`search: ${search}`);
                     let replace = `\n${value.replace(/^[ ]*/gm, '')}\n   \n`;
                     result = result.replace(new RegExp(search, 'g'), replace); // 1.4
                  });
               // debug('any', `  - Text:`, result);
                  return result;
               }();

            // Replace Prompt Placeholders
               replaced = await async function() {
                  let result = replaced;
                  const promptInputs = []
                  const promptKeys = function() {
                     let keys = [];
                     let matches = result.match(/\{prompt\:[a-zA-Z0-9\:\-\_\.\;\?\,\! ]*\}/g);
                  // console.log('matches: ', matches);
                     if (matches) { 
                        matches = matches.map(s=>s.slice(1,-1)); 
                        matches.forEach(function(match, i) {
                           match = match.replace(/^prompt\:/g, '');
                           let arr = match.split(';');
                           let key = arr[1] ? arr[0] : match;
                           let input = arr[1] ? arr[1] : null;
                        // match = match.replace(/prompt\:([a-zA-Z0-9\:\-\_\.]*)/g, '$1');
                           keys.push(key);
                           promptInputs.push(input);
                        });
                     }
                  // debug('any', `  - promptKeys:`,   keys);
                  // debug('any', `  - promptInputs:`, promptInputs);
                     return keys;
                  }();

                  const promptData = await async function() {
                     let prompts = [];
                     for (const promptKey of promptKeys) {
                        let data = await fetchWithLogin(`${config.client.urlServer}/prompt/get?id=${promptKey}`, {
                           method: 'GET',
                           headers: {
                              'Allow': 'application/json',
                              'Content-Type': 'application/json',
                           }
                        });
                        data = await data.json();
                        data.result.key = promptKey;
                        prompts.push(data.result);
                     };
                     return await prompts;
                  }();
               // debug('any', `  - promptData:`, promptData);

                  const promptResults = await async function() {
                     let results = [];
                  // debug('any', `  - promptData:`, JSON.stringify(promptData));
                     for (const promptDatum of promptData) {
                     // console.log(`-------------------------------------------`);
                     // console.log(`  - promptDatum: `, promptDatum);
                        let i = promptData.indexOf(promptDatum);
                        let input = typeof promptInputs[i] === 'string' && promptInputs[i] !== '' ? promptInputs[i] : useInput;
                     // console.log(`i: ${i}, input: ${input}`);
                        chatLogNew.pop();
                        chatLogNew = [ ...chatLogNew, { user: 'gpt', message: `Consulting ${promptDatum.name}...`, waiting: true } ];
                        setChatLog(chatLogNew);
                        let datumBody = {
                           message: input,
                        // statisticId,
                        // statisticQuery,
                           currentModel: promptDatum.model,
                           currentTemperature: promptDatum.temperature,
                           currentTokens: promptDatum.tokens,
                        // currentPrecision,
                           currentPrompt: promptDatum.key, // 1.4
                           fullPrompt: promptDatum.prompt, // 1.4
                           userId,
                           userCity,
                           userDepartment,
                           userFirstname,
                           userLastname,
                           userPosition,
                           userRole
                        };
                     // debug('any', `  - datumBody:`, datumBody);
                        let datum = await fetchWithLogin(config.client.urlServer, {
                           method: 'POST',
                           headers: {
                              'Content-Type': 'application/json',
                           },
                           body: JSON.stringify(datumBody)
                        });
                        datum = await datum.json();
                     // debug('any', `  - datumJson:`, datum);
                        datum.key = promptDatum.key;
                        results.push(datum);
                     };
                     return await results;
                  }();
               // console.log(`-------------------------------------------`);
               // debug('any', `- promptResults:`, promptResults);

                  const promptPlaceholder = await async function() {
                  // debug(null, `- Result before:`, result);
                     for (const promptResult of promptResults) {
                        let i = promptResults.indexOf(promptResult);
                        let input = promptInputs[i];
                     // debug(null, `  - promptResult: `, promptResult);
                     // let value = value.replace(/^[ ]*/gm, '');
                        
                        let search = '';
                        if (typeof input === 'string' && input !== '') { 
                           search = `\{prompt\:${promptResult.key}\;${input.replace(/\?/g, '\?').replace(/\!/g, '\!').replace(/\,/g, '\,')}\}`; 
                        }
                        else { 
                           search = `\{prompt\:${promptResult.key}\}`; 
                        }
                        debug('any', `- Replacing "${search}"...`);
                     // result = result.replace(new RegExp(search, 'g'), promptResult.message); // 1.4
                        result = result.replace(search, promptResult.message);
                        result = result.replace(search, promptResult.message);
                        result = result.replace(search, promptResult.message);
                     };
                  // debug('any', `- Result after:`, result);
                     return true;
                  }();
               // debug('any', `- Chained:`, promptPlaceholder); // 1.7

                  return result;
               }();
               
            // Compress prompt
               data.prompt = helper.prompt.compress(replaced, 'raw');
            // debug('any', `- Compressed: `, helper.string.shorten(data.prompt, 'default', true));

            }

            if (data.statistic) {
            // debug('any', `- Statistics: `, data.statistic);
            }

            if (data.tokens) {
               debug('any', `- Tokens used: ${data.tokens}`);
               let untilNow = helper.storage.get('userTokens');
               untilNow = untilNow === null ? 0 : untilNow === 'null' ? 0 : parseInt(untilNow);
            // console.log(`  - untilNow (${typeof untilNow}): ${untilNow}`);
               let untilNext = untilNow + data.tokens;
            // console.log(`  - untilNext (${typeof untilNext}): ${untilNext}`);
               if (typeof untilNext === 'number') {
                  helper.storage.set('userTokens', untilNext);
                  let tokenDisplay = document.querySelector(`.sidebar .tokenDisplay input`);
                  tokenDisplay.value = helper.number.thousand(untilNext);
               // console.log(`  - Updated Sidebar input`);
               }
            }

            if (data.keywords) {
            // debug('any', `- Keywords:   `, data.keywords);
            }

            if (data.ok === false) { 
               debug('any', `- Something is wrong with the API`);
            // chatLogNew.pop();
            }
            if (typeof data.message === 'undefined' ||  data.message === 'undefined') {
               debug('any', `- Something is wrong with the AI`);
               return;
            }

            let waiting = document.querySelectorAll(`.chat-log > .waiting`);
            waiting.forEach(function(wait) { wait.style.display = 'none'; wait.classList.remove('waiting'); wait.classList.add('waited'); });
            setChatLog([...chatLogNew, { user: 'gpt', message: `${data.message}`, type: 'response', feedback: true, prompt: promptKey, author: promptAuthor, group: promptGroup, request: messages, response: data.message }]);
         // debug('any', `- Response:    ${helper.string.shorten(data.message, 'default', true)}`);
            
         // debug('any', `- Updating log...`);
            let logUpdate = await feature.log('update', {
               userId: userId,
               userCity: userCity, 
               userDepartment: userDepartment, 
               userInput: messages,
               userRole: userRole,
               promptKey: promptKey,
               promptGroup: promptGroup || 'unavailable',
            // promptContent: promptContent,
               promptTokens: data.tokens,
               timestamp: timestamp,
               clientView: clientView,
               clientTheme: currentTheme,
               response: data.message
            }, logCreate);
            debug('any', `- Updated Log: `, logUpdate);
            
            helper.ui.scroll('chat', 'bottom', 300, true);
            
            textarea.dataset.last = messages;
            textarea.focus();

            textarea.disabled = false;
            if (buttons)  { buttons.forEach((button) => { button.disabled = false; button.classList.remove('disabled'); }); }

            let count = feature.loggedOut('renewCounting');
         // debug('any', `- Logout Counter reset from ${count} minutes`);

         // debug('any', `- Response (fine): `, data);
            debug('any', `- Response is displayed`);

         },
         promptFields: async function(promptKey) {
         // console.log(`promptOptions: ${promptKey}`);
            const hideField = function(selector) {
               document.querySelector(selector).style.display = 'none';
            };
            const showField = function(selector) {
               document.querySelector(selector).style.display = 'block';
            };
            const setField = function(selector, value) {
               let elem = document.querySelector(selector);
               elem.value = value;
               showField(selector);
            };
   
            const promptData = await async function() { 
               let result = await fetchWithLogin(`${config.client.urlServer}/prompt/get?id=${promptKey}`, {
                  method: 'GET',
                  headers: {
                     'Allow': 'application/json',
                     'Content-Type': 'application/json',
                  }
               });
               result = await result.json();
               result = result.result;
            // console.log(`Fields for "${promptKey}": `, result);
               return result;
            }();
   
            if      (promptData.role                  === true)     { showField('.role'); }
            else if (promptData.role                  === false)    { hideField('.role'); }
            else if (typeof promptData.role           === 'string') {  setField('.role', promptData.role); }
   
            if      (promptData.precision             === true)     { showField('.precision'); }
            else if (promptData.precision             === false)    { hideField('.precision'); }
            else if (typeof promptData.precision      === 'string') {  setField('.precision', promptData.precision); }
   
            if      (promptData.statisticId           === true)     { showField('.statisticId'); }
            else if (promptData.statisticId           === false)    { hideField('.statisticId'); }
            else if (typeof promptData.statisticId    === 'number') {  setField('.statisticId', promptData.statisticId); }
   
            if      (promptData.statisticQuery        === true)     { showField('.statisticQuery'); }
            else if (promptData.statisticQuery        === false)    { hideField('.statisticQuery'); }
            else if (typeof promptData.statisticQuery === 'string') {  setField('.statisticQuery', promptData.statisticQuery); }
   
            if      (promptData.temperature           === true)     { showField('.temperatures'); }
            else if (promptData.temperature           === false)    { hideField('.temperatures'); }
            else if (typeof promptData.temperature    === 'number') {  setField('.chooseTemperature', promptData.temperature); }
   
            if      (promptData.tokens                === true)     { showField('.tokens'); }
            else if (promptData.tokens                === false)    { hideField('.tokens'); }
            else if (typeof promptData.tokens         === 'number') {  setField('.tokens', promptData.tokens); }
   
            if      (promptData.models                === true)     { showField('.models'); }
            else if (promptData.models                === false)    { hideField('.models'); }
         // Setting field not possible due to React wtf
         },
         openView: function() {
            let view = helper.params.get('view');
            let opened = null;
            if (view !== null) {
               if (view !== 'client') { debug(null, ` \nOpen view`); }
               if (view !== 'client') { debug(null, `- Choice:      ${view}`); }
               if (view === 'editor') {
                  helper.ui.prompteneer(true);
                  opened = 'Editor';
               }
               else if (view === 'settings') {
                  helper.settings.toggle(true);
                  opened = 'Settings';
               }
               else if (view === 'trouble') {
                  helper.trouble.toggle(true);
                  opened = 'Trouble';
               }
               else if (view === 'share') {
                  helper.share.toggle(true);
                  opened = 'Share';
               }
               else if (view === 'widget') {
                  opened = 'Widget';
               }
               else if (view === 'assistants') {
                  opened = 'Assistants';
               }
               else if (view === 'client') {
                  opened = 'Client';
               }
               if (view !== 'client') { debug(null, `- Opened:      ${opened}\n `); }
            }
         },
         insertPlaceholder: function() {
            
         },
         log: async function(method, obj, id) {

            if (typeof obj !== 'object') { return false; }

            if (method === 'create') {
               id = `${window.user.firstName}-${window.user.lastName}_${obj.timestamp}`;
               obj.id = id;
            // console.log(`# Creating log ${id}: `, obj);
               let result = null;
               try {
                  result = await fetchWithLogin(`${config.client.urlServer}/log/set?id=${id}`, {
                     method: 'POST',
                     headers: {
                        'Allow': 'application/json',
                        'Content-Type': 'application/json',
                     },
                     body: JSON.stringify(obj),
                  });
                  result = await result.json();
                  result = await result;
               } catch {
                  console.log(`- Not created`);
               }
            // console.log(result);
               return id;
            }

            else if (method === 'update') {
               obj.id = id;
            // console.log(`# Updating log ${id}: `, obj);
               let result = null;
               try {
                  result = await fetchWithLogin(`${config.client.urlServer}/log/set?id=${id}`, {
                     method: 'POST',
                     headers: {
                        'Allow': 'application/json',
                        'Content-Type': 'application/json',
                     },
                     body: JSON.stringify(obj),
                  });
               } catch {
                  console.log(`- Not updated`);
               }
               result = await result.json();
               result = await result;
            // console.log(result);
               return true;
            }

         },
         initParams: function() {
            let params = Object.fromEntries(new URLSearchParams(window.location.search));
            if (params.view === 'assistants') { params.view = 'widget';     } // 1.9
            if (params.view === 'test')       { params.view = 'assistants'; } // 1.9
            Object.entries(params).forEach(function([key, value]) {
               if (config.client.allowedParams.indexOf(key) > -1) {
                  value = decodeURIComponent(value); 
                  if (params.length > -1) { debug(null, `- ${key}: ${value}`); }
                  helper.params.set(key, value);
               }
            });
            if (params.length > -1) { debug(null, ` \nLoading from URL`); }
            let hash = window.location.hash;
            hash = hash.replace('#', '');
            if (typeof hash === 'string' && hash !== '') {
               helper.params.set('prompt', hash);
               if (params.length > -1) { debug(null, `- Prompt: ${hash} \n`); }
            }
            let wait = window.setInterval(function() {
               if (document.querySelector('.App')) {
                  let view =   helper.params.get('view');
                  let title =  helper.params.get('title');
                  let text =   helper.params.get('text');
                  let button = helper.params.get('button');
                  if (typeof title  === 'string') { document.querySelector('.loggedOut h1 span').innerText = title;  }
                  if (typeof title  === 'string') { document.querySelector('#signTitle'        ).innerText = title;  }
                  if (typeof text   === 'string') { document.querySelector('#signText'         ).innerText = text;   }
                  if (typeof button === 'string') { document.querySelector('#signIn'           ).innerText = button; }
                  clearInterval(wait);
               }
            }, 100);
            feature.loggedOut('init');
         },
         loadPromptByHash: function() {
            let func = function() { // 1.1
               let prompt = helper.params.get('prompt');
               if (typeof prompt === 'string' && prompt !== '') {
                  helper.prompt.loadPrompt(prompt, 'hash');
               // document.querySelector(`.chat-input-textarea`).disabled = false;
                  document.querySelector(`.App`).dataset.promptLoaded = true;
               }
               feature.openView();
            };
            window.setTimeout(function() { func(); }, 1);
            window.setTimeout(function() { func(); }, 100);
            window.setTimeout(function() { func(); }, 500);
            window.setTimeout(function() { func(); }, 1050);
            window.setTimeout(function() { func(); }, 2000);
            window.setTimeout(function() { func(); }, 5000);
         },
         saveGroupsToStorage: function() {
            let result = [];
            const insert = function(arr, str) {
               arr = arr || [];
               str = str.replace(/,null,/g, ',').replace(/Live$|Stage$|Dev$/g, '').replace(/,,/g, ',').replace(/^,|,$/g, '');
               str.split(',').forEach(function(item) {
                  arr = helper.array.insert(arr, item);
               });
               return arr;
            };
            result = insert(result, helper.url.parameter('get', 'group' ) || helper.params.get('group'));
            result = insert(result, helper.url.parameter('get', 'groups') || helper.params.get('groups'));
            result = insert(result, helper.storage.get('groupsAvailable', 'string'));
            result = result.join(',');
            helper.storage.set('groupsAvailable', result);
         // console.log(`Saved groups: `, result);
         },
         chooseAssistant: function(key) {
            console.log(`chooseAssistant: ${key}`);
            window.location.hash = `#${key}`;
            helper.prompt.loadPrompt(key);
            let inactive = document.querySelectorAll(`.sidebar > .assistants .active`);
            inactive.forEach(function(assistant) { assistant.classList.remove('active')});
            let active = document.querySelector(`.sidebar > .assistants [value="${key}"]`);
            if (active) { active.classList.add('active'); }
         },
         closeAssistant: function() {
            console.log(`closeAssistant`);
            const url = new URL(window.location.origin);
            let params = Object.fromEntries(new URLSearchParams(window.location.search));
            Object.entries(params).forEach(function([key, value]) {
               if (key !== 'view') {
                  url.searchParams.append(key, value);
               }
               else {
                  url.searchParams.append('view', 'client');
               }
            });
            let hash = window.location.hash;
            if (hash) { url.hash = hash; }
            window.location.assign(url);
         },
         loggedOut: function(method) {
            let screen = document.querySelector(`.loggedOut`);
            if (method === 'open') {
               screen.style.display = 'block';
            }
            if (method === 'close') {
               screen.style.display = 'none';
            }
            if (method === 'countup') {
               let count = parseInt(screen.dataset.minutes);
               count++;
               screen.dataset.minutes = count;
               if (count >= config.client.timeout) {
                  feature.loggedOut('open');
               }
            }
            if (method === 'renewCounting') {
               let count = parseInt(screen.dataset.minutes);
               screen.dataset.minutes = 0;
               return count;
            }
            if (method === 'init') {
               screen.dataset.minutes = 0;
               let timer = window.setInterval(function() {
                  feature.loggedOut('countup');
               }, (1000 * 60));
            }
         },
      };
      
      const sso = {
         signIn: function() {

         // Debug
            debug('signin', `Signing in current user`);

         // Flow
            auth.login();

         },
         signedIn: function(userData) {

            let d = false;
            if (d) { debug('any', `Signing in: `, userData); }

         // Adapt role
            let adaptRole = function() {
               let role = null;
               if (d) { debug('any', `- Setup role`); }
               if      (userData.isRoleAdmin)     { role = 'admin'; }
               else if (userData.isRoleAngel) { role = 'angel'; }
               else if (userData.isRoleManager)   { role = 'manager'; }
               else if (userData.isRoleUser)      { role = 'user'; }
               if (d) { debug('any', `  - Role by AD: ${role} (${typeof role})`); }
               let current = helper.storage.get('userRole');
               if (d) { debug('any', `  - Role by LS: ${current} (${typeof current})`); }
               let isSetup = typeof current === 'string' && current !== '' && current !== 'null' && current !== null ? true : false;
               if (d) { debug('any', `  - Is set up:  ${isSetup} (${typeof isSetup})`); }
               if (!isSetup) {
                  helper.storage.set('userRole', role);
                  helper.settings.roles('init', role);
               }
               else {
                  role = current;
               // helper.settings.roles('set', role);
                  let setting = document.querySelector(`.settingsRole select`);
                  setting.value = role;
               // if (d) { debug('any', `  - Already set up: ${role} (${typeof role})`); }
               // if (role === 'user')      { window.setTimeout(function() { helper.settings.roles('set', 'user');      }, 1000); }
               // if (role === 'manager')   { window.setTimeout(function() { helper.settings.roles('set', 'manager');   }, 1000); }
               // if (role === 'angel')     { window.setTimeout(function() { helper.settings.roles('set', 'angel');     }, 1000); }
               // if (role === 'admin')     { window.setTimeout(function() { helper.settings.roles('set', 'admin');     }, 1000); }
               }
               if (d) { debug('any', `  - Finalized: ${role}`); }
               return role;
            }();

         // Adapt groups
            let adaptGroups = function() {

               let result = [];
               if (d) { debug('any', `- Setup groups`); }

               let isSetup = helper.storage.get('groupsAvailable', 'string');
               isSetup = typeof isSetup === 'string' && isSetup !== '' && isSetup !== 'null' && isSetup !== null ? true : false;
               if (d) { debug('any', `  - Is set up:  ${isSetup}`); }

               let department = userData.department;
               if (d) { debug('any', `  - Department: ${department}`); }
               
               let role = adaptRole;
               if (d) { debug('any', `  - Role:       ${role}`); }
               
               result = config.client.groupsDefault;
            // if (d) { debug('any', `- Defaults:  `, result); }

               delete result[undefined];

               if (d) { debug('any', `  - Checking groups`); }
               Object.entries(config.statista.groupsAvailable).forEach(function([key, array]) {

                  let isApplicable = false;
                  
                  array.forEach(function(value) {
                     if (department) { // SSOF
                        if (department.match(new RegExp(value, 'g')) || 'role'+role === value) {
                        // console.log(`- Matches Regex: ${key}`);
                        // console.log(`- Value: `, result);
                           result = helper.array.insert(result, key);
                           isApplicable = true;
                        }
                     }
                  });

                  if (d) { debug('any', `    - ${key}: ${isApplicable}`); }

               });

               delete result[undefined];

               result = helper.array.insert(result, helper.storage.toPersonal(userData.mail));
               if (d) { debug('any', `  - Adapted:   `, result); }
            // if (!result[helper.storage.toPersonal(window.user.id)]) {
            //    result.push(helper.storage.toPersonal(window.user.id));
            // }

               result = result.join(',');

               if (!isSetup) {

                  if (d) { debug('any', `  - Setting up Groups: `, result); }
                  helper.storage.set('groupsCurrent',   result);
                  helper.storage.set('groupsAvailable', result);

                  helper.settings.user('init');
                  
               }

               else {
               // result = helper.storage.get('groupsAvailable');
               }

               if (d) { debug('any', `  - Finalized:  ${result.replace(/,/g, ', ')}`); }
               return result;

            }();

         // Update Settings
            let updateSettings = function() {
               document.querySelector('span.userName').innerText =       `${userData.firstName} ${userData.lastName}`;
               document.querySelector('span.userName').title =           `${userData.firstName} ${userData.lastName}`;
               document.querySelector('span.userEmail').innerText =      `${userData.mail}`;
               document.querySelector('span.userEmail').title =          `${userData.mail}`;
               document.querySelector('span.userCity').innerText =       `${userData.city}`;
               document.querySelector('span.userCity').title =           `${userData.city}`;
               document.querySelector('span.userDepartment').innerText = `${userData.department}`;
               document.querySelector('span.userDepartment').title =     `${userData.department}`;
               document.querySelector('span.userLevel').innerText =      `${userData.levelNumber}`;
               document.querySelector('span.userLevel').title =          `${userData.levelNumber}`;
            }();

         // Update User Id
            let updateId = function() {
               let isSetup = helper.storage.get('userId');
               if (isSetup === null || isSetup === 'null' || isSetup === '') {
                  helper.storage.set('userId', userData.id);
                  document.querySelector('input.userEmail').value =   userData.id;
               }
            }();

         // Adjust view
            let adjustView = function() {
               document.querySelector('.signIn.signedIn').style.display =  'block';
               document.querySelector('.signIn.signedIn').style.display =  'none';
               document.querySelector('.signIn.signedOut').style.display = 'none';
               document.querySelector('option[value="signIn"]').style.display = 'none';
               document.querySelector('option[value="signOut"]').style.display = 'block';
               document.querySelector('.signIn').classList.remove('ready');
               document.querySelector('.signIn').classList.add('notready');
               document.querySelector('.App').dataset.signedIn = true;
            }();
            
         // Update Profile
            let updateProfile = function() {
               Object.entries(userData).forEach(function([key, value]) {
                  window.user[key] = value;
               });
            }();

         // Mark Login
         // setCurrentLogin(true);

         // Debug
            let debug = function() {
               if (typeof window.user.id === 'string' && window.user.initialized !== true) {
                  console.log(`Signed in successfully`);
                  console.log(`- Name:        ${userData.firstName} ${userData.lastName}`);
                  console.log(`- Title:       ${userData.title}`);
                  console.log(`- Email:       ${userData.id}`);
                  console.log(`- City:        ${userData.city}`);
                  console.log(`- Department:  ${userData.department}`);
               // console.log(`- Groups:      ${adaptGroups.replace(',', ', ')}`);
               // console.log(`- Level:       ${userData.levelNumber}`);
                  console.log(`- Role:        ${adaptRole}`);
                  window.user.initialized = true;
               }
            }();

         // feature.getPrompts();
            
         },
         signOut: function() {

         // Debug
            console.log(`Signing out current user`);

         // Flow
            auth.logout();

         },
         signedOut: function() {

         // Adjust view
            document.querySelector('.signIn.signedOut').style.display = 'block';
            document.querySelector('.signIn.signedIn').style.display =  'none';
            document.querySelector('option[value="signIn"]').style.display = 'block';
            document.querySelector('option[value="signOut"]').style.display = 'none';

            document.querySelector('.signIn').classList.remove('notready');
            document.querySelector('.signIn').classList.add('ready');

         // Debug
            console.log(`Signed out`);

         },
         setupRole: function() {

         },
      };

      auth.onLogin((err, userdata) => {
         if (userdata != null) {
            sso.signedIn(userdata);
         }
      });

      setTimeout(() => {
         let element = document.querySelector('.prompteneer .editor');
         let status = element.dataset.promptNull;
         if (status !== 'true') {
            helper.prompt.loadPrompt('null');
            element.dataset.promptNull = 'true';
         }
      }, 100);

      setTimeout(() => {
         helper.ui.defaultModel();
      }, 1000);
      
      return (
         <div className={`App`} data-theme={currentTheme} data-role={`${helper.ui.roleAttr()}`} data-highest={`${helper.ui.highestAttr()}`} data-policy-confirmed={`${policyConfirmed}`}>
            <div className='sidebar-replacement' onClick={(e) => {
               document.querySelector('.App').classList.toggle('extended');
            }}>
               <span class="closed">≡</span>
               <span class="opened">x</span>
            </div>
            <aside className="sidebar"> 
               <div className="client">
                  <div className="sidebar-header">
                     <h1>
                        <img src="img/logo_light.png" />
                        Statista AI Client
                     </h1>
                  </div>
                  <div className='prompts'>
                     <h3>
                        Prompt
                        <i title="Choose from the Prompts which are available to you (depending on the Groups activated in your settings). Be aware of the Group-postfix '(live)', which indicates prompts which have been cleared by Managers. As a regular User, you will not be able to save Prompts to Live.">i</i>
                     </h3>
                     <select className='choosePrompt' defaultValue={config.client.prompt} onChange={async function(e) {
                        let promptKey = e.target.value;
                        const promptData = await async function() { 
                           let result = await fetchWithLogin(`${config.client.urlServer}/prompt/get?id=${promptKey}`, {
                              method: 'GET',
                              headers: {
                                 'Allow': 'application/json',
                                 'Content-Type': 'application/json',
                              }
                           });
                           result = await result.json();
                           result = result.result;
                           return result;
                        }();
                        setTimeout(() => {
                           let name = 'something';
                           let chatLogNew = [ ...chatLog, { user: 'client', message: `Changed prompt to "${name}".` } ];
                           setChatLog(chatLogNew);
                        // helper.chat.sendMessage('client', `Switched prompt to "${promptData.name}".`);
                           const cta = function() {
                           // helper.chat.sendMessage('gpt', promptData.description.trim());
                           }();
                           const load = function() {
                              helper.prompt.loadPrompt(promptKey);
                           }();
                           let status = document.querySelector('body').dataset.prompteneer;
                           if (status === 'true') { helper.ui.prompteneer(true); }
                           helper.url.parameter('set', 'hash', promptKey);
                           feature.openView();
                           helper.ui.scroll('chat', 'bottom', null, true);
                        // helper.ui.scroll('editor', 'top');
                           setTimeout(() => { helper.ui.scroll('chat', 'bottom', null, true); }, 300);
                        }, 100)
                        setCurrentPrompt(promptKey); 
                        feature.promptFields(promptKey);
                        let remove = document.querySelector(`option[value="null"]`);
                        if (remove) { remove.remove(); }
                        let app = document.querySelector(`.App`);
                        app.classList.remove('extended');
                        app.dataset.promptLoaded = true;
                     }}>
                        <option key="null" value="null" selected disabled>Loading Prompts...</option>
                        <option key="noPromptDesign" value="noPromptDesign" selected>No Prompt Design</option>
                        <optgroup id="assistants" label="Assistants">
                           <option value="assistantAiclient_0-1" class="">AI Client</option>
                           <option value="assistantHumanResources_1-0" class="roleManager roleAngel roleAdmin">Human Resources</option>
                           <option value="assistantOfficeManagement_1-0" class="">Office Management</option>
                           <option value="assistantApiSupport_0-5" class="">Statista Content API</option>
                           <option value="statistaR_AI_helpdesk_0-1" class="roleManager roleAngel roleAdmin">Statista R Helpdesk</option>
                           <option value="assistantUniversity_0-5" class="roleManager roleAngel roleAdmin">Statista University</option>
                           <option value="assistantUnhelpful_0-1" class="">The Unhelpful by Benny</option>
                           <option value="assistantSearch_0-1" class="roleAngel roleAdmin italic">Search our Assistants</option>
                        </optgroup>
                        {(() => {

                           let userId = helper.storage.toPersonal(window.user.id);

                        // 1.1
                           let groupList = function() {
                           // let promptsSorted = JSON.parse(JSON.stringify(prompts));
                           // function compare(a, b) {
                           //    if (a.name < b.name){ return -1; }
                           //    if (a.name > b.name){ return  1; }
                           //    return 0;
                           // }
                           // promptsSorted = promptsSorted.sort(compare);

                              let array = [...new Set(prompts.map((prompt) => prompt.group))];
                           // array = helper.array.sort(array);
                           // console.log(`GroupList: `, array);

                              const optimizeFirst = function() {
                                 array = helper.array.sort(array);
                              // array = helper.array.sortArrOfObjByKey(array);
                              // console.log(`Array optimized:`, array);
                              }();
                              const followWithTests = function() {
                                 let arrayNew = [...array];
                                 arrayNew.forEach(function(item) {
                                 // console.log(`Test:`, item);
                                    if (item.indexOf('testsLive') >= 0 || item.indexOf('testsStage') >= 0 || item.indexOf('testsDev') >= 0) {
                                    // console.log(`Is Test:`, item);
                                       array = helper.array.insert(array, item, 'prepend');
                                    }
                                 });
                              }();
                              const followWithDepartments = function() {
                                 let arrayNew = array;
                                 arrayNew.forEach(function(item) {
                                 // console.log(`Test:`, item);
                                    if (item.indexOf('Live') > 0 || item.indexOf('Stage') > 0 || item.indexOf('Dev') > 0) {
                                    // console.log(`Is Dept:`, item);
                                 // // item = item.replace(/Live$|Stage$|Dev$/g, '');
                                       array = helper.array.insert(array, item, 'prepend');
                                    }
                                 });
                              }();
                              const followWithTools = function() {
                                 let index = array.indexOf('tools') >= 0;
                                 if (index) { 
                                    array = helper.array.insert(array, 'tools', 'prepend');
                                 }
                              }();
                              const followWithPersonal = function() {
                                 let index = array.indexOf(userId) >= 0;
                                 if (index) { 
                                    array = helper.array.insert(array, userId, 'prepend');
                                 }
                              }();
                           // const followWithAssistants = function() {
                           //    let index = array.indexOf('assistants') >= 0;
                           //    if (index) { 
                           //       array = helper.array.sort(array);
                           //       array = helper.array.insert(array, 'assistants', 'prepend');
                           //    }
                           // }();
                           // const startWithDefault = function() {
                           //    let index = array.indexOf('default') >= 0;
                           //    if (index) { 
                           //       array = helper.array.insert(array, 'default', 'prepend');
                           //    }
                           // }();
                              const endWithTemplates = function() {
                                 let index = array.indexOf('templates') >= 0;
                                 if (index) { 
                                    array = helper.array.insert(array, 'templates', 'append');
                                 }
                              }();
                           // console.log(`array.length: ${array.length}`);
                           // if (array.length > 0) { console.log(`- Prompt groups: `, JSON.stringify(array)); }
                              return array;
                           }();

                           return groupList.map((groupId) => {

                           // console.log(`- Group Id:    ${groupId}`);

                              let promptsInGroup = prompts.filter((prompt) => (prompt.group === groupId));
                              promptsInGroup = promptsInGroup.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : 0));
                           // console.log(`  - Group Prompts: `, promptsInGroup);
                              
                              let groupPromptTemplate = {
                                 "id": config.client.personalDefault,
                                 "name": config.client.personalFallback,
                              // "description": "Let me answer according to your prompt.\n\nEdit this template as you like!\n\Add an example: `Enter an example`",
                              // "model": config.openai.model,
                              // "precision": config.openai.precision,
                              // "temperature": config.openai.temperature,
                              // "statisticId": null,
                              // "statisticQuery": null,
                              // "tokens": config.openai.tokens,
                                 "group": helper.storage.toPersonal(window.user.id),
                                 "label": `${window.user.firstName} ${window.user.lastName}`,
                              // "locked": false,
                              // "role": "user",
                              // "prompt": `// Meta\n// Author: [Your name]\n// Date: [Today]\n// Description: [Short explanation of this prompt].\n   \n// -----------------------------\n// Briefing\n// -----------------------------\n   \n// Impersonation\n// Tba\n   \n// Task\n// Tba\n// ... based on the Briefing at the end of this prompt. \n   \n// Context\n// Tba\n   \n// Exclusions\n// Tba\n// Do not hallucinate, base your answer solely on information contained in this prompt. \n   \n// Formatting\n// Tba\n   \n// -----------------------------\n// Examples\n// -----------------------------\n   \n// Input\n   Here is an exemplary input: \n// Tba\n   \n// Output\n   Here is an example of a good answer to the exemplary input above: \n// Tba\n   \n// -----------------------------\n// Finish\n// -----------------------------\n   \n// Merge Input\n   This is my Briefing: \n   {input}`,
                              // "authorRole": helper.storage.get('userRole'),
                              // "hidden": false,
                              };
                              
                           // console.log(`Check length: ${promptsInGroup.length} (${promptsInGroup.length === 0})`);
                              if (groupId === helper.storage.toPersonal(window.user.id) && promptsInGroup.length === 0) {
                                 promptsInGroup.push(groupPromptTemplate);
                              }

                              let groupPersonal = helper.storage.toPersonal(window.user.id);
                              let groupLabel = '';
                              promptsInGroup.forEach((prompt) => {

                                 let groupCheck = groupId === groupPersonal;
                                 groupLabel = groupId;

                                 if (groupCheck === true) {
                                    groupLabel = `${window.user.firstName} ${window.user.lastName}`;
                                 }
                                 else {
                                    groupLabel = prompt.label;
                                 }
      
                              // console.log(`- Label`);
                              // console.log(`  - Id:       ${groupId}`);
                              // console.log(`  - Personal: ${groupPersonal}`);
                              // console.log(`  - Prompt:   `, prompt);
                              // console.log(`  - Label:    `, prompt.label);
                              // console.log(`  - Check:    ${groupCheck}`);
                              // console.log(`  - Result:   ${groupLabel}`);
      
                              });

                              let groupData = promptsInGroup.map((prompt) => {

                                 let groupHide = function() {
                                    let result = false;
                                    if ( window.user.isRoleUser && prompt.label.indexOf(/test[A-Z]/) === 0 ) { result = true; }
                                    if (!window.user.isRoleUser && prompt.label.indexOf(' (dev)') === prompt.label.length - 5) { result = true; }
                                    return result;
                                 }();
                              // console.log(`${groupId} -> ${groupHide} (${typeof window.user.isRoleUser})`);
      
                                 if (prompt.id !== 'null' && !groupHide) {

                                    if (prompt.group === 'templates' && prompt.id === config.client.personalDefault) { return; }
      
                                    let roleClass = '';
                                    if (groupLabel === 'Templates' && prompt.name === 'Statistic')  { roleClass = 'roleAngel roleAdmin'; }
                                    if (groupLabel === 'Templates' && prompt.name === 'Statistics') { roleClass = 'roleAngel roleAdmin'; }

                                    return (
                                       <option key={prompt.id} value={prompt.id} className={roleClass}>
                                          {prompt.name}
                                       </option>
                                    );

                                 }

                              });

                              let group = '';

                           // if (groupId !== 'default' && groupId !== helper.storage.toPersonal(window.user.id)) { 
                           // 1.1
                              if (groupId !== 'default' && groupId !== 'assistants') { 
                                 group = <optgroup id={groupId} label={groupLabel === 'Default' ? '' : groupLabel}>
                                    {groupData}
                                 </optgroup>;
                              }
                              else {
                              // group = groupData; // 1.1
                              }

                              let saver = document.querySelector(`.promptSavingDialogue`);
                              saver.dataset[`promptGroup_${groupId}`] = true;

                              return group;

                           });

                        })()}
                     </select>
                  </div>
                  <div className='statisticId roleAngel roleAdmin'>
                     <h3>
                        Statistic Id
                        <i title="Insert the id of a Statistic. This will make various {placeholders} available in the prompt, which will be filled upon submit dynamically with data from our API.">i</i>
                     </h3>
                     <input type='text' defaultValue={config.statista.statisticId} placeholder={config.client.placeStatId} onBlur={(e) => {
                        setCurrentStatisticId(e.target.value);
                     // console.log(`Entered Statistic ID:  ${e.target.value}`);
                        let message = e.target.value !== '' ? `Changed Statistic id to "${e.target.value}".` : `Removed Statistic id.`;
                        let chatLogNew = [ ...chatLog, { user: 'client', message: message } ];
                        setChatLog(chatLogNew);
                        helper.ui.scroll('chat', 'bottom', null, true);
                     }} />
                  </div> 
                  <div className='statisticQuery roleAngel roleAdmin'>
                     <h3>
                        Statistic Query
                        <i title="Insert search keywords which will load the data of the top 5 most relevant Statistics from our API and make it available for your prompt with the placeholder {data}.">i</i>
                     </h3>
                     <input type='text' defaultValue={config.statista.statisticQuery} placeholder={config.client.placeStatQuery} onBlur={(e) => {
                        setCurrentStatisticQuery(e.target.value);
                     // console.log(`Entered Statistic Query:  ${e.target.value}`);
                        let message = e.target.value !== '' ? `Changed Statistic query to "${e.target.value}".` : `Removed Statistic query.`;
                        let chatLogNew = [ ...chatLog, { user: 'client', message: message } ];
                        setChatLog(chatLogNew);
                        helper.ui.scroll('chat', 'bottom', null, true);
                     }} />
                  </div> 
                  <div className='precision roleAngel roleAdmin'>
                     <h3>
                        Precision
                        <i title="Choose a precision in conjunction with the Statistic Query, so that the AI will formulate an answer as precise as you chose.">i</i>
                     </h3>
                     <select className='choosePrecision' defaultValue={currentPrecision} onChange={(e) => {
                        let precisionKey = e.target.value;
                        setCurrentPrecision(precisionKey);
                     // console.log(`Chosen Precision: ${precisionKey}`); 
                        let name = document.querySelector(`option[value="${precisionKey}"]`).innerText;
                        let chatLogNew = [ ...chatLog, { user: 'client', message: `Changed Precision to "${name.trim()}".` } ];
                        setChatLog(chatLogNew);
                        helper.ui.scroll('chat', 'bottom', null, true);
                     }}>
                        <option key="0" value="null"    >null    </option>
                        <option key="5" value="ultra"   >Ultra   </option>
                        <option key="4" value="high"    >High    </option>
                        <option key="3" value="medium"  >Medium  </option>
                        <option key="2" value="low"     >Low     </option>
                        <option key="1" value="joke"    >Joke    </option>
                     </select>
                  </div>
                  <div className='models'>
                     <h3>
                        Model
                        <i title="Choose from the models which are available in our OpenAI-account (which are all, currently). Generally recommended models are 'text-davinci-003' (fastest and cheapest but a bit stupid), 'gpt-3.5-turbo' (fast and cheap and quite smart), or 'gtp-4' (slower and expensive but really smart). Or try out the various other models which all have their reason for existence.">i</i>
                     </h3>
                     <select className='chooseModel' defaultValue={currentModel} onChange={(e) => {
                        let modelKey = e.target.value;
                        if (modelKey.indexOf('--') === 0) { e.target.value = currentModel; return; }
                        config.openai.model = modelKey;
                        setCurrentModel(modelKey);
                        let chatLogNew = [ ...chatLog, { user: 'client', message: `Changed Model to "${modelKey}".` } ];
                        setChatLog(chatLogNew);
                        debug('any', ` \nChanged Model`);
                        debug('any', `- From:        ${currentModel}`);
                        debug('any', `- To:          ${modelKey}`);
                        helper.ui.scroll('chat', 'bottom', null, true);
                     }}>
                        <option key='loading' value='loading'>Loading models...</option>
                        {
                           models.map((model, index) => {
                              return (
                                 <option key={model.id} value={model.id}>
                                    {model.id}
                                 </option>
                              );
                           })
                           
                        }
                     </select>
                  </div>
                  <div className='temperatures'>
                     <h3>
                        Temperature
                        <i title="Choose how creative/loose/hallucinating you want the AI to be. 0 is uncreative and 1 is super-creative. Try to optimize task-oriented prompts for the temperature 0.2, which is quite reliable. Try to optimize your generative prompts for the temperatur 0.8, which is creative but not crazy.">i</i>
                     </h3>
                     <select className='chooseTemperature' defaultValue={currentTemperature} onChange={(e) => {
                        let chosen = e.target.value;
                        setCurrentTemperature(chosen);
                     // console.log(`Chosen Temperature: ${chosen}`);
                        let chatLogNew = [ ...chatLog, { user: 'client', message: `Changed Temperature to ${chosen}.` } ];
                        setChatLog(chatLogNew);
                        helper.ui.scroll('chat', 'bottom', null, true);
                     }}>
                        <option key="0.0" value="0"  >0</option>
                        <option key="0.1" value="0.1">0.1</option>
                        <option key="0.2" value="0.2">0.2</option>
                        <option key="0.3" value="0.3">0.3</option>
                        <option key="0.4" value="0.4">0.4</option>
                        <option key="0.5" value="0.5">0.5</option>
                        <option key="0.6" value="0.6">0.6</option>
                        <option key="0.7" value="0.7">0.7</option>
                        <option key="0.8" value="0.8">0.8</option>
                        <option key="0.9" value="0.9">0.9</option>
                        <option key="1.0" value="1"  >1</option>
                     </select>
                  </div>
                  <div className='tokens roleManager roleAngel roleAdmin'>
                     <h3>
                        Tokens
                        <i title="Tokens are akin to the currency in AI. One token resembles roughly one sylable. The sum of tokens (in the prompt, the user input and the response) is limited, depending on the chosen model. It is also what we pay for with our OpenAI account. By entering a number, you can limit the amount of tokens used – which sometimes is helpful but most often not. Leave blank if in doubt.">i</i>
                     </h3>
                     <input type='text' defaultValue={currentTokens} className='chooseTokens' placeholder='Maximum number (optional)' onBlur={(e) => {
                        let chosen = e.target.value;
                        setCurrentTokens(chosen);
                     // console.log(`Entered Tokens: ${chosen}`);
                        let chatLogNew = [ ...chatLog, { user: 'client', message: `Changed Tokens to ${chosen}.` } ];
                        setChatLog(chatLogNew);
                        helper.ui.scroll('chat', 'bottom', null, true);
                     }} />
                  </div>
                  <div className='role roleManager roleAngel roleAdmin'>
                     <h3>
                        Role
                        <i title="This is a shortcut to see the role of the Prompt that is currently loaded. To change it, do so in the 'Save'-dialogue in the Editor.">i</i>
                     </h3>
                     <select className='chooseRole' disabled defaultValue={currentRole} onChange={(e) => {
                        let roleKey = e.target.value;
                        setCurrentRole(roleKey);
                        debug('any', `Chosen Role: ${roleKey}`); 
                     // let name = document.querySelector(`option[value="${roleKey}"]`).innerText;
                     // let chatLogNew = [ ...chatLog, { user: 'client', message: `Changed Role to "${name.trim()}".` } ];
                     // setChatLog(chatLogNew);
                     // helper.ui.scroll('chat', 'bottom', null, true);
                     }}>
                        <option key="user"      value="user"      >User</option>
                        <option key="manager"   value="manager"   >Manager</option>
                        <option key="angel"     value="angel"     >Angel</option>
                        <option key="admin"     value="admin"     >Admin</option>
                     </select>
                  </div>
                  <div className='functions bottom'>
                     <div className='tokenDisplay' data-dev='TBD TOKENDISPLAY'>
                        <h3>
                           Tokens used
                           <i title="This is the amount of tokens you have used until today in this browser (unless you reset your browser's local storage).">i</i>
                        </h3>
                        <input id='tokenCounter' type='text' defaultValue={helper.number.thousand(helper.storage.get('userTokens'))} placeholder='0' />
                     </div> 
                     <div className='helpers'>
                        <h3>
                           Functions
                           <i title="Here you will find various helpers to use in this Client.">i</i>
                        </h3>
                        <select className='chooseHelper' defaultValue='' onChange={(e) => {
                           let chosen = e.target.value;
                           if (chosen === 'toggleSettings') {
                              helper.settings.toggle();
                           }
                           else if (chosen === 'toggleShare') {
                              helper.share.toggle();
                           }
                           else if (chosen === 'toggleTrouble') {
                              helper.trouble.toggle();
                           }
                           else if (chosen === 'openDocumentation') {
                              helper.url.open('blank', config.statista.urlPage);
                           }
                           else if (chosen === 'openPrompts') {
                              helper.url.open('blank', config.statista.urlPrompts);
                           }
                           else if (chosen === 'openPolicy') {
                              helper.url.open('blank', config.policy.url);
                           }
                           else if (chosen === 'openAdUsers') {
                              helper.url.open('blank', config.statista.urlAdUsers);
                           }
                           else if (chosen === 'openAdManagers') {
                              helper.url.open('blank', config.statista.urlAdManagers);
                           }
                           else if (chosen === 'openAdAdmins') {
                              helper.url.open('blank', config.statista.urlAdAdmins);
                           }
                           else if (chosen === 'themeStatista') {
                              let themeKey = 'statista';
                              setCurrentTheme(themeKey);
                              let stored = helper.storage.set('theme', themeKey);
                           }
                           else if (chosen === 'themeChatgpt') {
                              let themeKey = 'chatgpt';
                              setCurrentTheme(themeKey);
                              let stored = helper.storage.set('theme', themeKey);
                           }
                           else if (chosen === 'clientRestart') {
                              window.location.href = window.location.href;
                           }
                           else if (chosen === 'clientPolicy') {
                              document.querySelector('.entrance').classList.add('inside');
                              helper.settings.policy('open');
                           }
                           else if (chosen === 'signIn') {
                              sso.signIn();
                           }
                           else if (chosen === 'signOut') {
                              sso.signOut();
                           }
                           else if (chosen === 'showTokens') {
                              alert(`Until now, you have used ${helper.number.thousand(helper.storage.get('userTokens'))} tokens.`);
                           }
                           else if (chosen === 'openUsage') {
                              helper.url.open('blank', config.statista.urlUsage);
                           }
                           e.target.value = 'reset';
                        }}>
                        <option value='reset'>Choose...</option>
                        <optgroup label='Client'>
                           <option value='signIn'>Sign In</option>
                           <option value='signOut'>Sign Out</option>
                           <option value='toggleSettings' className='toggleSettings'>{currentSettings}</option>
                           <option value='toggleTrouble' className='toggleTrouble'>{currentTrouble}</option>
                           <option value='clientRestart' className='hidden'>Restart Client</option>
                           <option value='clientPolicy'>Our AI Policy</option>
                           <option value='showTokens' className='showTokens'>Tokens used</option>
                        </optgroup>
                        <optgroup label='Theme'>
                           <option value='themeStatista'>Statista</option>
                           <option value='themeChatgpt'>Chat GPT</option>
                        </optgroup>
                        <optgroup label='Links'>
                           <option value='toggleShare' className='toggleShare'>{currentShare}</option>
                           <option value='openPolicy' className='hidden'>Statista AI Policy</option>
                           <option value='openDocumentation'>Documentation</option>
                           <option value='openAdUsers'  class='roleManager'>AD Users</option>
                           <option value='openAdManagers' class='roleAdmin'>AD Managers</option>
                           <option value='openAdAdmins'   class='roleAdmin'>AD Admins</option>
                           <option value='openUsage'      class='roleAngel'>OpenAI Usage</option>
                           <option value='openPrompts'    class='roleAdmin'>Prompts</option>
                        </optgroup>
                        </select>
                     </div>
                  </div>
               </div>
               <div className="assistants">
                  <div className="sidebar-header">
                     <h1>
                        <img src="img/logo_light.png" />
                        Statista Assistants
                     </h1>
                  </div>
                  <div className='aiClient'         value='assistantAiclient_0-1'          onClick={(e) => { feature.chooseAssistant('assistantAiclient_0-1'); }}>
                     <strong>AI Client<span className="green">Live</span></strong>
                     <span>Let me help you use / design prompts in the AI Client</span>
                  </div>
                  <div className='humanResources'   value='assistantHumanResources_1-0'    onClick={(e) => { feature.chooseAssistant('assistantHumanResources_1-0'); }}>
                     <strong>Human Resources<span className="red">Stage</span></strong>
                     <span>Let me answer all questions you might want to ask HR</span>
                  </div>
                  <div className='officeManagement' value='assistantOfficeManagement_1-0'  onClick={(e) => { feature.chooseAssistant('assistantOfficeManagement_1-0'); }}>
                     <strong>Office Management<span className="green">Live</span></strong>
                     <span>Let me assist you on behalf of Office Management</span>
                  </div>
                  <div className='statistaApi'      value='assistantApiSupport_0-5'        onClick={(e) => { feature.chooseAssistant('assistantApiSupport_0-5'); }}>
                     <strong>Statista Content API<span className="green">Live</span></strong>
                     <span>Let us sell and give support for our customer facing API</span>
                  </div>
                  <div className='statistaR roleAngel' value='statistaR_AI_helpdesk_0-1' onClick={(e) => { feature.chooseAssistant('statistaR_AI_helpdesk_0-1'); }}>
                     <strong>Statista R Helpdesk<span className="gray">Dev</span></strong>
                     <span>Let me help you find the right page on our Sharepoint</span>
                  </div>
                  <div className='functions bottom' onClick={(e) => { feature.closeAssistant(); }}>
                     Switch to AI Client
                  </div>
               </div>
            </aside> 
            <section className="chatbox">
               <div className="chat-log">
                  {chatLog.map((message, index) => {
                     return (
                        <ChatMessage key={index} message={message} />
                     );
                  })}
               </div>
               <div className="chat-input-holder">
                  <form onSubmit={feature.handleSubmit}>
                     <div className="growWrap">
                        <textarea 
                           className="chat-input-textarea" 
                           type="text" 
                           placeholder={config.client.placeInput} 
                           rows="1"
                           tabIndex="0"
                           disabled="true"
                           value={input}
                           onChange={(e) => {
                              setInput(e.target.value);
                           }}
                           onLoad={(e) => {
                              helper.ui.uiReady();
                           }}
                           onKeyDown={(e) => {
                              let theKey = e.keyCode;
                              let theInput = e.target.value;
                              let theTextarea = document.querySelector('.chat-input-textarea');
                              let theParent = e.target.parentNode;
                              if (theKey === 38 && theInput === '') { // Arrow up when empty
                                 let last = theTextarea.dataset.last;
                                 if (last) {
                                    setInput(last);
                                    theParent.dataset.replicatedValue = last;
                                 }
                              }
                              if (theKey === 40 && theInput === '') { // Arrow down when empty
                                 let last = theTextarea.dataset.last;
                                 if (last === theInput) {
                                    setInput('');
                                 }
                              }
                              if (theKey === 13 && !e.shiftKey) { // Enter
                                 feature.handleSubmit(e);
                                 theParent.dataset.replicatedValue = " ";
                              }
                           }}
                           onInput={(e) => {
                              let theParent = e.target.parentNode;
                              let theInput = e.target.value;
                              theParent.dataset.replicatedValue = theInput;
                           }}
                           onDoubleClick={(e) => {
                              e.target.disabled = false;
                              e.target.focus();
                           }}
                           title="Press Shift+Enter to insert linebreaks. When text is pasted in, linebreaks are preserved. Press Arrow Up to insert the last input you submitted."
                        ></textarea>
                        <div className="chat-input-submit" onClick={(e) => {
                           let theValue = document.querySelector('.chat-input-textarea').value;
                           let theParent = e.target.parentNode;
                           setInput(theValue);
                           feature.handleSubmit(e, theValue);
                           theParent.dataset.replicatedValue = " ";
                        }}></div>
                     </div>
                  </form>
                  <div className="open" onClick={(e) => {
                     helper.ui.prompteneer(true);
                  }}>^
                     <i title="Click this button to open the prompt design interface. There you can enter text which is sent to the AI once a message is sent. Include the message of the user in your prompt by entering the placeholder {input} somewhere in the text.">i</i>
                  </div>
               </div>
            </section>
            <div className="prompteneer">
               <textarea className="editor" placeholder="Enter your prompt..."></textarea>
               <div className="controls">
                  <div>
                     <button id="new"    disabled>New</button>
                     <i title="Click this button to start a new prompt. This will remove the current prompt as well as it's settings from the prompt design interface and load the template prompt instead.">i</i>
                  </div>
                  <div>
                     <button id="save" onClick={(e) => { helper.ui.promptSaveDialogue(true, e); helper.prompt.loadSaver(currentPrompt); }}>Save</button>
                     <i title="Click this button to save the prompt design entered above. This will open a dialogue which will allow you to edit the settings before saving your prompt.">i</i>
                  </div>
                  <div>
                     <button id="reload" onClick={(e) => { helper.ui.promptReload(currentPrompt, e); }}>Reload</button>
                     <i title="Click this button to discard any changes you made. This will load the last saved version of the prompt.">i</i>
                  </div>
                  <div>
                     <button id="share" onClick={(e) => { helper.ui.promptShare(currentPrompt, e); }}>Share</button>
                     <i title="Click this button to open the sharing-feature for this prompt.">i</i>
                  </div>
                  <div>
                     <button id="cancel" onClick={(e) => { helper.ui.prompteneer(false); }}>Cancel</button>
                     <i title="Click this button to close the prompt design interface. Changes you made will be in effect when entering a message and will persist (unless you choose another prompt or reload the page).">i</i>
                  </div>
                  <div class="">
                     <input id="wordwrap" type="checkbox" onClick={(e) => {
                        let textarea = document.querySelector(`.prompteneer .editor`);
                        textarea.classList.toggle('wordwrap');
                     }}/>
                     <label for="wordwrap" onClick={(e) => { e.target.stopPropagation() }}>Word wrap</label>
                     <i title="Allow word wrap (line breaks in long sentences) in the Editor to see all text at once.">i</i>
                  </div>
               </div>
               <div className="close" onClick={(e) => {
                  helper.ui.prompteneer(false);
               }}>v
                  <i title="Click this button to close the prompt design interface. Changes you made will be in effect when entering a message and will persist (unless you choose another prompt or reload the page).">i</i>
               </div>
               <div className="lists">
                  <ul className="style"></ul>
                  <ul className="statistaList"></ul>
                  <ul className="statistaDetail"></ul>
               </div>
               <div className="promptSaving">
                  <div className="promptSavingDialogue">
                     <form>
                        <div class="promptSavingHeader">
                           Save this Prompt
                           <div onClick={(e) => { helper.ui.promptSaveDialogue(false, e); }}>
                              X
                           </div>
                        </div>
                        <div class="promptSavingForm">
                           <div class="section">Meta</div>
                           <div id="promptId">
                              <h3>
                                 Id
                                 <i title="Enter an id for your prompt. If you edited an existing prompt and want to overwrite it, do not change it. Otherwise note: The id is a unique identifier across all prompt-Groups saved in the system, so please make it very specific (feel free to include your name or the name of your team or department to secure it). Please use lowerCamelCase notation (start with a lowercase letter, include no '-' or '_' or other characters but letters and digits and indicate words with a leading uppercase letter). Consider versioning your prompt by appending a '_' followed by a number for the major version, followed by a '-' followed by a number for the minor version. Example 'yourWonderfulPrompt_0-1'.">i</i>
                              </h3>
                              <input type="text" placeholder="Enter a concise handle" defaultValue={() => {
                                 let saveableId = {
                                    is: null,
                                    current: currentPrompt,
                                    next: currentPrompt+'_0-1'
                                 };
                              // console.log(`defaultValue saveableId: `, saveableId);
                                 return saveableId.next;
                              }} />
                           </div>
                           <div id="promptName">
                              <h3>
                                 Name
                                 <i title="Enter the name of your prompt as you want it to appear in the dropdown. Limit it's length to 25 characters (including spaces), otherwise it will be cropped.">i</i>
                              </h3>
                              <input type="text" placeholder="Enter a pretty handle" defaultValue={() => {
                                 return currentPrompt+'-001';
                              }} />
                           </div>
                           <div id="promptDescription">
                              <h3>
                                 Message
                                 <i title="Enter the message you want the bot to post once your prompt was selected from the dropdown. See this as your call to action to the user using your prompt. Add one or more examples (if their text's is not long, shorten them eventually. A precise message as well as examples greatly improve the usability of your prompt. Linebreaks will be preserved.">i</i>
                              </h3>
                              <textarea placeholder="Enter the message you want the chatbot to send to users who chose your prompt."></textarea>
                           </div>
                           <div class="section">Settings</div>
                           <div id="promptModel">
                              <h3>
                                 Model
                                 <i title="Choose from the models which are available in our OpenAI-account (which are all, currently). Generally recommended models are 'text-davinci-002' (fastest and cheapest but a bit stupid), 'gpt-3.5-turbo' (fast and cheap and quite smart), or 'gtp-4' (slower and expensive but really smart). Or try out the various other models which all have their reason for existence.">i</i>
                              </h3>
                              <select></select>
                           </div>
                           <div id="promptTemperature">
                              <h3>
                                 Temperature
                                 <i title="Choose how creative/loose/hallucinating you want the AI to be. 0 is uncreative and 1 is super-creative. Try to optimize task-oriented prompts for the temperature 0.2, which is quite reliable. Try to optimize your generative prompts for the temperatur 0.8, which is creative but not crazy.">i</i>
                              </h3>
                              <select></select>
                           </div>
                           <div id="promptTokens">
                              <h3>
                                 Tokens
                                 <i title="Enter the maximum (!) amount of tokens (approximates the amount of sylables in your prompt and the AI's answer combined) you want to burn with your prompt. Most models have an upper limit of 3000, 'gpt-4' can cope with up to 5000 tokens. Be aware that tokens are the currency which counts towards our budgets. Usually, 1000 should do.">i</i>
                              </h3>
                              <input value="" />
                           </div>
                           <div class="section api roleAngel roleAdmin">Statista API</div>
                           <div id="promptStatisticId" class="roleAngel roleAdmin">
                              <h3>
                                 Statistic Id
                                 <i title="Insert the id of a Statistic. This will make various {placeholders} available in the prompt, which will be filled upon submit dynamically with data from our API.">i</i>
                              </h3>
                              <input value="" />
                           </div>
                           <div id="promptStatisticQuery" class="roleAngel roleAdmin">
                              <h3>
                                 Statistic Query
                                 <i title="Insert search keywords which will load the data of the top 5 most relevant Statistics from our API and make it available for your prompt with the placeholder {data}.">i</i>
                              </h3>
                              <input value="" />
                           </div>
                           <div id="promptPrecision" class="roleAngel roleAdmin">
                              <h3>
                                 Precision
                                 <i title="Choose a precision in conjunction with the Statistic Query, so that the AI will formulate an answer as precise as you chose.">i</i>
                              </h3>
                              <select></select>
                           </div>
                           <div class="section">Organisation</div>
                           <div id="promptAuthors" class="roleManager roleAngel roleAdmin">
                              <h3>
                                 Authors
                                 <i title="A list of all people who have saved this prompt.">i</i>
                              </h3>
                              <input class="plain" type="text" placeholder={window.user.id} defaultValue={(e) => {
                                 e.target.value = window.user.id;
                              }} />
                           </div>
                           <div id="promptRole" class="roleUser roleManager roleAngel roleAdmin">
                              <h3>
                                 Role
                                 <i title="Choose the minimum role which is allowed to overwrite your prompt. Select 'User' to make your prompt editable for everybody. Select 'Manager' to secure your prompt and only allow managers to edit it.">i</i>
                              </h3>
                              <select>
                                 <option key="user"      value="user"      >User</option>
                                 <option key="manager"   value="manager"   >Manager</option>
                                 <option key="angel" value="angel" class="roleAdmin">Angel</option>
                                 <option key="admin"     value="admin"     >Admin</option>
                              </select>
                           </div>
                           <div id="promptGroup">
                              <h3>
                                 Group
                                 <i title="Choose which Group you want to save your prompt in. You can only save into Groups you can see (according to the current URL). Be aware that the Groups 'Assistants' and 'Tools' is available for everybody. And be aware that as a regular User, one can not save into Groups with the postfix '(live)'; you may want to approach a Manager to put your prompt live.">i</i>
                              </h3>
                              <select disabled><option disabled>Loading...</option></select>
                           </div>
                           <div id="promptHidden" class="roleManager roleAngel roleAdmin">
                              <h3>
                                 Hidden
                                 <i title="Check to remove this prompt from the dropdown. Please be careful: You will not be able to reinstate the Prompt without the help of an Admin. Please let an Admin know whether this Prompt should be deleted alltogether.">i</i>
                              </h3>
                              <div>
                                 <input id="hide" type="checkbox" />
                                 <label for="hide">Remove this Prompt from the dropdown</label>
                              </div>
                           </div>
                           <div class="section">About</div>
                           <div id="promptBy">
                              This prompt was edited by <span>{() => {
                                 return ' "loading authors..';
                              }}</span>. 
                           </div>
                        </div>
                        <div className='promptSavingOverlay'>
                           <table><tr><td align="center" valign="center"><div>
                              <b>Are you sure you want to overwrite this prompt?</b>
                              <br />
                              <i><span className='promptSavingYou'>Loading...</span></i>
                              <br /><br />
                              <span className='promptSavingAuthors'>Current authors are loading...</span>
                              The Prompt was created on <span className='promptSavingCreated'>loading...</span>  and was last updated on <span className='promptSavingUpdated'>loading..</span>.
                              <br /><br />
                              Consider to change the Id of the prompt <br />
                              (for example by counting up a version, like <code>_1-3</code>). <br />
                              Press "Abort" to review your changes. 
                              <span className='promptSavingOff'>Loading...</span>
                           </div></td></tr></table>
                        </div>
                        <div class="promptSavingFooter">
                           <div>
                              <button className='promptSavingConfirm' onClick={(e) => { 
                                 helper.ui.promptSave(e); 
                                 feature.loggedOut('renewCounting');
                              }}>Confirm</button>
                              <i title="Click this button to save your prompt with the settings above. In case you overwrite an existing prompt, this button will change it's text to 'Overwrite' and not save unless you click the button again.">i</i>
                           </div>
                           <div>
                              <button onClick={(e) => { 
                                 let dialogue = document.querySelector('.promptSavingDialogue');
                                 let saveButton = document.querySelector('.promptSavingFooter button:first-child');
                                 if (dialogue.dataset.overwrite === 'true') {
                                    dialogue.dataset.overwrite = 'false';
                                    saveButton.dataset.overwrite = 'false';
                                    saveButton.innerText = 'Confirm';
                                 }
                                 else {
                                    dialogue.dataset.overwrite = 'false';
                                    helper.ui.promptSaveDialogue(false, e); 
                                    saveButton.dataset.overwrite = 'false';
                                    saveButton.innerText = 'Confirm';
                                 }
                                 saveButton.classList.remove('saving');
                                 e.preventDefault();
                              }}>Abort</button>
                              <i title="Click this button to cancel the saving of your prompt. The settings you made will persist until you open this dialogue again (unless you choose another prompt from the dropdown or reload the page).">i</i>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
            <div className="placeholders">
               <div className='placeholdersClose' onClick={(e) => {
                  helper.placeholders.toggle(false);
               }}>X</div>
               <table><tr><td align="center" valign="center"><div>
               {Object.entries(config.placeholders.text).forEach(([key, value], index) => {
               // console.log(`index: ${index}, key: ${key}, value: ${value}`);
                  return (
                     <Placeholder key={index} entry={{ key: key, value: value }} />
                  );
               })}
               </div></td></tr></table>
            </div>
            <div className="share" onLoad={(e) => { helper.share.update(e); }}>
               <div className='shareClose' onClick={(e) => {
                  helper.share.toggle(false);
               }}>X</div>
               <table><tr><td align="center" valign="center"><div>
                  <h1>Share</h1>
                  <div className='sharePrompt'>
                     <h3>
                        Choose Prompt
                        <i title='Enter the id of the Prompt you want to share. By default, it is the id of the Prompt you last used. Use the dropdown at the right to pick another Prompt.'>i</i>
                     </h3>
                     <ul>
                        <li><select></select></li>
                        <li><input type='text' id='prompt' onKeyUp={(e) => { helper.share.update(e); }} placeholder={currentPrompt} value={currentPrompt} /></li>
                     </ul>
                  </div>
                  <div className='shareAppearance' data-radios='view'>
                     <h3>
                        Choose View
                        <i title='Choose how the AI Client shall be displayed when shared – current options are "Client" (the usual design) or "Widget" (a minimal UI focussed on just using the Prompt). Managers and higher roles can select certain pages to be displayed upon load.'>i</i>
                     </h3>
                     <ul>
                       <li                                            ><input type='radio' id='client'     name='view' onChange={(e) => { helper.share.update(e); }} /> <label for='client'    > Client    </label></li>
                       <li                                            ><input type='radio' id='widget'     name='view' onChange={(e) => { helper.share.update(e); }} /> <label for='widget'    > Widget    </label></li>
                       <li                                            ><input type='radio' id='assistants' name='view' onChange={(e) => { helper.share.update(e); }} /> <label for='assistants'> Assistants</label></li>
                       <li className='roleManager roleAngel roleAdmin'><input type='radio' id='settings'   name='view' onChange={(e) => { helper.share.update(e); }} /> <label for='settings'  > Settings  </label></li>
                       <li className='roleManager roleAngel roleAdmin'><input type='radio' id='trouble'    name='view' onChange={(e) => { helper.share.update(e); }} /> <label for='trouble'   > Trouble   </label></li>
                       <li className='roleManager roleAngel roleAdmin'><input type='radio' id='share'      name='view' onChange={(e) => { helper.share.update(e); }} /> <label for='share'     > Share     </label></li>
                       <li                                            ><input type='radio' id='editor'     name='view' onChange={(e) => { helper.share.update(e); }} /> <label for='editor'    > Editor    </label></li>
                       <li                                            ><input type='radio' id='none1'      name='view' onChange={(e) => { helper.share.update(e); }} /> <label for='none1'     > none      </label></li>
                     </ul>
                  </div>
                  <div className='shareSignin roleManager roleAngel roleAdmin'>
                     <h3>
                        Change Signin Texts
                        <i title='You can adjust the texts of the login-screen. This can help users who will see the "Widget" view.'>i</i>
                     </h3>
                     <ul>
                       <li><input type='text' id='title'  onKeyUp={(e) => { helper.share.update(e); }} placeholder={config.signin.title}  /></li>
                       <li><input type='text' id='text'   onKeyUp={(e) => { helper.share.update(e); }} placeholder={config.signin.text}   /></li>
                       <li><input type='text' id='button' onKeyUp={(e) => { helper.share.update(e); }} placeholder={config.signin.button} /></li>
                     </ul>
                  </div>
                  <div className='shareTheme' data-radios='theme'>
                     <h3>
                        Choose Theme
                        <i title='You can select the light theme (Statista) or the dark theme (ChatGPT). Choosing no option will make use of the current setting of the user (or Statista by default).'>i</i>
                     </h3>
                     <ul>
                       <li><input type='radio' id='statista' name='theme' onChange={(e) => { helper.share.update(e); }} /> <label for='statista'> Light </label></li>
                       <li><input type='radio' id='chatgpt'  name='theme' onChange={(e) => { helper.share.update(e); }} /> <label for='chatgpt' > Dark  </label></li>
                       <li><input type='radio' id='none2'    name='theme' onChange={(e) => { helper.share.update(e); }} /> <label for='none2'   > none  </label></li>
                     </ul>
                  </div>
                  <div className='shareWelcome'>
                     <h3>
                        Enter welcome message
                        <i title='You can change the message the AI Client will send first after loading. This can improve the user experience depending on your use case.'>i</i>
                     </h3>
                     <ul>
                       <li><input type='text' id='welcome' onKeyUp={(e) => { helper.share.update(e); }} placeholder={config.client.start} /></li>
                     </ul>
                  </div>
                  <div className='shareMessage' className='roleAdmin'>
                     <h3>
                        Enter automatic Message
                        <i title='You can send a message on the users behalf. The message will be send as soon as the initial Prompt has loaded. This allows you to guide your users.'>i</i>
                     </h3>
                     <ul>
                     <li><input type='text' id='message' onKeyUp={(e) => { helper.share.update(e); }} placeholder={config.client.message !== null ? config.client.message : ''} /></li>
                     </ul>
                  </div>
                  <div className='shareRole' data-radios='role' className='roleManager roleAngel roleAdmin'>
                     <h3>
                        Add User Role
                        <i title='You can add a role for the users that open your shared url. The role will be permanently available to them, so watch out not to grant a role too high by mistake. Handle with care!'>i</i>
                     </h3>
                     <ul>
                       <li className='roleManager roleAngel roleAdmin'><input type='radio' id='user'    name='role' onChange={(e) => { helper.share.update(e); }} /> <label for='user'   > User    </label></li>
                       <li className=            'roleAngel roleAdmin'><input type='radio' id='manager' name='role' onChange={(e) => { helper.share.update(e); }} /> <label for='manager'> Manager </label></li>
                       <li className=                      'roleAdmin'><input type='radio' id='angel'   name='role' onChange={(e) => { helper.share.update(e); }} /> <label for='angel'  > Angel   </label></li>
                       <li className=                      'roleAdmin'><input type='radio' id='admin'   name='role' onChange={(e) => { helper.share.update(e); }} /> <label for='admin'  > Admin   </label></li>
                       <li className=                               ''><input type='radio' id='none3'   name='role' onChange={(e) => { helper.share.update(e); }} /> <label for='none3'  > none    </label></li>
                     </ul>
                  </div>
                  <div className='shareEmbed' data-radios='embed'>
                     <h3>
                        Embed Code
                        <i title='You can choose to embed the url in code, so that you can add it to other systems easily.'>i</i>
                     </h3>
                     <ul>
                       <li><input type='radio' id='linkHtml'     name='embed' onChange={(e) => { helper.share.update(e); }} /> <label for='linkHtml'     > Link (HTML)         </label></li>
                       <li><input type='radio' id='linkMarkdown' name='embed' onChange={(e) => { helper.share.update(e); }} /> <label for='linkMarkdown' > Link (Markdown)     </label></li>
                       <li><input type='radio' id='iframe'       name='embed' onChange={(e) => { helper.share.update(e); }} /> <label for='iframe'       > Iframe (Web)        </label></li>
                       <li><input type='radio' id='sharepoint'   name='embed' onChange={(e) => { helper.share.update(e); }} /> <label for='sharepoint'   > Iframe (Sharepoint) </label></li>
                       <li><input type='radio' id='none4'        name='embed' onChange={(e) => { helper.share.update(e); }} /> <label for='none4'        > none                </label></li>
                     </ul>
                  </div>
                  <div className='shareCode'>
                     <h3>
                        Share this <span>URL</span>
                        <i title='This is the URL or code you can share, with all parameters above reflected.'>i</i>
                     </h3>
                     <ul>
                        <li><textarea width='100%' height='200' onLoad={(e) => {  }}>Loading...</textarea></li>
                     </ul>
                  </div>
               </div></td></tr></table>
            </div>
            <div className="trouble">
               <div className='troubleClose' onClick={(e) => {
                  helper.trouble.toggle(false);
               }}>X</div>
               <table><tr><td align="center" valign="center"><div>
                  <h1>Trouble</h1>
                  <div className='troublePage'>
                     <h3>
                        Read the docs
                        <i title='This button will open the Sharepoint Page which contains various helpful information.'>i</i>
                     </h3>
                     <p>
                        Learn more on this Sharepoint page:
                     </p>
                     <button onClick={(e) => {

                        window.open(config.statista.urlPage, '_blank');

                     }} >Open Sharepoint</button>
                  </div>
                  <div className='troubleRestart'>
                     <h3>
                        Restart Client
                        <i title='This button will reload the Client, which helps in case of minor issues.'>i</i>
                     </h3>
                     <p>
                        Reload the Client (Chat will be lost):
                     </p>
                     <button onClick={(e) => {

                        window.location.reload();

                     }} >Restart Client</button>
                  </div>
                  <div className='troubleReset'>
                     <h3>
                        Reset Client
                        <i title='This button will delete some of the locally stored data and reload the Client, then redirect you to the Settings.'>i</i>
                     </h3>
                     <p>
                        Clear data saved in your browser:
                     </p>
                     <button onClick={(e) => {

                        document.querySelector(`#troubleReally`).style.display = 'inline-block';
                        e.target.style.display = 'none';

                     }} >Reset Client</button>
                     <button id='troubleReally' className='hidden' onClick={(e) => {

                        helper.storage.delete('userId');
                        helper.storage.delete('groupsCurrent');
                        helper.storage.delete('userRole'); 
                        window.location.href = `?view=settings${typeof currentPrompt === 'string' && currentPrompt !== '' ? '#'+currentPrompt : ''}`;

                     }} >Really?</button>
                  </div>
                  <div className='troubleSignout'>
                     <h3>
                        Sign out
                        <i title='This button will sign you out of the Client so that you have to sign in again – which will update eventually wrong data from SSO.'>i</i>
                     </h3>
                     <p>
                        Restart the authentication:
                     </p>
                     <button onClick={(e) => {

                        sso.signOut();

                     }} >Log out</button>
                  </div>
                  <div className='troubleForm'>
                     <h3>
                        Report a bug
                        <i title='This button will open the Support-Form where you can get help from a real human being.'>i</i>
                     </h3>
                     <p>
                        Fill out this short form:
                     </p>
                     <button onClick={(e) => {

                        window.open(config.statista.urlForm, '_blank');

                     }} >Open Form</button>
                  </div>
               </div></td></tr></table>
            </div>
            <div className="settings">
               <div className='settingsClose' onClick={(e) => {
                  helper.settings.toggle(false);
               }}>X</div>
               <table><tr><td align="center" valign="center"><div>
                  <h1>Settings</h1>
                  <div className='settingsUser'>
                     <h3>
                        Your Login
                        <i title="This is information about you provided to the Client by Microsoft SSO. You can not change it, but it is useful for orientation and debugging.">i</i>
                     </h3>
                     <p class="hidden">
                        This is your user id:
                     </p>
                     <input className="userEmail hidden" type='text' placeholder={config.client.userDefault} defaultValue={`${currentUser === config.client.userDefault ? '' : currentUser }`} onChange={(e) => {
                     // console.log(`Target element: `, e);
                        let userId = e.target.value;
                        if (typeof userId === 'string') {
                           let valid = /[a-zA-Z-]*[\.]?[a-zA-Z-]*\@statista.com/.test(userId);
                        // console.log(`valid: `, valid);
                           if (valid) {
                              userId = userId === '' ? config.client.userDefault : userId;
                              helper.settings.user('set', userId);
                              e.target.classList.remove('invalid');
                           }
                           else {
                              e.target.classList.add('invalid');
                           }
                        }
                     }} />
                     <p>
                        These are your credentials:
                     </p>
                     <ul className="userProfile">
                        <li class='name'     ><label>Name:       </label> <span class="userName"      >Loading...</span></li>
                        <li class='email'    ><label>Email:      </label> <span class="userEmail"     >Loading...</span></li>
                        <li class='city'     ><label>City:       </label> <span class="userCity"      >Loading...</span></li>
                        <li class='dept'     ><label>Department: </label> <span class="userDepartment">Loading...</span></li>
                        <li class='level'    ><label>Level:      </label> <span class="userLevel"     >Loading...</span></li>
                     </ul>
                  </div>
                  <div className='settingsRole'>
                     <h3>
                        Your Role
                        <i title="These are the Roles available to you in this Client. They depend on whether you have been added to the 'Managers'- or 'Admin'-Group in our Active Directory, otherwise you are a 'User'. You can downgrade your Role and upgrade it at anytime.">i</i>
                     </h3>
                     <p>
                        Choose your role in this Client:
                     </p>
                     <select defaultValue={currentRole} onChange={(e) => {
                        let userRole = e.target.value;
                        userRole = typeof userRole === 'string' && userRole !== '' ? userRole : config.client.roleDefault;
                        setCurrentRole(userRole);
                        helper.storage.set('userRole', userRole);
                     // helper.url.state('replace', 'role', userRole);
                        if (userRole === 'user') { helper.storage.set('groupsCurrent', helper.storage.get('groupsCurrent').replace(/([,]*)tests([,]*)/g, '$1_tests$2')); }
                        if (userRole !== 'user') { helper.storage.set('groupsCurrent', helper.storage.get('groupsCurrent').replace(/([,]*)_tests([,]*)/g, '$1tests$2')); }
                     }}>
                        <option key="user"      value="user"      class="highestUser highestManager highestAngel highestAdmin">User</option>
                        <option key="manager"   value="manager"   class="            highestManager highestAngel highestAdmin">Manager</option>
                        <option key="angel"     value="angel"     class="                           highestAngel highestAdmin">Angel</option>
                        <option key="admin"     value="admin"     class="                                        highestAdmin">Admin</option>
                     </select>
                  </div>
                  <div className='settingsGroups'>
                     <h3>
                        Your Groups
                        <i title="These are the Prompt-Groups you can have access to in the Prompt-Dropdown. Changes are saved automatically. Reload the Client to reload the Prompts-Dropdown. Switch them on and off at will to reduce the loading time.">i</i>
                     </h3>
                     <p>
                        Choose which Prompt-Groups to see:
                     </p>
                     <div className='settingsAll roleAngel roleAdmin'>
                        <h4>
                           Override
                           <i title="As an Angel, you can switch on all Prompt-Groups at once, overriding the individual settings.">i</i>
                        </h4>
                        <ul>
                        <li><input type='checkbox' id='all' onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='all' >Load all</label></li>
                        </ul>
                     </div>
                     <div className='settingsFunctions'>
                        <h4>
                           Functions
                           <i title="These are the functional Prompt-Groups that are available to you in the Prompt-Dropdown.">i</i>
                        </h4>
                        <ul>
                           <li                                            ><input type='checkbox' id='default'    onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='default'                                     class='dflt'>Default</label></li>
                           <li className='roleManager roleAngel roleAdmin'><input type='checkbox' id='tests'      onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='tests'                                       class='dflt'>Tests</label></li>
                           <li                                            ><input type='checkbox' id='assistants' onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='assistants'                                  class='dflt'>Assistants</label></li>
                           <li className=            'roleAngel roleAdmin'><input type='checkbox' id='notes'      onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='notes'                                       class='dflt'>Notes</label></li>
                           <li                                            ><input type='checkbox' id='tools'      onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='tools'                                       class='dflt'>Tools</label></li>
                           <li                                            ><input type='checkbox' id='templates'  onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='templates'                                   class='dflt'>Templates</label></li>
                           <li>                                            <input type='checkbox' id='su'         onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='su'                                          class='dflt'>Statista University</label></li>
                        </ul>
                     </div>
                     <div className='settingsDepartments'>
                        <h4>
                           Departments
                           <i title="These are the department-related Prompt-Groups that are available to you in the Prompt-Dropdown. The departments you have available depend on the Department in your Personio-profile. Departments are added as you open shared Prompts from other Departments or open certain administrative links.">i</i>
                        </h4>
                        <ul>
                           <li><input type='checkbox' id='ask'                                         onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='ask'                                         class='dept'>Ask Statista</label></li>
                           <li><input type='checkbox' id='cd'                                          onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='cd'                                          class='dept'>Content & Design</label></li>
                           <li><input type='checkbox' id='cs'                                          onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='cs'                                          class='dept'>Corporate Services</label></li>
                           <li><input type='checkbox' id='eri'                                         onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='eri'                                         class='dept'>ERI</label></li>
                           <li><input type='checkbox' id='csHr'                                        onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='csHr'                                        class='dept'>HR</label></li>
                           <li><input type='checkbox' id='pitIt'                                       onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='pitIt'                                       class='dept'>IT</label></li>
                           <li><input type='checkbox' id='nxt'                                         onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='nxt'                                         class='dept'>nxt Statista</label></li>
                           <li><input type='checkbox' id='csOm'                                        onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='csOm'                                        class='dept'>Office Management</label></li>
                           <li><input type='checkbox' id='pit'                                         onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='pit'                                         class='dept'>PIT</label></li>
                           <li><input type='checkbox' id='pitPi'                                       onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='pitPi'                                       class='dept'>Product Intelligence</label></li>
                           <li><input type='checkbox' id='pitPs'                                       onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='pitPs'                                       class='dept'>Product Services</label></li>
                           <li><input type='checkbox' id='sales'                                       onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='sales'                                       class='dept'>Sales/CSM</label></li>
                           <li><input type='checkbox' id='smi'                                         onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='smi'                                         class='dept'>SMI</label></li>
                           <li><input type='checkbox' id='smiCompany'                                  onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='smiCompany'                                  class='dept'>SMI Company Insights</label></li>
                           <li><input type='checkbox' id='smiConsumer'                                 onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='smiConsumer'                                 class='dept'>SMI Consumer Insights</label></li>
                           <li><input type='checkbox' id='smiMarket'                                   onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='smiMarket'                                   class='dept'>SMI Market Insights</label></li>
                           <li><input type='checkbox' id='smedia'                                      onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='smedia'                                      class='dept'>Statista Media</label></li>
                           <li><input type='checkbox' id='sq'                                          onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='sq'                                          class='dept'>Statista Q</label></li>
                           <li><input type='checkbox' id='sr'                                          onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='sr'                                          class='dept'>Statista R</label></li>
                           <li><input type='checkbox' id='csSu'                                        onChange={(e) => { helper.settings.groups('set', e); }} /> <label for='csSu'                                        class='dept'>Statista University</label></li>
                        </ul>
                     </div>
                     <div className='settingsPeople'>
                        <h4>
                           People
                           <i title="These are the Prompt-Groups of people whose Prompts you have been shared.">i</i>
                        </h4>
                        <ul>
                           <li class='roleAngel roleAdmin'><input type='checkbox' id={helper.storage.toPersonal(window.user.id)} onChange={(e) => { helper.settings.groups('set', e); }} /> <label for={helper.storage.toPersonal(window.user.id)} class='dflt'>{window.user.firstName} {window.user.lastName}</label></li>

                           {(() => {

                              window.setTimeout(function() {

                                 let userId =          helper.storage.toPersonal(window.user.id);
                                 let groupsAvailable = helper.storage.get('groupsAvailable').split(',');
                                 let groupsCurrent =   helper.storage.get('groupsCurrent'  ).split(',');
                                 let groupsStatic =    [userId, ...config.statista.groupsStatic];

                                 let chapter = document.querySelector(`.settingsPeople`);
                                 let ul = chapter.querySelector(`ul`);
                              // console.log(`  - Ul:       `, ul);
                              // console.log(`  - Current:  `, groupsCurrent);

                                 groupsAvailable.map((groupId) => {

                                    if (userId !== undefined && typeof groupId === 'string' && groupId !== '' && groupId !== userId 
                                    && groupId.toLowerCase().trim() !== window.user.firstName.toLowerCase().trim()) {

                                       let liExists = function() {
                                          let result = document.querySelector(`.settingsPeople #${groupId}`);
                                          if (result !== null) { result = true; } else { result = false; }
                                          return result;
                                       }();

                                       let isActive = groupsCurrent.includes(groupId);

                                       let isComplete = function() {
                                          let result = true;
                                          let xname = groupId.split(/(?=[A-Z])/);
                                          let fname = xname[0];
                                          let lname = xname[1];
                                          if (!lname) { 
                                             result = false; 
                                             let old = helper.storage.get('groupsAvailable');
                                             let str = `/([,]*)${fname}([,]*)/`;
                                             let reg = new RegExp(str, 'g');
                                             let nev = old.replace(reg, '$1$2');
                                             nev = nev.replace(',,', ',');
                                             helper.storage.set('groupsAvailable', nev);
                                          }
                                          return result;
                                       }(); 
                                    // console.log(`isComplete: ${isComplete}`);

                                       if (!liExists && isComplete) {
                                          
                                          let groupName = groupId;
                                          groupName = groupName.replace(/([A-Z])/g, " $1");
                                          groupName = groupName.charAt(0).toUpperCase() + groupName.slice(1);

                                       // chapter.classList.add('visible'); 
                                       // console.log('- Visible Personal Groups');
                                          
                                          if (typeof groupId === 'string' && groupId !== '') {

                                             if (!groupsStatic.includes(groupId)) { 

                                             // console.log(`- ${groupId}`);
                                             // console.log(`  - Id:        ${groupId}`);
                                             // console.log(`  - Exists:   `, liExists);
                                             // console.log(`  - Is active:`, isActive);
                                             // console.log(`  - Name:      ${groupName}`);
                                             // console.log(`  - User:      ${userId}`);
            
                                                let input = document.createElement("input");
                                                input.type = 'checkbox';
                                                input.id = groupId;
                                                input.checked = isActive;
                                                input.addEventListener('click', function(e) { helper.settings.groups('set', e); });

                                                let label = document.createElement("label");
                                                label.innerHTML = `&nbsp;${groupName}`;
                                                label.classList.add(`dflt`);
                                                label.for = groupId;
                                                input.addEventListener('click', function(e) { e.stopPropagation(); });
                                                
                                                let li = document.createElement("li");
                                                li.appendChild(input);
                                                li.appendChild(label);
                                             // console.log(`  - Li:       `, li);

                                                ul.appendChild(li);
                                                ul.dataset.hasGroup = true;

                                             // li = <li class=''><input type='checkbox' id={groupId} onChange={(e) => { helper.settings.groups('set', e); }} checked={isActive} /> 
                                             //    &nbsp;<label for={groupId} class='dflt'> {groupName} </label> 
                                             // </li>
                                                
                                             }

                                          }

                                       }
                                       
                                    }

                                 });

                                 window.setTimeout(function() {

                                    if (ul.dataset.finished !== 'true') {

                                    // ul.dataset.finished = 'true';

                                       let peoples = document.querySelectorAll(`.settingsPeople ul li`);
                                       let isVisible = false;
                                       peoples.forEach(function(people) {
                                          isVisible = people.offsetParent !== null;
                                       // console.log(`offsetParent: ${people.offsetParent}`);
                                       });
                                    // console.log(`isVisible: `, isVisible);
                                       let people = document.querySelector(`.settingsPeople .noPeople`);
                                       if (people) { people.remove(); }
                                       if (!isVisible) { 
                                          
                                          let input = document.createElement("input");
                                          input.type = 'checkbox';
                                          input.id = 'noPeopleId';
                                          input.checked = false;
                                          input.disabled = true;
                                          input.style.cursor = 'default';
         
                                          let label = document.createElement("label");
                                          label.innerHTML = `&nbsp;You have no People yet.`;
                                          label.for = 'noPeopleId';
                                          label.style.fontStyle = 'italic';
                                          label.style.cursor = 'default';
                                          
                                          let li = document.createElement("li");
                                          li.classList.add('noPeople');
                                          li.classList.add('noAdmin');
                                          li.style.display = 'block';
                                          li.style.cursor = 'default';
                                          li.appendChild(input);
                                          li.appendChild(label);
         
                                          ul.appendChild(li);
         
                                       }
         
                                    }

                                 }, 10);

                              }, 10);

                           })()}

                        </ul>
                     </div>
                  </div>
                  <div className='settingsToken' data-dev='TBD TOKENDISPLAY'>
                     <h3>
                        Tokens used
                        <i title="You can choose to permanently display the Tokens you used until now in the Sidebar. If not activated, you can access the Tokens you used in the 'Functions'-Dropdown.">i</i>
                     </h3>
                     <p>
                        Display "Tokens used" in the Sidebar:
                     </p>
                     <ul>
                        <li ><input type='checkbox' id='displayTokens' defaultValue={currentToken} onChange={(e) => { 
                           debug('any', ` \nToken Display`);
                           debug('any', `- Chosen: `, e.target.checked);
                           helper.settings.token(e.target.checked); 
                           debug('any', `- Saved`);
                        }} /> 
                        <label for='displayTokens' onClick={(e) => { e.stopPropagation(); }}>Always</label></li>
                     </ul>
                  </div>
               </div></td></tr></table>
            </div>
            <div className="signIn signedOut notready">
               <table><tr><td align="center" valign="center"><div>
                  <h1>
                     <img src="img/logo_light.png" />
                     <span id="signTitle">Statista AI Client</span>
                  </h1>
                  <div className="">
                     <p id="signText">
                        Log in with your Statista credentials:
                     </p>
                     <p>
                        <button id="signIn" className='' onClick={(e) => {
                           sso.signIn();
                        }}>Log In</button>
                     </p>
                  </div>
               </div></td></tr></table>
            </div>
            <div className="signIn signedIn">
               <table><tr><td align="center" valign="center"><div>
                  <h1>Statista AI Client</h1>
                  <p>
                     You are logged in as:
                  </p>
                  <p>
                     <span class="userName"></span><br />
                     <span class="userEmail"></span><br />
                     <span class="userCity"></span><br />
                     <span class="userDepartment"></span><br />
                     <span class="userLevel"></span>
                  </p>
               </div></td></tr></table>
            </div>
            <div className="entrance policyConfirm">
               <table><tr><td align="center" valign="center"><div>
                  <h1>Statista AI Client</h1>
                  <p>
                     Here we design, test, share and use our prompts.
                  </p>
                  <p>
                     Before you proceed, please read our latest
                     <a id="policyLink" href={`${policyUrl}`} data-version={`${policyVersion}`} target='blank'>AI Policy</a>
                     &nbsp;(version {helper.settings.policy('print')}). 
                  </p>
                  <p>
                     Please be reminded that you shall not provide Statista-owned data to this client. 
                     Also, keep in mind that the AIs are somewhat unreliable, so always review their results before using them. 
                     Last but not least: {`${config.client.allowance}`}
                  </p>
                  <p>
                     <input type='checkbox' id="policyConfirmed" defaultChecked={policyConfirmed} data-confirmed={policyConfirmed} onClick={(e) => {
                        helper.settings.policy(e.target.checked);
                     }}/>
                     <label for="policyConfirmed">I know what I am doing</label>
                  </p>
                  <p>
                     <button id="policyStart" className='disabled' onClick={(e) => {
                        helper.settings.policy('close');
                     }}>Open Client</button>
                  </p>
               </div></td></tr></table>
               <div className="regulations">
                  <b>Powered by OpenAI</b>
                  <i>This Client is not affiliated, endorsed, or sponsored by OpenAI.</i>
               </div>
            </div>
            <div className="loggedOut">
               <table><tr><td align="center" valign="center"><div>
                  <h1>
                     <img src="img/logo_light.png" />
                     <span>Statista AI Client</span>
                  </h1>
                  <p>
                     You have been inactive for too long.<br />
                     Please reload the AI Client:
                  </p>
                  <p>
                     <button id="logOut" className='' onClick={(e) => {
                        window.location.reload();
                     }}>Reload AI Client</button>
                  </p>
                  <p>
                     <em>
                        You might want to <a onClick={(e) => {
                           feature.loggedOut('close');
                        }}>close this notification</a><br />
                        before reloading to retrieve unsaved information.
                     </em>
                  </p>
               </div></td></tr></table>
               <div className='loggedOutClose' onClick={(e) => {
                  feature.loggedOut('close');
               }}>X</div>
               <div className='loggedOutScreen'></div>
            </div>
         </div>
      );
   }

// Placeholders
   const Placeholder = ({ entry }) => {

      console.log(`entry: `, entry);

      return (
         <div className='placeholder'>
            Hallo.
         </div>
      );

   };

// Message
   const ChatMessage = ({ message }) => {

      const helper = {
         string: {
            markdown: function(src) {
               var rx_lt = /</g;
               var rx_gt = />/g;
               var rx_space = /\t|\r|\uf8ff/g;
               var rx_escape = /\\([\\\|`*_{}\[\]()#+\-~])/g;
               var rx_hr = /^([*\-=_] *){3,}$/gm;
               var rx_blockquote = /\n *&gt; *([^]*?)(?=(\n|$){2})/g;
               var rx_list = /\n( *)(?:[*\-+]|((\d+)|([a-z])|[A-Z])[.)]) +([^]*?)(?=(\n|$){2})/g;
               var rx_listjoin = /<\/(ol|ul)>\n\n<\1>/g;
               var rx_highlight = /(^|[^A-Za-z\d\\])(([*_])|(~)|(\^)|(--)|(\+\+)|`)(\2?)([^<]*?)\2\8(?!\2)(?=\W|_|$)/g;
               var rx_code = /\n((```|~~~).*\n?([^]*?)\n?\2|((    .*?\n)+))/g;
               var rx_link = /((!?)\[(.*?)\]\((.*?)( ".*")?\)|\\([\\`*_{}\[\]()#+\-.!~]))/g;
               var rx_table = /\n(( *\|.*?\| *\n)+)/g;
               var rx_thead = /^.*\n( *\|( *\:?-+\:?-+\:? *\|)* *\n|)/;
               var rx_row = /.*\n/g;
               var rx_cell = /\||(.*?[^\\])\|/g;
               var rx_heading = /(?=^|>|\n)([>\s]*?)(#{1,6}) (.*?)( #*)? *(?=\n|$)/g;
               var rx_para = /(?=^|>|\n)\s*\n+([^<]+?)\n+\s*(?=\n|<|$)/g;
               var rx_stash = /-\d+\uf8ff/g;
               function replace(rex, fn) {
                  src = src.replace(rex, fn);
               }
               function element(tag, content) {
                  return '<' + tag + '>' + content + '</' + tag + '>';
               }
               function blockquote(src) {
                  return src.replace(rx_blockquote, function(all, content) {
                        return element('blockquote', blockquote(highlight(content.replace(/^ *&gt; */gm, ''))));
                  });
               }
               function list(src) {
                  return src.replace(rx_list, function(all, ind, ol, num, low, content) {
                        var entry = element('li', highlight(content.split(
                           RegExp('\n ?' + ind + '(?:(?:\\d+|[a-zA-Z])[.)]|[*\\-+]) +', 'g')).map(list).join('</li><li>')));
                        return '\n' + (ol ?
                           '<ol start="' + (num ?
                              ol + '">' :
                              parseInt(ol, 36) - 9 + '" style="list-style-type:' + (low ? 'low' : 'upp') + 'er-alpha">') + entry + '</ol>' :
                           element('ul', entry));
                  });
               }
               function highlight(src) {
                  return src.replace(rx_highlight, function(all, _, p1, emp, sub, sup, small, big, p2, content) {
                        return _ + element(
                           emp ? (p2 ? 'strong' : 'em') :
                           sub ? (p2 ? 's' : 'sub') :
                           sup ? 'sup' :
                           small ? 'small' :
                           big ? 'big' :
                           'code',
                           highlight(content));
                  });
               }
               function unesc(str) {
                  return str.replace(rx_escape, '$1');
               }
               var stash = [];
               var si = 0;
               src = '\n' + src + '\n';
               replace(rx_lt, '&lt;');
               replace(rx_gt, '&gt;');
               replace(rx_space, '  ');
            // blockquote
               src = blockquote(src);
            // horizontal rule
               replace(rx_hr, '<hr/>');
            // list
               src = list(src);
               replace(rx_listjoin, '');
            // code
               replace(rx_code, function(all, p1, p2, p3, p4) {
                  p4 = typeof p4 === 'undefined' ? '' : p4;
                  stash[--si] = element('pre', element('code', p3 || p4.replace(/^    /gm, '')));
                  return si + '\uf8ff';
               });
            // link or image
               replace(rx_link, function(all, p1, p2, p3, p4, p5, p6) {
                  let target = "_self";
                  let title = p3;
                  if (p4.indexOf(window.location.origin) !== 0) { 
                     target = '_blank'; 
                     title = 'Open '+p3+' in a new tab';
                  }
                  stash[--si] = p4 ?
                        p2 ?
                        '<img src="'+p4.replace(/\(/g, '%28').replace(/\)/g, '%29')+'" alt="'+p3 + '"/>' :
                        '<a href="' +p4.replace(/\(/g, '%28').replace(/\)/g, '%29')+'" target="'+target+'" title="'+title+'">' + unesc(highlight(p3)) + '</a>' :
                        p6;
                  return si + '\uf8ff';
               });
            // table
               replace(rx_table, function(all, table) {
                  var sep = table.match(rx_thead)[1];
                  return '\n' + element('table',
                        table.replace(rx_row, function(row, ri) {
                           return row == sep ? '' : element('tr', row.replace(rx_cell, function(all, cell, ci) {
                              return ci ? element(sep && !ri ? 'th' : 'td', unesc(highlight(cell || ''))) : ''
                           }))
                        })
                  )
               });
            // heading
               replace(rx_heading, function(all, _, p1, p2) {
                  return _ + element('h' + p1.length, unesc(highlight(p2)))
               });
            // paragraph
               replace(rx_para, function(all, content) {
                  return element('p', unesc(highlight(content)))
               });
            // stash
               replace(rx_stash, function(all) {
                  return stash[parseInt(all)]
               });
               src = src.trim();
               return src;
            },
            shorten: function(string, length, ellipsis){
               if (typeof string !== 'string') { return ''; }
               if (!length) { return string; }
               if (length === 'default' || length === null) { length = 50; }
               string = string.trim();
               string = string.replace(/\n/g, ' ');
               string = string.replace(/\n/g, ' ');
               string = string.replace(/\n/g, ' ');
               string = string.replace(/  /g, ' ');
               string = string.replace(/  /g, ' ');
               string = string.replace(/  /g, ' ');
               let postfix = '';
               if (ellipsis) {
                  if ((length - 3) < string.length) {
                     postfix = '...';
                     length = length - 3;
                  }
               }
               string = string.substring(0, length);
               string += postfix;
               return string;
            },
            timestamp: function() {
               const dt = new Date();
               const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
               return `${
               dt.getFullYear()}-${
               padL(dt.getMonth()+1)}-${
               padL(dt.getDate())}_${
               padL(dt.getHours())}-${
               padL(dt.getMinutes())}-${
               padL(dt.getSeconds())}`;
            },
         },
         feedback: {
            id: function() {
               let result = 'f'+Math.floor(Math.random()*999999+111111);
               return result;
            },
         },
      };

      let user =     message.user === 'gpt' ? 'bot' : message.user === 'client' ? 'client' : 'human';
      let text =     message.message;
      let type =     message.type === 'response' ? 'response' : message.type === 'init' ? 'init' : '';
      let feedback = message.feedback === false ? false : true;
      let waiting =  message.waiting;
      let prompt =   typeof message.prompt   === 'string' ? message.prompt   : null;
      let author =   typeof message.author   === 'string' ? message.author   : null;
      let group =    typeof message.group    === 'string' ? message.group    : null;
      let request =  typeof message.request  === 'string' ? message.request  : null;
      let response = typeof message.response === 'string' ? message.response : null;
      let log =      typeof message.log      === 'string' ? message.log      : null;

      text = text.trim();
      text = text.replace(/\n\n\n/g, '\n\n');
      text = text.replace(/\n\n\n/g, '\n\n');
      text = text.replace(/\n\n\n/g, '\n\n');

      if (text.match(/^[a-zA-Z0-9 ]*?\;http/)) {
         let arr = text.split(`;http`);
         let instruction = arr[0];
         let url = `http${arr[1]}`;
         text = `[${instruction}](${url})`;
      }
      
      const html = function() {
         let result = helper.string.markdown(text); 
         result = result.replace(/\<li\>/gm, ' <li> ');
         result = result.replace(/\<\/li\>/gm, ' </li> ');
         result = result.replace(/  /gm, ' ');
         if (type === 'init') {
            result = result.replace(/([a-zA-Z0-9])"/gm, '$1”');
            result = result.replace(/"([a-zA-Z0-9])/gm, '“$1');
            result = result.replace(/([a-zA-Z0-9])'/gm, '$1’');
            result = result.replace(/'([a-zA-Z0-9])/gm, '‘$1');
         }
         let app = document.querySelector('.App');
         let loaded = false;
         if (app) { loaded = app.dataset.promptsLoaded; }
         let disabled = loaded === 'true' ? '' : ' disabled';
         let button = `$1 <code class="button"${disabled} onclick="`+
            
            `let interval = window.setInterval(function() { `+
               `if ('$2'.indexOf('#') === 0) { `+
                  `document.querySelector('.sidebar .choosePrompt').value='$2'.replace(/^\#/g,''); `+
               `} `+
               `else { `+
                  `let promptsLoaded = document.querySelector('.App').dataset.promptsLoaded; `+
                  `if (promptsLoaded === true || promptsLoaded === 'true') { `+
                        `document.querySelector('.chat-input-textarea').value = ${"`"}$2${"`"}; `+
                        `document.querySelector('.chat-input-submit').click(); `+
                  `} `+
               `} `+
               `clearInterval(interval); `+
            `}, 100);`+
            
         `">$2</code>`;
         result = result.replace(/(Add an example\:|Example\:|Try\:|Try it\:Ask\:||Say\:|Related\:|Try|try|Ask|ask|Say|say|Write|write|-|\<li\>) \<code\>(.*?)\<\/code\>/gm, button);
         result = result.replace(/\<code\>https\:/gmi, '<code class="wrap">https:');
         return {__html: result};
      }

      const sendFeedback2 = async function(feedbackId) {

         let feedback = document.querySelector(`#${feedbackId}`);
         let choices = feedback.querySelectorAll('i');
         let textarea = feedback.querySelector('textarea');

         if (feedback.dataset.sent === 'true') { return false; }

         let id = `${window.user.firstName}-${window.user.lastName}_${helper.string.timestamp()}`;
         
         let choice = feedback.dataset.choice;
         if (typeof choice !== 'string') { choice = 'null'; }
         choice = choice === 'true' ? true : choice === 'false' ? false : choice === 'null' ? false : choice;

         let statement = '';
         if (textarea) { if (typeof textarea.value === 'string') { statement = textarea.value; }}

         let debugFeedback = function() {
            console.log(` \nFeedback`);
            console.log(`- Id:         ${id}`);
            console.log(`- User:       ${window.user.id}`);
            console.log(`- Department: ${window.user.department}`);
            console.log(`- Prompt:     ${feedback.dataset.prompt}`);
            console.log(`- Author:     ${feedback.dataset.author}`);
            console.log(`- Group:      ${feedback.dataset.group}`);
            console.log(`- Request:    ${helper.string.shorten(feedback.dataset.request,  'default', true)}`);
            console.log(`- Response:   ${helper.string.shorten(feedback.dataset.response, 'default', true)}`);
            console.log(`- Log:        ${document.querySelector('.prompteneer').dataset.log}`);
            console.log(`- Choice:     ${choice}`);
            console.log(`- Statement:  ${statement}`);
         }();

         let data = {
            "id":         id,
            "choice":     choice,
            "prompt":     feedback.dataset.prompt,
            "author":     feedback.dataset.author,
            "group":      feedback.dataset.group,
            "user":       window.user.id,
            "department": window.user.department,
            "request":    feedback.dataset.request,
            "response":   feedback.dataset.response,
            "log":        feedback.dataset.log,
            "statement":  statement,
         };
         console.log(`- Data: `, data);

         let result = await fetchWithLogin(`${config.client.urlServer}/feedback/set?id=${id}`, {
            method: 'POST',
            headers: {
               'Allow': 'application/json',
               'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
         });
         result = await result.json();
         result = await result;
         console.log(`- Sent:      `, result);
      // textarea.value = '';
         
         feedback.dataset.open = false;
         feedback.dataset.sent = true;

      };

      const sendFeedback = async function(feedbackId) {
         let feedback = document.querySelector(`#${feedbackId}`);
         let prompteneer = document.querySelector(`.prompteneer`);
         let choice = feedback.dataset.choice;
         if (typeof choice !== 'string') { choice = 'null'; }
         if (feedback.dataset.sent === 'false' && feedback.dataset.choice !== 'null') {
            feedback.dataset.thanks = true;
            let choices = feedback.querySelectorAll('i');
            let textarea = feedback.querySelector('textarea');
            let id = `${window.user.firstName}-${window.user.lastName}_${helper.string.timestamp()}`;
            choice = choice === 'true' ? true : choice === 'false' ? false : choice === 'null' ? false : choice;
            let statement = '';
            let log = prompteneer.dataset.log;
            if (type === 'init') { log = '--'; }
            if (textarea) { if (typeof textarea.value === 'string') { statement = textarea.value; }}
            console.log(` \nNew Feedback`);
            console.log(`- Id:         ${id}`);
         // console.log(`- Element:    `, feedback);
            console.log(`- User:       ${window.user.id}`);
            console.log(`- Department: ${window.user.department}`);
            console.log(`- Prompt:     ${feedback.dataset.prompt}`);
            console.log(`- Author:     ${feedback.dataset.author}`);
            console.log(`- Group:      ${feedback.dataset.group}`);
            console.log(`- Request:    ${helper.string.shorten(feedback.dataset.request,  'default', true)}`);
            console.log(`- Response:   ${helper.string.shorten(feedback.dataset.response, 'default', true)}`);
            console.log(`- Log:        ${log}`);
            console.log(`- Choice:     ${choice}`);
            console.log(`- Statement:  ${statement}`);
            let data = {
               "id":         id,
               "choice":     choice,
               "prompt":     feedback.dataset.prompt,
               "author":     feedback.dataset.author,
               "group":      feedback.dataset.group,
               "user":       window.user.id,
               "department": window.user.department,
               "request":    feedback.dataset.request,
               "response":   feedback.dataset.response,
               "log":        log,
               "statement":  statement,
            };
         // console.log(`- Data: `, data);
            let result = await fetchWithLogin(`${config.client.urlServer}/feedback/set?id=${id}`, {
               method: 'POST',
               headers: {
                  'Allow': 'application/json',
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify(data),
            });
            result = await result.json();
            result = await result;
            console.log(`- Sent:      `, result);
            feedback.dataset.statement = statement;
         // textarea.value = '';
            window.setTimeout(function() {
               feedback.dataset.thanks = false;
            }, 2000);
         }
         feedback.dataset.open = false;
         feedback.dataset.sent = true;
      };

      return (
         <div className={`${user} ${type} ${waiting ? 'waiting' : ''}`}>
            <div className="center">
               <div className={`avatar`} >
                  {`${user === 'bot' ? 'AI' : 'Me'}`}
               </div>
               <div className="message" dangerouslySetInnerHTML={html()} />
               <div className='feedback roleUser roleManager roleAngel roleAdmin' data-feedback={feedback} data-sent='null' data-open='false' data-choice='null' data-prompt={prompt} data-author={author} data-group={group} data-request={request} data-response={response} data-log={log} data-thanks='null' onMouseLeave={(e) => {
                  let feedbackId = helper.feedback.id();
                  e.target.id = feedbackId;
                  sendFeedback(feedbackId);
               }}>
                  <h3 title={`Your Feedback on ${prompt} in ${group} will be sent to ${author}.`}>Your Feedback</h3>
                  <span class="thanks">Thank you!</span>
                  <i class="pos fa-solid fa-thumbs-up fa-flip-horizontal" data-chosen='false' onClick={(e) => {
                     let choice = e.target;
                     let feedback = choice.parentNode;
                     let choices = feedback.querySelectorAll('i');
                     let status = choice.dataset.chosen;
                     choices.forEach(function(ch) { ch.dataset.chosen = false; });
                     if (status === 'true') {
                        feedback.dataset.open = false;
                        feedback.dataset.choice = null;
                        choice.dataset.chosen = false;
                     } else {
                        feedback.dataset.open = true;
                        feedback.dataset.choice = true;
                        choice.dataset.chosen = true;
                     }
                     feedback.querySelector('textarea').focus();
                     e.target.parentNode.dataset.sent = false;
                  }}></i>
                  <i class="neg fa-solid fa-thumbs-down" data-chosen='false' onClick={(e) => {
                     let choice = e.target;
                     let feedback = choice.parentNode;
                     let choices = feedback.querySelectorAll('i');
                     let status = choice.dataset.chosen;
                     choices.forEach(function(ch) { ch.dataset.chosen = false; });
                     if (status === 'true') {
                        feedback.dataset.open = false;
                        feedback.dataset.choice = null;
                        choice.dataset.chosen = false;
                     } else {
                        feedback.dataset.open = true;
                        feedback.dataset.choice = false;
                        choice.dataset.chosen = true;
                     }
                     feedback.querySelector('textarea').focus();
                     e.target.parentNode.dataset.sent = false;
                  }}></i><br />
                  <textarea placeholder="Please elaborate..." onKeyDown={(e) => {
                     let theKey = e.keyCode;
                     if (theKey === 13 && !e.shiftKey) {
                        let feedbackId = helper.feedback.id();
                        e.target.parentNode.id = feedbackId;
                        sendFeedback(feedbackId);
                     }
                  }} onKeyUp={(e) => {
                     let theValue = e.target.value;
                     if (typeof theValue === 'string' && theValue !== '') {
                        e.target.parentNode.querySelector('button').disabled = false;
                     }
                     else {
                        e.target.parentNode.querySelector('button').disabled = true;
                     }
                  }} onChange={(e) => {
                     e.target.parentNode.dataset.sent = false;
                  }}></textarea><br />
                  <span class="cta">Write and hit "Enter" to</span>
                  <button disabled="true" onClick={(e) => {
                     let feedbackId = helper.feedback.id();
                     e.target.parentNode.id = feedbackId;
                  // e.target.parentNode.dataset.open = false;
                     sendFeedback(feedbackId);
                  }}>Send</button>
               </div>
            </div>
         </div>
      );
   };

// Authentication exit
   auth.onLogin((err, userdata) => {
   // console.log(err, userdata);
   });
   auth.init();

// Export
   export default App;
