   import { PublicClientApplication } from '@azure/msal-browser';
   import { msalConfig, graphConfig } from './authConfig';

   const client = new PublicClientApplication(msalConfig);

   let initalized = false;
   let isLoggedIn = null;
   let account = undefined;
   let userdata = undefined;
   let events = {};
   let accessToken = undefined;

   const addEventHandler = (name, callback) => {
      if (events[name] == null)
         events[name] = [];

      events[name].push(callback);
   }

   const callEventHandler = (name, ...args) => {
      if (events[name] == null)
         return;

      for (let handler of events[name]) {
         handler(...args);
      }
   }

   export const init = () => {

      if (initalized)
         return;
      
      initalized = true;

   // console.log('Initializing authentication');

      client.handleRedirectPromise().then((redirectResponse) => {

         if (redirectResponse != null) {
         // redirect login successful
            account = redirectResponse.account;
            completeLogin(redirectResponse.accessToken);
            return;
         }
         
      // try to login silently
         const accounts = client.getAllAccounts();

      // console.log(`- Accounts:`, accounts);
      // console.log(`  Folgt: Find statista.com accounts`);

         if (accounts.length == 0) {
         // no account found => please login
            isLoggedIn = false;
            callEventHandler('login', new Error('no account found'));
            return;
         }

         else if (accounts.length > 1) {
         // too many accounts found
            isLoggedIn = false;
            callEventHandler('login', new Error('too many accounts found'));
            return;
         }

         let useAccount = accounts[0];
      
      // try to get an access token
         const accessTokenRequest = {
            scopes: ["user.read"],
            account: useAccount,
         };

         client.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {

            account = accessTokenRequest.account;

         // silent login successful
            completeLogin(accessTokenResponse.accessToken);

         }).catch((err) => {
         // could not get access token => please login
            isLoggedIn = false;
            callEventHandler('login', new Error('could not get access token'));
         });

      });

   }

   const completeLogin = (token) => {

      accessToken = token;
   // console.log('- AccessToken:', accessToken);

      const headers = new Headers();
      headers.append('Authorization', `Bearer ${accessToken}`);
      const options = {
         method: 'get',
         headers: headers
      };

      const reqMe = fetch(graphConfig.graphMeEndpoint, options)
         .then(response => response.json());

      const reqGroup = fetch(graphConfig.graphGroupsEndpoint, options)
         .then(response => response.json());

      Promise.all([reqMe, reqGroup]).then(([responseMe, responseGroups]) => {
         let responseGroup = [];
         responseGroups.value.forEach(function(obj) {
            responseGroup.push(obj.id);
         });
      // console.log(`responseGroup: `, responseGroup);
         userdata = {
            id:            responseMe.userPrincipalName,
            employeeId:    responseMe.employeeId,
            active:        responseMe.accountEnabled,
            companyName:   responseMe.companyName,
            department:    responseMe.department,
            levelNumber:   responseMe.extension_4cb0c897fadc44808b9c35bd6f3170ae_extensionAttribute1,
            levelName:     responseMe.extension_4cb0c897fadc44808b9c35bd6f3170ae_extensionAttribute2,
            firstName:     responseMe.givenName,
            lastName:      responseMe.surname,
            title:         responseMe.jobTitle,
            mail:          responseMe.mail,
            country:       responseMe.usageLocation,
            city:          responseMe.officeLocation,
            language:      responseMe.preferredLanguage,
            isRoleUser:    responseGroup.includes('f4ada266-d19c-4697-8725-55e0aa24f2f7'),
            isRoleManager: responseGroup.includes('d591bfca-a238-4d4a-a626-1c5380f1706f'), 
            isRoleAngel:   responseGroup.includes('59fe2208-bdb4-4e1c-b9c0-1d4c8513efff'),
            isRoleAdmin:   false, // Mock
         };
         
         isLoggedIn = true;
         callEventHandler('login', null, userdata);
      });
   }

   export const onLogin = (callback) => {
      if (isLoggedIn === true) {
         return callback(null, userdata);
      } else if (isLoggedIn === false) {
         return callback(new Error('unknown error'));
      }
      addEventHandler('login', callback);
   }

   export const login = () => {
      client.loginRedirect({
         scopes: ['user.read']
      });
   }

   export const logout = () => {
      client.logoutRedirect({
         account,
         postLogoutRedirectUri: msalConfig.auth.redirectUri,
      });
   }

   export const addTokenToUrl = (url) => {
      let x = new URL(url, window.location.origin);
      x.searchParams.set('sso_token', accessToken);
      return x.href;
   }
