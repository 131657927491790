// Client Configuration
   let config = {
      client: {
         start:              `Moin, welcome in our AI Client.`,
         placeStatId:        'Enter a Statistic ID',
         placeStatQuery:     'Enter search phrases',
         placeInput:         'Type your input here and press Enter.',
         urlClient:          '/',
      // urlServer:          `${window.location.origin}/api`,
         urlServer:          `https://statista-ai.statista.workers.dev/api`,
         prompt:             'null', // Id of the prompt loaded by default
         prompteneer:        true,
         passcode:           'statistics',
         groupsParams:       ['group', 'groups'],
         groupsDefault:      ['andreasSefzig', 'assistants', 'default', 'templates', 'tests', 'tools'],
         allowedParams:      ['group', 'groups', 'role', 'view', 'theme', 'welcome', 'title', 'text', 'button', 'message'],
      // removeParams:       ['group', 'groups', 'role', 'view', 'theme', 'welcome', 'title', 'text', 'button'],
         maintenance:        true,
         theme:              'statista',
         userDefault:        'firstname.lastname@statista.com',
         roleDefault:        'user',
         personalDefault:    'templatePersonal_0-1',
         personalFallback:   'Start with a template!',
         storagePrefix:      'statistaAiClient_',
         storageDefault:     'local',
         message:            'This is a test.',
         initSettings:       false,
         timeout:            30,
         allowance:          'Have fun using our Prompts!',
      },
      policy: {
         version:           '1.0.0',
         url:               'https://statista.sharepoint.com/sites/Statista2/SiteAssets/SitePages/Memo-ChatGPT/2023-02_Memo_ChatGPT-Usage.pdf?web=1',
      },
      statista: {
         statisticId:         null,
         statisticQuery:      null,
         groupsAvailable: {
            'ask':           ['AskStatista'],
            'cd':            ['Content & Design'],
            'cs':            ['^HR .*', '^CS .*'],
            'eri':           ['^Research .*'],
            'cs':            ['^CS .*'],
            'csHr':          ['^HR Operations.*', 'roleAdmin'],
            'csOm':          ['^CS .*'],
            'csSu':          ['^CS .*'],
            'nxt':           ['^nxt .*'],
            'pit':           ['^PIT .*'],
            'pitIt':         ['^PIT .*'],
            'pitPi':         ['PIT Product Intelligence'],
            'pitPs':         ['^PIT .*'],
            'sales':         ['Sales Management', '^Client Success .*', '^Account Sales .*'],
            'smi':           ['^SMI .*'],
            'smedia':        ['^Statista Media.*'],
            'smiCompany':    ['^SMI Company .*'],
            'smiConsumer':   ['^SMI Consumer .*'],
            'smiIf':         ['^SMI .*'],
            'smiMarket':     ['^SMI Market .*'],
            'sq':            ['Data Insights', 'AskStatista'],
            'sr':            ['Statista R'],
         // 'su':            ['roleUser', 'roleManager', 'roleAngel', 'roleAdmin', 'CS Management', 'HR Development'],
            'su':            ['.*'],
            'default':       ['.*'], // '^(?!Research).*'
            'templates':     ['.*'],
            'tests':         ['roleManager', 'roleAngel', 'roleAdmin'], // tbd
         // 'assistants':    ['^PIT .*', '^HR .*', '^CS .*'],
            'assistants':    ['.*'],
            'tools':         ['.*'],
            'andreasSefzig': ['.*'],
         },
         groupsStatic:       ['default', 'all', 'templates', 'tests', 'tools', 'assistants', 'su', 'ask', 'cd', 'cs', 'csHr', 'csOm', 'csSu', 'eri', 'nxt', 'pit', 'pitIt', 'pitPi', 'pitPs', 'sales', 'smi', 'smiCompany', 'smiConsumer', 'smiMarket', 'smiIf', 'sq', 'sr'],
         urlAdUsers:         'https://myaccount.microsoft.com/groups/f4ada266-d19c-4697-8725-55e0aa24f2f7',
         urlAdManagers:      'https://myaccount.microsoft.com/groups/d591bfca-a238-4d4a-a626-1c5380f1706f',
         urlAdAdmins:        '',
         urlForm:            'https://forms.office.com/e/jdAbSbVH2A',
         urlPrompts:         'https://dash.cloudflare.com/a3594e24b6cd1f9c3fdc045f2f1eafaa/workers/kv/namespaces/f2125f262b7a408d85ead5ef2d5c7382',
         urlUsage:           'https://platform.openai.com/account/usage',
         urlPage:            'https://statista.sharepoint.com/sites/AIStatista/SitePages/AI-Client.aspx',
      },
      openai: {
         model:              'gpt-3.5-turbo',
         temperature:         0.2,
         tokens:              null,
         precision:           null,
      },
      signin: {
         title:              'Statista AI Client',
         text:               'Please log in using your Statista credentials:',
         button:             'Log in'
      },
      embed: {
         linkHtml:           `<a href="{url}" target="_blank" title="Opens the Prompt '{prompt}' in a new tab.">#{prompt}</a>`,
         linkMarkdown:       `[#{prompt}]({url})`,
         iframe:             `<iframe src="{url}" width="100%" height="100%" frameborder="0" scroll="auto"></iframe>`,
         sharepoint:         `<!-- Needs changes to SSO (msal.js) --><iframe src="{url}" width="100%" height="100%" frameborder="0" scroll="auto"></iframe>`
      },
      placeholders: {
         text: {
            aboutAiclient:   `- "Statista.ai Client" (aka "AI Client", "the Client") is Statista's general tool to access AIs. The call name of the client is "AI Client". 
                              - "AI platform" is a class of software vernors who provide AI models and offer an API to access the AIs (machine-to-machine). 
                              - "AI Policy" is the name of a document which explains the do's and dont's of Statista employees in the Client. The document has to be confirmed in the Client when one starts using the Client or when the AI Policy has been updated. The document is created and maintained by the AI Security Team. 
                              - "AI Security Team" is the name of a group which feels responsible for all aspects of Statistas data security and -sovereignty in conjunction with AI initiatives (including the Client). 
                              - "OpenAI" is the provider of the currently hottest AIs from the GPT family. Statista is not affiliated with OpenAI. 
                              - "OpenAI Account" is an account provided in the OpenAI-Org "Statista". 
                              - "OpenAI Member" is an employee of Statista who has been added to the OpenAI-Org "Statista". Members can generate API Keys which can access OpenAI and count towards the Org's Usage. . 
                              - "OpenAI Usage" is the amount of tokens used by a Member or the whole Org. The Usage is not split by API Key. 
                              - "LLM" is short for "Large Language Model". LLMs are the foundatinal models offered by OpenAI and others. 
                              - "Tokens" are a kind of currency when using LLMs. Tokens resemble sylables in natural language. Users of AIs are charged for the amount of tokens their Prompts contain (including the answer). 
                              - "Prompts" are a set of instructions, information and data sent to an AI to respond. Task-oriented Prompts usually consist of a static and a dynamic part, with the static part being applied repeatedly on the dynamic/variable part entered by the Prompt's users. 
                              - "Prompt-Groups" are a virtual grouping of all of our Prompts in the Prompt-Dropdown as well as the "Settings"-screen. Every department has their own groups, consisting of a Dev-, a Stage- and a Live-group each (the names of these groups follow the schema "DepartmentName (dev|stage|live", for example "ERI (stage)"). There is also a fictuous department named "Tests", which has Dev-, Stage- and Live-environment too, and is only visible to Managers, Angels and Admins. Also, every user has their own group (called "Personal Prompts"), named after themself (the naming of these Groups follows the schema "firstnameLastname", for example "johnDoe" in the case of John Doe). And there are two Groups available for everybody ("tools" and "templates"). 
                              - "Prompt-Dropdown" is the select at the top of the Sidebar, which lists als Prompts in their respective Groups. The order of the Groups is: Personal Prompts, Test-Groups (if one is a manager), all Department-Groups (one has access to), then all Personal Groups (one has access to), and finally the Tools and Templates. 
                              - "Personal Groups" are Groups specific to a person using the Client. They are named after their owner (schema "firstnameLastname). Only the owner of a Personal Group can save Prompts to it. 
                              - "Department Groups" are Groups specific to a department. They are named after the department and come with a Dev-, a Stage- and a Live-Environment (which appear as separate Groups in the Prompt-Dropdown). 
                              - "Functional Groups" are Groups which are available to everybody at Statista. They contain Prompts that are not department-specific and useful for the general public. 
                              - "Designer" is the name of the screen in which the editor resides, in which one can design (and save) Prompts. The Designer can be opened by pressing the button at the right side of the Chat's message input field. The Designer can be closed by pressing the close-button at the top right of the Designer (or the button at the right side of the Chat's message input field). 
                              - "Trouble" is the name of a screen in which one can debug their Client on their own and eventually report a bug. 
                              - "User" is the Role of a regular user (who is not Manager, Angel or Admin). Users have limited capabilities in the Client. They can save Prompts only to Personal Groups as well as the Stage-Prompt-Groups of their department. 
                              - "Manager" is the Role of a person dedicated by their department to manage Prompts and support the Group. Managers have extended capabilities in the Client. They can save Prompts only to Personal Groups as well as the Stage- and Live-Prompt-Groups of their department. 
                              - "Angel" is the Role of the person who leads the AI initiatives in their Department. Angels have most capabilities in the Client. They can save Prompts in every Prompt-Group, including the Live-Prompt-Groups of their department and the Functional Groups. 
                              - "Admin" is the Role of the person who is responsible for the functioning and development of the Client. Admins have all capabilities in the Client. `,
            aboutMe:         `- My name is "{userFirstname} {userLastname}". 
                              - I work in the department "{userDepartment}" in the city "{userCity}". 
                              - My position is "{userPosition}". 
                              - My email-address is "{userEmail}". 
                              - My role in the AI Client is "{userRole}".`,
            aboutStatista:   ``,
            termsAccounts:   `- "[name of the topic]" is a [description of the topic]. 
                              - "Customers" are companies who have licensed one or more Accounts of Statista. 
                              - "Prospects" are Customers who have not yet licensed an Account. 
                              - "Clients" are API integrations set up by our customers. 
                              - "Users" are employeeds of our customers who use the client. 
                              - "Content" is a collection of data which resembles the metadata of a piece of content available on Statista's platforms. 
                              - "Content Types" available via API are "Statistics", "Studies" and "Infographics". 
                              - "Licenses" are agreements between Statista and the customer, which define the eligibility to use certain products, Platforms and access types. 
                              - "Accounts" are individual- or group-accesses which grant access to the Statista platform. They reflect the rights defined in the according License. 
                              - "Professional Accounts" are formerly known as "Corporate Accounts". 
                              - "Platforms" are websites by Statista which are available in English, German, Spanish and French. Eligibility to use these is defined on an individual basis in the License. `,
            termsDepartments:`- Corporate Services: This is where key areas of our company are managed, amongst others HR, Office Management and Finance. 
                              - "PIT" (Product & IT): This is where our products and websites are programmed and maintained. Also, PIT is responsible for the IT infrastructure. 
                              - "Product Intelligence" (part of PIT): This is where Online Marketing, SEO, Voice of customer and user analysis happens. 
                              - "Product Services" (part of PIT): This is where all sorts of internal support requests concerning our Product and IT are handled. 
                              - "Sales": This is where Statista accounts for our platform are sold to new customers. 
                              - "CSM" (Customer Success Management): This is where existing customers are supported to make the most of their accounts. 
                              - "MarCom" (Marketing and Communications): This is where marketing material is created, events are planned and PR is conducted. 
                              - "SMI" (Strategic Market Insights): This is where the data is curated for Statista's flagship products, the so called "Insights".
                              - "ERI" (Editorial Research Intelligence): This is where data-journalism happens to create the content on our platform. 
                              - "Statista R" (Statista Rankings): This is where the popular Rankings are created together with or for external media companies. 
                              - "Statista Q": This is where custom projects are conducted for external clients, based on the data accessible at Statista. 
                              - "AskStatista" (part of Statista Q): : This is where custom requests for data are handled, using internal and external data sources. 
                              - "C&D" (Content & Design): This is where content is produced, based on data, for internal stakeholders and external clients. 
                              - "nxt Statista": This is where external clients are provided with custom consultancy, focussing on data and AI. `,
            termsTools:      `- "Rydoo" is a tool for remibursement of expenses (e.g. travel, trade fair tickets, team events, birth or marriage gifts for employees) accessible via https://app.rydoo.com/dashboard/
                              - "IT Tickets" are used by Statista employees to contact the IT helpdesk accessible via https://forms.office.com/pages/responsepage.aspx?id=OfiFB67f4U-gaID-j8YfK_r8C5b8ujtGu1VXngtgpCRUNVU4TlVPMEpNTzZDTFQ4SFZaNkwzWFdVRC4u
                              - "Robin" is a tool used for desk reservation in the office accessible via https://dashboard.robinpowered.com/statista-gmbh/office?end=2023-10-02T17:00:00Z&duration=30&capacity=0&locations=760899&levels=190999 . Employees can log into robin using their Statista SSO credentials. A ROBIN app is downloadable in the AppStore  (https://apps.apple.com/us/app/robin-mobile-app/id948856282) and via Google Play (https://apps.apple.com/us/app/robin-mobile-app/id948856282). ROBIN is only used for desk reservations but not for reservation of conference rooms. The office maps in ROBIN can also be used by employees for orientation within the office or finding rooms.
                              - "Back" is a ticketing system that can be used by employees to contact Office Management. BACK tickets are the only way employees should contact office management. A ticket in BACK can be opened by employees via the BACK bot which is available in the chats tab of Teams.
                              - "Front desk" refers to the reception of the Statista office. It's located on the first floor of the offce. Opening hours of the front desk are Monday - Thursday from 8.00 a.m. to 5.30 p.m. and Fridays from 8.00 a.m. to 4:30 p.m..
                              - "Order form" refers to an online from used to order items (e.g. flowers, business cards), services (e.g. catering), and all software licenses for non-Microsoft software accessible via https://forms.office.com/pages/responsepage.aspx?id=OfiFB67f4U-gaID-j8YfKzhmdIdpt3NCtO5dc02OMkVUQk9LRUtZWFJETjBUVFY5M1k3UkNXWTJBUS4u. Employees need to specify the costs of their order in the form. If they are unsure about the cost of their order they can contact the procurement department via order@statista.com.
                              - "Cytric" is a tool employees use for booking travel via train or plane and hotels accessible via https://world.cytric.net/env-a/ibe/?system=der-co-stroeer . Employees should note that train rides cannot be booked as round trips but must be booked individually. Employees can request CYTRIC log-in credentials via opening a ticket adressed to Office Management in Back given prior permission from their supervisor. For resetting their CYTRIC password employees should contact de.obtsupport@amexgbt.com .
                              - "Fiori" is a tool employees can use to view their paycheck and is accessible via https://contactmonkey.com/api/v1/tracker?cm_session=5af2d879-1361-49fc-9592-ab236d875190&cm_type=link&cm_link=7cdebae7-14c1-4193-9f95-e04e21a25a8d&cm_destination=https://launchpad.stroeer.de . If employees have problems accessing FIORI they must contact support@stroeer.de . Office management and HR cannot help with FIORI access.
                              - "Personio" is the tool we use to manage employee data, request absences and research organizational aspects of our colleagues. Personio is accessible via https://statista.personio.com . Data from Personio is used by a wide variety of other tools, including Moco and the internal onboarding process. 
                              - "Moco" is the tool in which most employees track their time. It is used to gather data on activatable budgets for projects. Moco is accessible via https://statista.mocoapp.com . Directors, supervisors as well as Finance have access to the time that was tracked in Moco.`,
            formatMarkdown:  `- Format your answer in Markdown. 
                              - Format urls as clickable links in Markdown. 
                              - Format tables in the following way: 
                                - Format the Items as a table with the appropriate columns
                                - Always add a header which contains short and concise strings. 
                              - Format lists in the following way: 
                                - Format the items in your answer as an unordered list, with each line starting with the Item's name, followed by a colon and a related link. 
                                - Choose unordered lists unless I state otherwise. 
                              - Format images in the following way: 
                                - Insert an empty line before and after an image formatted in Markdown. 
                              - Format text in the following way, if specified explicitly: 
                                - Write text in bold font.`,
            formatParagraphs:`- Consider using lists when you mention more than two entities of a same category (we like lists). 
                              - If your answer contains multiple aspects, always break your answer down into paragraphs (devided by an empty line). 
                              - Try to split paragraphs consisting of more than two sentences into multiple paragraphs (which are seperated by an empty line (two new lines)). 
                              - If your answer contains more than 2 sentences, split your answer into multiple paragraphs. `,
            languageInternal:``,
            languageExternal:``,
         },
      },
      admins: {
         assistants: [
            'andreas.sefzig@statista.com',
            'lukas.krause@statista.com',
            'viviane.minz@statista.com',
         ],
         default: [
            'andreas.sefzig@statista.com',
         ],
         live: [
            'andreas.sefzig@statista.com',
         ],
         templates: [
            'andreas.sefzig@statista.com',
         ],
         tools: [
            'andreas.sefzig@statista.com',
         ],
      },
      scraper: {
         scrape:             false,
         token:              'a1b2c3d4e5f6g7h8',
         url:                'https://langchain-worker.statista.workers.dev/',
      }
   };
   
// Initial prompt
   config.prompts = {
      null: {
         name:               "Choose a prompt...",
         description:        "You can talk with GPT through me!",
         model:              "gpt-3.5-turbo",
         precision:          false,
         temperature:        0.5,
         statisticId:        false,
         statisticQuery:     false,
         tokens:             null,
         group:              "default",
         locked:             true,
         prompt:             `

         // This is an empty Prompt Design template. 
         // It will be used to enricht a message of the user. 
         // Edit it at will. (Later) you can save it to reuse it. 

         // Refine it by adding data from a Statistic Id: 
         // {title}       // The title of the Statistic 
         // {subject}     // The subject of the Statistic 
         // {description} // The description of the Statistic 
         // {date}        // The publishing date of the Statistic 
         // {source}      // The name of the first source of the Statistic 
         // {link}        // The url of the link of the Statistic 
         // {image}       // The url of the main image of the Statistic 
         // {csv}         // The chart data of the Statistic in csv format

         // Or refine it by adding data from a Statistic Query: 
         // {searchData}  // The data of the 5 most relevant Statistics found with the Statistic Query
            
         // Weave in the user's message
            {input}       // The string the user entered in the chat interface
            
         `,
      },
   };
   
// Export Config
   export { config }; 
