
   import { LogLevel } from '@azure/msal-browser';

   /**
    * Configuration object to be passed to MSAL instance on creation. 
    * For a full list of MSAL.js configuration parameters, visit:
    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
    */
   export const msalConfig = {
      auth: {
         clientId: "39b82784-1b68-4b1d-b5f2-b76f044edfa9",
         authority: "https://login.microsoftonline.com/0785f839-dfae-4fe1-a068-80fe8fc61f2b",
         redirectUri: function() {
            let baseUrl = "https://statista-ai.statista.workers.dev/";
            if (window.location.href.indexOf('localhost') > -1) { baseUrl = 'http://localhost:3000/'; }
            return baseUrl;
         }(),
      },
      cache: {
         cacheLocation: "sessionStorage", // This configures where your cache will be stored
         storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {	
         loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
               if (containsPii) {		
                  return;		
               }		
               switch (level) {		
                  case LogLevel.Error:		
                     console.error(message);
                     return;		
                  case LogLevel.Info:		
                  // console.info(message);
                     return;		
                  case LogLevel.Verbose:		
                     console.debug(message);
                     return;		
                  case LogLevel.Warning:		
                     console.warn(message);
                     return;		
               }	
            }	
         }	
      }
   };



   /**
    * tba
    */
   export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/beta/me",
      graphGroupsEndpoint: "https://graph.microsoft.com/beta/me/memberOf"
   };